import AbstractListResource from './abstract/abstract-list-resource';
import {Resource, ActionType} from 'sdk/src/defines';
import Subscriber from './items/subscriber';

class Subscribers extends AbstractListResource<Subscriber> {
    public resourceType: Resource = Resource.SUBSCRIBERS;

    public resourceItemClass: typeof Subscriber = Subscriber;

    public allowedActions: ActionType[] = [
        ActionType.LIST,
        ActionType.GET,
        ActionType.UPDATE,
        ActionType.DELETE,
        ActionType.CREATE,
    ];
}

export {Subscribers, Subscriber};

export default new Subscribers();

import AbstractListResource from './abstract/abstract-list-resource';
import {ActionType, CategoryMode, CategoryType, Resource} from 'sdk/src/defines';
import Category from './items/category';
import {DropDownItem, SortFunc} from '../utility/types';
import {sortAsc} from '../utility/utils';
import {TFunction} from 'react-i18next';

class Categories extends AbstractListResource<Category> {
    public resourceType: Resource = Resource.CATEGORIES;

    public resourceItemClass: typeof Category = Category;

    public allowedActions: ActionType[] = [
        ActionType.LIST,
        ActionType.GET,
        ActionType.CREATE,
        ActionType.UPDATE,
        ActionType.DELETE,
    ];

    public sort: SortFunc = sortAsc('contentScreenOrder');

    public getConnectedResourceTypes(): Resource[] {
        return [Resource.CONTENTS];
    }

    public forDropDown(t: TFunction, empty?: string, mode?: CategoryMode | CategoryMode[], type?: CategoryType | CategoryType[]): DropDownItem[] {
        if (!this.isInitialized()) {
            return [];
        }

        const result: DropDownItem[] = [];

        if (empty) {
            result.push({
                label: empty,
                value: null,
            });
        }

        this.getItems().forEach((item: Category) => {
            if (!mode || (Array.isArray(mode) && mode.includes(item.mode)) || item.mode === mode) {
                if (!type || (Array.isArray(type) && type.includes(item.type)) || item.type === type) {
                    result.push({
                        label: item.getTitle(t),
                        value: item.id,
                    });
                }
            }
        });

        return result;
    }

}

export {Categories, Category};

export default new Categories();

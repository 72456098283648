import {Ability, AbilityBuilder} from '@casl/ability';
import {initialAbility} from './initialAbility';

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
const userData = JSON.parse(localStorage.getItem('userData'));
import {RoleType, Resource} from 'sdk/src/defines';


export const prepareRules = (userData) => {
    const abilityBuilder = new AbilityBuilder(Ability);

    if (!userData || !userData.role) {
        return initialAbility;
    }

    if (userData.superAdmin === true || userData.role.type === RoleType.OPERATOR) {
        abilityBuilder.can('manage', 'all');
    } else {
        if (userData.role.permissions) {
            Object.keys(userData.role.permissions).forEach(resource => {
                userData.role.permissions[resource].forEach(permission => {
                    abilityBuilder.can(permission, resource);
                });
            });
        }

        abilityBuilder.can('manage', Resource.PANELS);
        abilityBuilder.can('manage', Resource.PROFILE);
    }

    if (userData.disabledResources) {
        userData.disabledResources.forEach(resource => {
            abilityBuilder.cannot('manage', resource);
        });
    }

    return abilityBuilder.rules;
};
const abil = new Ability(prepareRules(userData));

export default abil;

import AbstractListResource from './abstract/abstract-list-resource';
import {Resource, ActionType} from 'sdk/src/defines';
import Panel from './items/panel';
import {SortFunc} from '../utility/types';
import {sortAsc} from '../utility/utils';

class Panels extends AbstractListResource<Panel> {

    public resourceType: Resource = Resource.PANELS;

    public resourceItemClass: typeof Panel = Panel;

    public sort: SortFunc = sortAsc('order');

    public allowedActions: ActionType[] = [
        ActionType.LIST,
        ActionType.UPDATE,
        ActionType.DELETE,
        ActionType.CREATE,
    ];

}

export {Panels, Panel};
export default new Panels();

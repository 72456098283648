import {formatDate, formatTime, secondsToHms} from '../utils';
import defaultChannelImage from '@src/assets/images/content/channel/128.jpg';
import defaultVideoImage from '@src/assets/images/content/video/100x150.jpg';
import defaultShowImage from '@src/assets/images/content/video/100x75.jpg';
import defaultItemImage from '@src/assets/images/content/items/244x144.jpg';
import {ContentType, Resource, Uid} from 'sdk/src/defines';
import AbstractResource from '../abstract/abstract-resource';
import {TFunction} from 'react-i18next';
import AbstractFileModelResource from '../abstract/abstract-file-model-resource';
import tags from '../tags';
import {
    AttributesType,
    CastRule,
    CastType,
    DetailsType,
    DispatchFunc,
    DropDownItem, ErrorType,
    LabelsType, LocalesType,
} from '../../utility/types';

export type EpgParams = {
    id: string,
    url: string,
    timeShift?: number,
    duration?: number,
    updatedAt?: number,
    pluginInstanceId?: string,
};

class Content extends AbstractFileModelResource {
    public resourceType: Resource = Resource.CONTENTS;

    public labels: LabelsType = {
        id: 'ID',
        title: 'contents.title',
        type: 'contents.type',
        url: 'contents.url',
        duration: 'contents.duration',
        sizeInBytes: 'contents.fileSize',
        folderId: 'contents.folderID',
        beginAt: 'contents.availableFrom',
        endAt: 'contents.availableTo',
    };

    public declare beginAt: Date;

    public declare endAt: Date;

    public declare published: boolean;

    public declare externalId: string;

    public declare contentOwnerId: Uid;

    public declare geoIpId: Uid;

    public declare sharedFromContentId: Uid;

    public declare parentContentId: Uid;

    public declare type: ContentType;

    public declare title: string;

    public declare duration: number;

    public declare rating: number;

    public declare ageLimit: number;

    public declare availableWithoutPurchase: boolean;

    public declare showToDemoUsers: boolean;

    public declare price: number;

    public declare locales: LocalesType;

    public declare props: {
        url?: string,
        fullDescription?: string,
        shortDescription?: string,
        keyCode?: number,
        year?: number,
        month?: number,
        day?: number,
        synopsis?: string,
        app?: string,
        imdbRating?: number,
        index?: number,
        languages?: string[],
        epg?: EpgParams,
    };

    public declare categories: {categoryId: Uid}[];

    public declare packages: {packageId: Uid}[];

    public declare discounts: {discountId: Uid}[];

    public declare shared: {operatorId: Uid}[];

    public declare tags: {tagId: Uid}[];

    public castValues(): CastType {
        return {
            beginAt: CastRule.DATE,
            endAt: CastRule.DATE,
            duration: CastRule.FLOAT,
            rating: CastRule.FLOAT,
            ageLimit: CastRule.INTEGER,
            price: CastRule.FLOAT,
        };
    }

    public static hasPackages(type: ContentType): boolean {
        return [
            ContentType.CHANNEL,
            ContentType.SERIES,
            ContentType.SHOW,
            ContentType.APP,
            ContentType.VIDEO,
        ].includes(type);
    }

    public static hasContentOwner(type: ContentType): boolean {
        return [
            ContentType.CHANNEL,
            ContentType.SERIES,
            ContentType.SHOW,
            ContentType.VIDEO,
        ].includes(type);
    }

    public static hasSubOperators(type: ContentType): boolean {
        return [
            ContentType.CHANNEL,
            ContentType.SERIES,
            ContentType.SHOW,
            ContentType.VIDEO,
        ].includes(type);
    }

    public getImage(name: string = 'image'): string {
        return super.getImage(Content.getDefaultImage(this.type), name);
    }

    public applyMetadata(params: AttributesType) {
        return (dispatch: DispatchFunc): void => {
            dispatch({type: `${this.resourceType}_UPDATING`, id: this.id});
            this.api().get(`${Resource.CONTENTS}/metadata/apply/${this.id}`, params).then((data: AttributesType) => {
                dispatch(tags.refresh());
                dispatch(this.updated(data));
            }).catch((err: ErrorType) => {
                dispatch({type: `${this.resourceType}_UPDATE_FAILED`, id: this.id, error: err});
            });
        };
    }

    public static getImageSize(type: ContentType): {width: number, height: number} {
        switch (type) {
            case ContentType.CHANNEL:
                return {width: 400, height: 400};
            case ContentType.VIDEO:
            case ContentType.SERIES:
            case ContentType.SHOW:
                return {width: 1200, height: 1772};
            case ContentType.ITEM:
                return {width: 400, height: 240};
            case ContentType.NEWS:
            case ContentType.APP:
                return {width: 400, height: 300};
            default:
                return {width: 400, height: 400};
        }
    }

    public static getDefaultImage(type: ContentType): string {
        switch (type) {
            case ContentType.CHANNEL:
                return defaultChannelImage;
            case ContentType.VIDEO:
            case ContentType.SERIES:
            case ContentType.SEASON:
            case ContentType.EPISODE:
                return defaultVideoImage;
            case ContentType.SHOW:
                return defaultShowImage;
            case ContentType.ITEM:
                return defaultItemImage;
            case ContentType.NEWS:
            case ContentType.APP:
                return defaultShowImage;
            default:
                return null;
        }
    }

    public static ageLimitsForDropDown(): DropDownItem[] {
        return [
            {label: '0+', value: 0},
            {label: '18+', value: 18},
        ];
    }

    public getDetails(t: TFunction): DetailsType {
        return super.getDetails(t, {
            title: this.getTitle(t),
            beginAt: !this.beginAt ? '' : `${formatDate(this.beginAt)} ${formatTime(this.beginAt)}`,
            endAt: !this.endAt ? '' : `${formatDate(this.endAt)} ${formatTime(this.endAt)}`,
            duration: this.duration ? secondsToHms(this.duration) : '',
        });
    }

    public getDurationMs(): number {
        if (this.duration) {
            return this.duration * 1000;
        }

        return 60000;
    }

    public getTitle(t: TFunction): string {
        return this.title || t('contents.unnamedContent');
    }

    public static typesForDropDown(t: TFunction): DropDownItem[] {
        return AbstractResource.defineForDropDown(ContentType, 'contents', t);
    }

    public getTypeStr(t: TFunction): string {
        const item: DropDownItem = Content
            .typesForDropDown(t)
            .find((item: DropDownItem) => item.value === this.type);

        if (item) {
            return item.label;
        }

        return null;
    }
}

export default Content;

import AbstractItemResource from '../abstract/abstract-item-resource';
import {Resource, ContentType} from 'sdk/src/defines';
import {LocalesType} from '../../utility/types';

class CustomTagType extends AbstractItemResource {
    public resourceType: Resource = Resource.CUSTOM_TAG_TYPES;

    public declare title: string;

    public declare name: string;

    public declare published: string;

    public declare searchable: string;

    public declare contentTypes: ContentType[];

    public declare locales: LocalesType;

    public declare order: number;
}

export default CustomTagType;

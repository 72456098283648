import AbstractListResource from './abstract/abstract-list-resource';
import {Resource, ActionType, ContentAspect} from 'sdk/src/defines';
import Content from './items/content';
import {AttributesType, DispatchFunc, DispatchResponse, DropDownItem} from '../utility/types';
import {TFunction} from 'react-i18next';
import tags from './tags';

class Contents extends AbstractListResource<Content> {
    public resourceType: Resource = Resource.CONTENTS;

    public resourceItemClass: typeof Content = Content;

    public allowedActions: ActionType[] = [
        ActionType.LIST,
        ActionType.GET,
        ActionType.UPDATE,
        ActionType.DELETE,
        ActionType.CREATE,
        ActionType.UPLOAD,
    ];

    public static getAspectForDropDown(t: TFunction): DropDownItem[] {
        return [
            {
                label: t('contents.aspectSource'),
                value: ContentAspect.SOURCE,
            },
            {
                label: t('contents.aspectFit'),
                value: ContentAspect.FIT,
            },
            {
                label: t('contents.aspectStretch'),
                value: ContentAspect.STRETCH,
            },
            {
                label: t('contents.aspectCrop'),
                value: ContentAspect.CROP,
            },
        ];
    };

    public publishedForDropDown(t: TFunction, empty?: string): DropDownItem[] {
        const result: DropDownItem[] = [];

        if (empty) {
            result.push({
                label: empty,
                value: null,
            });
        }

        result.push(
            {
                label: t('contents.published'),
                value: true,
            },
            {
                label: t('contents.notPublished'),
                value: false,
            },
        );

        return result;
    }

    public searchMetadata(params: AttributesType): Promise<any> {
        return this.api().get(`${Resource.CONTENTS}/metadata/search`, params);
    }

    public createFromMetadata(params: AttributesType): DispatchResponse {
        return (dispatch: DispatchFunc) => {
            dispatch({type: `${this.resourceType}_CREATING`});
            this.api().get(`${Resource.CONTENTS}/metadata/apply`, params).then((data: AttributesType) => {
                dispatch(tags.refresh());
                dispatch(this.created(data));
            }).catch(() => {
                dispatch({type: `${this.resourceType}_CREATE_FAILED`});
            });
        };
    }

    public getConnectedResourceTypes(): Resource[] {
        return [Resource.CATEGORIES, Resource.PACKAGES, Resource.TRACKS, Resource.SEASONS, Resource.EPISODES];
    }
}

export {Contents, Content};
export default new Contents();

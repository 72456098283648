// ** React Imports
import {Suspense, lazy} from 'react';
import ReactDOM from 'react-dom';

// ** Redux Imports
import {Provider} from 'react-redux';
import {store} from './redux/storeConfig/store';

// ** Toast & ThemeColors Context
import {ToastContainer} from 'react-toastify';
import {ThemeContext} from './utility/context/ThemeColors';

// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner';

// ** Ripple Button
import './@core/components/ripple-button';

// ** PrismJS
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import 'prismjs/components/prism-jsx.min';

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css';

// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss';

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css';
import './@core/scss/core.scss';
import './assets/scss/style.scss';

// ** Service Worker
import * as serviceWorker from './serviceWorker';

// ** Lazy load app
const LazyApp = lazy(() => import('./App'));

import './i18n';

import ability from './configs/acl/ability';

if (undefined === global.browser && undefined !== global.chrome) {
    global.browser = global.chrome;
}

import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';

ReactDOM.render(
    <Provider store={store}>
        <DndProvider backend={HTML5Backend}>
            <Suspense fallback={<Spinner />}>
                <ThemeContext>
                    <LazyApp />
                    <ToastContainer newestOnTop />
                </ThemeContext>
            </Suspense>
        </DndProvider>
    </Provider>,
    document.getElementById('root'),
);

serviceWorker.unregister();

import AbstractItemResource from '../abstract/abstract-item-resource';
import {Resource} from 'sdk/src/defines';
import languages from 'language-list';
import {DropDownItem} from '../../utility/types';
const languageList: any = languages();

class Locale extends AbstractItemResource {
    public resourceType: Resource = Resource.LOCALES;

    public declare language: string;

    public declare locales: {[phrase: string]: string};

    public static languagesForDropDown(): DropDownItem[] {
        const data: DropDownItem[] = [];
        languageList.getData().forEach((item: {language: string, code: string}) => {
            data.push({
                label: item.language,
                value: item.code,
            });
        });

        return data;
    }

    public getLanguageStr(): string {
        const languageItem: {language: string} = languageList
            .getData()
            .find((element: {code: string}) => element.code === this.language);

        if (languageItem) {
            return languageItem.language;
        }

        return null;
    }

}

export default Locale;

import AbstractItemResource from '../abstract/abstract-item-resource';
import {PluginType, Resource} from 'sdk/src/defines';
import api from '../../api';
import {Badge} from 'reactstrap';
import plugins from '../plugins';
import Plugin from './plugin';
import {ReactElement} from 'react';
import {TFunction} from 'react-i18next';
import {AttributesType} from '../../utility/types';
import {FormControl} from '../../components/form/FormSet';

class PluginInstance extends AbstractItemResource {
    public resourceType: Resource = Resource.PLUGIN_INSTANCES;

    public declare props: AttributesType;

    public declare pluginId: string;

    public declare version: string;

    public declare published: boolean;

    public declare active: boolean;

    public declare title: string;

    public getPluginType(): PluginType {
        const plugin: Plugin = plugins.findById(this.pluginId);

        if (!plugin) {
            return null;
        }

        return plugin.getType();
    }

    public sendTestMessage(): Promise<any> {
        return api.post(`${Resource.PLUGIN_INSTANCES.toLocaleLowerCase()}/${this.id}/send-test-message`);
    }

    public getAdditionalProps(): Promise<FormControl[]> {
        return api.get(`${Resource.PLUGIN_INSTANCES.toLocaleLowerCase()}/${this.id}/additional-props`);
    }

    public getStatusBadge(t: TFunction): ReactElement {
        if (!this.published) {
            return <Badge color='secondary'>{t('contents.notPublished')}</Badge>;
        }

        if (this.active) {
            return <Badge color='success'>{t('plugins.active')}</Badge>;
        }

        return <Badge color='danger'>{t('plugins.failed')}</Badge>;
    }
}

export default PluginInstance;

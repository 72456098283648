import AbstractListResource from './abstract/abstract-list-resource';
import {Resource, ActionType} from 'sdk/src/defines';
import ContentCategory from './items/content-category';
import {AttributesType, DispatchFunc, DispatchResponse, SortFunc} from '../utility/types';
import {sortAsc} from '../utility/utils';
import contents from './contents';
import Content from './items/content';

class ContentCategories extends AbstractListResource<ContentCategory> {
    public resourceType: Resource = Resource.CONTENT_CATEGORIES;

    public resourceItemClass: typeof ContentCategory = ContentCategory;

    public sort: SortFunc = sortAsc('order');

    public allowedActions: ActionType[] = [
        ActionType.LIST,
        ActionType.GET,
        ActionType.UPDATE,
        ActionType.DELETE,
    ];

    public getConnectedResourceTypes(): Resource[] {
        return [Resource.CONTENTS];
    }

    public onDeleted(items: ContentCategory[]): DispatchResponse {
        return (dispatch: DispatchFunc) => {
            dispatch(super.onDeleted(items));

            if (contents.isInitialized()) {
                items.forEach((item: ContentCategory) => {
                    const content: Content = contents.findById(item.content.id);

                    if (content) {
                        const index: number = content.categories
                            .findIndex((el: AttributesType) => el.categoryId === item.categoryId);

                        if (index >= 0) {
                            content.categories.splice(index, 1);
                        }
                    }
                });
            }
        };
    }
}

export {ContentCategories, ContentCategory};
export default new ContentCategories();

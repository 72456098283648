"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FrameworkResponseCode = exports.ValidationRuleType = exports.ResizeFit = exports.DataBaseTableType = exports.FileType = exports.DataBaseType = exports.ServerType = exports.LogLevel = exports.MicroserviceStatus = exports.ClusterMessageType = exports.FrameworkDbType = exports.FrameworkTableType = exports.ActionType = void 0;
var ActionType;
(function (ActionType) {
    ActionType["UPDATE"] = "update";
    ActionType["CREATE"] = "create";
    ActionType["DELETE"] = "delete";
    ActionType["LIST"] = "list";
    ActionType["GET"] = "get";
})(ActionType = exports.ActionType || (exports.ActionType = {}));
var FrameworkTableType;
(function (FrameworkTableType) {
    FrameworkTableType["MICROSERVICE"] = "microservice";
    FrameworkTableType["FAILED_MESSAGE"] = "failedMessage";
    FrameworkTableType["FILE"] = "file";
    FrameworkTableType["MIGRATION"] = "migration";
})(FrameworkTableType = exports.FrameworkTableType || (exports.FrameworkTableType = {}));
var FrameworkDbType;
(function (FrameworkDbType) {
    FrameworkDbType["MESSENGER"] = "messenger";
    FrameworkDbType["STORAGE"] = "storage";
    FrameworkDbType["MIGRATIONS"] = "migrations";
})(FrameworkDbType = exports.FrameworkDbType || (exports.FrameworkDbType = {}));
var ClusterMessageType;
(function (ClusterMessageType) {
    ClusterMessageType["HOOK"] = "hook";
    ClusterMessageType["GET_SHARED_USER_THREAD"] = "getSharedUserThread";
    ClusterMessageType["SET_SHARED_USER_THREAD"] = "setSharedUserThread";
    ClusterMessageType["RESPONSE_SHARED_USER_THREAD"] = "responseSharedUserThread";
    ClusterMessageType["REQUEST"] = "request";
    ClusterMessageType["RESPONSE"] = "response";
    ClusterMessageType["RESPONSE_CATCH"] = "responseCatch";
    ClusterMessageType["READY"] = "ready";
    ClusterMessageType["ADD_TRACKING_MODEL"] = "addTrackingModel";
    ClusterMessageType["CUSTOM"] = "custom";
    ClusterMessageType["GET_LIVE_COLLECTION"] = "getLiveCollection";
})(ClusterMessageType = exports.ClusterMessageType || (exports.ClusterMessageType = {}));
var MicroserviceStatus;
(function (MicroserviceStatus) {
    MicroserviceStatus["ONLINE"] = "online";
    MicroserviceStatus["OFFLINE"] = "offline";
})(MicroserviceStatus = exports.MicroserviceStatus || (exports.MicroserviceStatus = {}));
var LogLevel;
(function (LogLevel) {
    LogLevel["INFO"] = "info";
    LogLevel["WARNING"] = "warning";
    LogLevel["ERROR"] = "error";
    LogLevel["DEBUG"] = "debug";
})(LogLevel = exports.LogLevel || (exports.LogLevel = {}));
var ServerType;
(function (ServerType) {
    ServerType["PUBLIC"] = "public";
    ServerType["PRIVATE"] = "private";
    ServerType["SOCKET"] = "socket";
})(ServerType = exports.ServerType || (exports.ServerType = {}));
var DataBaseType;
(function (DataBaseType) {
    DataBaseType["STORAGE"] = "storage";
    DataBaseType["MIGRATIONS"] = "migrations";
})(DataBaseType = exports.DataBaseType || (exports.DataBaseType = {}));
var FileType;
(function (FileType) {
    FileType["IMAGE"] = "image";
    FileType["ICO"] = "ico";
    FileType["SRT"] = "srt";
    FileType["ZIP"] = "zip";
    FileType["BINARY"] = "binary";
    FileType["VIDEO"] = "video";
    FileType["AUDIO"] = "audio";
})(FileType = exports.FileType || (exports.FileType = {}));
var DataBaseTableType;
(function (DataBaseTableType) {
    DataBaseTableType["FILE"] = "file";
    DataBaseTableType["MIGRATION"] = "migration";
})(DataBaseTableType = exports.DataBaseTableType || (exports.DataBaseTableType = {}));
var ResizeFit;
(function (ResizeFit) {
    ResizeFit["COVER"] = "cover";
    ResizeFit["CONTAIN"] = "contain";
    ResizeFit["FILL"] = "fill";
    ResizeFit["INSIDE"] = "inside";
    ResizeFit["OUTSIDE"] = "outside";
})(ResizeFit = exports.ResizeFit || (exports.ResizeFit = {}));
var ValidationRuleType;
(function (ValidationRuleType) {
    ValidationRuleType["ACCEPTED"] = "accepted";
    ValidationRuleType["AFTER"] = "after";
    ValidationRuleType["AFTER_OR_EQUAL"] = "after_or_equal";
    ValidationRuleType["ALPHA"] = "alpha";
    ValidationRuleType["ALPHA_DASH"] = "alpha_dash";
    ValidationRuleType["ALPHA_NUM"] = "alpha_num";
    ValidationRuleType["BEFORE"] = "before";
    ValidationRuleType["BEFORE_OR_EQUAL"] = "before_or_equal";
    ValidationRuleType["BETWEEN"] = "between";
    ValidationRuleType["NUMERIC"] = "numeric";
    ValidationRuleType["CONFIRMED"] = "confirmed";
    ValidationRuleType["EMAIL"] = "email";
    ValidationRuleType["DATE"] = "date";
    ValidationRuleType["DEF"] = "def";
    ValidationRuleType["DIGITS"] = "digits";
    ValidationRuleType["DIGITS_BETWEEN"] = "digits_between";
    ValidationRuleType["DIFFERENT"] = "different";
    ValidationRuleType["IN"] = "in";
    ValidationRuleType["INTEGER"] = "integer";
    ValidationRuleType["HEX"] = "hex";
    ValidationRuleType["MIN"] = "min";
    ValidationRuleType["MAX"] = "max";
    ValidationRuleType["NOT_IN"] = "not_in";
    ValidationRuleType["PRESENT"] = "present";
    ValidationRuleType["REQUIRED_IF"] = "required_if";
    ValidationRuleType["REQUIRED_UNLESS"] = "required_unless";
    ValidationRuleType["REQUIRED_WITH"] = "required_with";
    ValidationRuleType["REQUIRED_WITH_ALL"] = "required_with_all";
    ValidationRuleType["REQUIRED_WITHOUT"] = "required_without";
    ValidationRuleType["REQUIRED_WITHOUT_ALL"] = "required_without_all";
    ValidationRuleType["SAME"] = "same";
    ValidationRuleType["SIZE"] = "size";
    ValidationRuleType["BOOLEAN"] = "boolean";
    ValidationRuleType["STRING"] = "string";
    ValidationRuleType["ARRAY"] = "array";
    ValidationRuleType["URL"] = "url";
    ValidationRuleType["REGEX"] = "regex";
    ValidationRuleType["UNIQUE"] = "unique";
    ValidationRuleType["REQUIRED"] = "required";
    ValidationRuleType["BETWEEN_LENGTH"] = "between_length";
    ValidationRuleType["MIN_LENGTH"] = "min_length";
    ValidationRuleType["MAX_LENGTH"] = "max_length";
    ValidationRuleType["INVALID"] = "invalid";
    ValidationRuleType["MAX_ITEMS"] = "max_items";
    ValidationRuleType["UNKNOWN_PARAMETER"] = "unknown";
    ValidationRuleType["FILE"] = "file";
    ValidationRuleType["JSON"] = "json";
    ValidationRuleType["ANY"] = "any";
})(ValidationRuleType = exports.ValidationRuleType || (exports.ValidationRuleType = {}));
exports.FrameworkResponseCode = {
    EMPTY: {
        code: 0,
        message: '',
    },
    OK: {
        code: 1,
        message: 'success.ok',
    },
    VALIDATION_ERROR: {
        code: 101,
        message: 'errors.validation-error',
    },
    USER_UNAUTHENTICATED: {
        code: 103,
        message: 'errors.user-not-authorized',
    },
    INTERNAL_ERROR: {
        code: 104,
        message: 'errors.internal-error',
    },
    INVALID_VALUE: {
        code: 129,
        message: 'errors.invalid-value',
    },
};

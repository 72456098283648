import AbstractListResource from './abstract/abstract-list-resource';
import {ActionType, Resource} from 'sdk/src/defines';
import Voucher from './items/voucher';
import {DropDownItem} from '../utility/types';
import {TFunction} from 'react-i18next';

class Vouchers extends AbstractListResource<Voucher> {
    public resourceType: Resource = Resource.VOUCHERS;

    public resourceItemClass: typeof Voucher = Voucher;

    public allowedActions: ActionType[] = [
        ActionType.LIST,
        ActionType.UPDATE,
        ActionType.CREATE,
        ActionType.DELETE,
    ];

    public export(): Promise<any> {
        return this.api().resourceExport(Resource.VOUCHERS);
    }

    public typesForDropDown(t: TFunction): DropDownItem[] {
        return [
            {label: t('vouchers.balance'), value: 'balance'},
            {label: t('vouchers.subscription'), value: 'subscription'},
        ];
    }
}

export {Vouchers, Voucher};
export default new Vouchers();

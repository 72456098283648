import AbstractItemResource from '../abstract/abstract-item-resource';
import {TemplateType, Resource} from 'sdk/src/defines';
import AbstractResource from '../abstract/abstract-resource';
import {TFunction} from 'react-i18next';
import {DropDownItem} from '../../utility/types';
import pluginInstances from '../plugin-instances';
import PluginInstance from './plugin-instance';

class Template extends AbstractItemResource {
    public resourceType: Resource = Resource.TEMPLATES;

    public declare type: TemplateType;

    public declare pluginInstanceId: string;

    public declare title: string;

    public declare body: string;

    public getTitle(t: TFunction): string {
        return this.title || t('templates.unnamedTemplate');
    }

    public getTypeStr(t: TFunction): string {
        const item: DropDownItem = Template.typesForDropDown(t)
            .find((item: DropDownItem) => item.value === this.type);

        if (item) {
            return item.label;
        }

        return '';
    }

    public static availableMacros(): string[] {
        return [
            'FIRST_NAME', 'LAST_NAME', 'CODE',
            'EMAIL', 'PHONE', 'USERNAME',
            'PASSWORD', 'IP', 'OPERATOR_NAME',
            'COUNTRY', 'CITY', 'ADDRESS', 'ZIP_CODE',
            'PLAN_NAME', 'SHOP_ITEM_NAME', 'PRICE',
            'CURRENCY', 'BEGIN', 'END',
        ];
    }

    public getDeliveryMethodStr(t: TFunction): string {
        const pluginInstance: PluginInstance = pluginInstances.findById(this.pluginInstanceId);

        if (!pluginInstance) {
            return t('templates.noDeliveryMethod');
        }

        return pluginInstance.title;
    }

    public static typesForDropDown(t: TFunction): DropDownItem[] {
        return AbstractResource.defineForDropDown(TemplateType, 'templates', t);
    }
}

export default Template;

import AbstractItemResource from '../abstract/abstract-item-resource';
import Plan from './plan';
import Offer from './offer';
import Subscriber from './subscriber';
import {OfferIntervalType, Resource, SubscriptionStatus} from 'sdk/src/defines';
import AbstractResource from '../abstract/abstract-resource';
import {AttributesType, CastRule, CastType, DetailsType, DropDownItem} from '../../utility/types';
import {TFunction} from 'react-i18next';
import pluginInstances, {PluginInstance} from '../plugin-instances';

class Subscription extends AbstractItemResource {
    public resourceType: Resource = Resource.SUBSCRIPTIONS;

    public declare planId: string;

    public declare ip: string;

    public declare deviceId: string;

    public declare offerId: string;

    public declare subscriberId: string;

    public declare pluginInstanceId: string;

    public declare intervalType: OfferIntervalType;

    public declare currentPeriodStartAt: Date;

    public declare currentPeriodEndAt: Date;

    public declare cancelAtPeriodEnd: boolean;

    public declare status: SubscriptionStatus;

    public declare subscriber: AttributesType;

    public declare plan: AttributesType;

    public declare offer: AttributesType;

    public castValues(): CastType {
        return {
            currentPeriodStartAt: CastRule.DATE,
            currentPeriodEndAt: CastRule.DATE,
        };
    }

    public getDetails(t: TFunction): DetailsType {
        return super.getDetails(t, {
            planId: this.getPlanTitle(t),
        });
    }

    public static statusesForDropDown(t: TFunction, empty?: string): DropDownItem[] {
        const result: DropDownItem[] = [];

        if (empty) {
            result.push({
                label: empty,
                value: null,
            });
        }

        return [...result, ...AbstractResource.defineForDropDown(SubscriptionStatus, 'billing', t)];
    }

    public getSubscriber(): Subscriber {
        if (!this.subscriber) {
            return null;
        }

        return new Subscriber(this.subscriber, null);
    }

    public getPlanTitle(t: TFunction): string {
        if (!this.plan) {
            return null;
        }

        return new Plan(this.plan, null).getTitle(t);
    }

    public getOfferTitle(t: TFunction): string {
        if (!this.offer) {
            return null;
        }

        return new Offer(this.offer, null).getTitle(t);
    }

    public getPaymentSystem(t: TFunction): string {
        if (!this.pluginInstanceId) {
            return t('users.createdByAdmin');
        }

        const pluginInstance: PluginInstance = pluginInstances.findById(this.pluginInstanceId);

        if (!pluginInstance) {
            return t('users.paymentSystemNotFound');
        }

        return pluginInstance.getTitle(t);
    }

    public getStatusStr(t: TFunction): string {
        const item: DropDownItem = Subscription.statusesForDropDown(t)
            .find((item: DropDownItem) => item.value === this.status);

        if (item) {
            return item.label;
        }

        return null;
    }
}

export default Subscription;

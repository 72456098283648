import AbstractItemResource from './abstract-item-resource';
import {DispatchFunc, DispatchResponse} from '../../utility/types';

export default class AbstractFileModelResource extends AbstractItemResource {
    public declare files: {[name: string]: string};

    public deleteFile(name: string = 'file'): DispatchResponse {
        return (dispatch: DispatchFunc): void => {
            this.api().delete(`${this.resourceType.toLowerCase()}/${this.id}/file?name=${name}`).then(() => {
                dispatch(this.refresh());
            }).catch(() => null);
        };
    }

    public uploadFiles(files: {[name: string]: File}): DispatchResponse {
        return async (dispatch: DispatchFunc) => {
            const names: string[] = Object.keys(files);

            for (const name of names) {
                await this.api().upload(`${this.resourceType.toLowerCase()}/${this.id}/file?name=${name}`, {file: files[name]}).catch(() => null)
            }

            dispatch(this.refresh());
        };
    }

    public uploadFile(file: File, name: string = 'file'): DispatchResponse {
        return (dispatch: DispatchFunc): void => {
            const previousValue: string = this.files[name];
            delete this.files[name];
            this.api().upload(`${this.resourceType.toLowerCase()}/${this.id}/file?name=${name}`, {file}).then(() => {
                dispatch(this.refresh());
            }).catch(() => {
                this.files[name] = previousValue;
            });
        };
    }

    public getFileUrl(name: string = 'file'): string {
        if (!this.files || !name) {
            return undefined;
        }

        return this.files[name];
    }

    public deleteImage(name: string = 'image'): DispatchResponse {
        return this.deleteFile(name);
    }

    public getImage(defaultImage?: string, name: string = 'image'): string {
        return this.getFileUrl(name) || defaultImage;
    }

    public uploadImage(file: File, name: string = 'image'): DispatchResponse {
        return this.uploadFile(file, name);
    }

}

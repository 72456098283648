import AbstractListResource from './abstract/abstract-list-resource';
import {Resource, ActionType} from 'sdk/src/defines';
import Locale from './items/locale';
import languages from 'language-list';
import {DropDownItem} from '../utility/types';

class Locales extends AbstractListResource<Locale> {
    public resourceType: Resource = Resource.LOCALES;

    public resourceItemClass: typeof Locale = Locale;

    public allowedActions: ActionType[] = [
        ActionType.LIST,
        ActionType.UPDATE,
        ActionType.DELETE,
        ActionType.CREATE,
        ActionType.DROP_DOWN_LIST,
    ];

    public availableLanguages(): DropDownItem[] {
        if (!this.isInitialized()) {
            return [];
        }

        const result: DropDownItem[] = [];
        this.getItems().forEach((item: Locale) => {
            result.push({
                value: item.language,
                label: languages().getLanguageName(item.language),
            });
        });

        if (!result.length) {
            result.push({
                value: 'en',
                label: languages().getLanguageName('en'),
            });
        }

        return result;
    }
}

export {Locales, Locale};

export default new Locales();

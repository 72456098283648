import AbstractItemResource from '../abstract/abstract-item-resource';
import {RoleType, Resource} from 'sdk/src/defines';
import AbstractResource from '../abstract/abstract-resource';
import {AttributesType, DropDownItem} from '../../utility/types';
import {TFunction} from 'react-i18next';

class Role extends AbstractItemResource {
    public resourceType: Resource = Resource.PLUGIN_INSTANCES;

    public declare type: RoleType;

    public declare default: boolean;

    public declare permissions: AttributesType;

    public declare title: string;

    public static typesForDropDown(t: TFunction): DropDownItem[] {
        return AbstractResource.defineForDropDown(RoleType, 'roles', t);
    }

    public static editableTypesForDropDown(t: TFunction): DropDownItem[] {
        return [
            {
                value: RoleType.ADMINISTRATOR,
                label: t('roles.administrator'),
            },
            {
                value: RoleType.DEALER,
                label: t('roles.dealer'),
            },
            {
                value: RoleType.CONTENT_OWNER,
                label: t('roles.contentOwner'),
            },
        ];
    }

    public getColor(): string {
        switch (this.type) {
            case RoleType.OPERATOR:
                return 'primary';
            case RoleType.ADMINISTRATOR:
                return 'warning';
            case RoleType.DEALER:
                return 'secondary';
            case RoleType.CONTENT_OWNER:
                return 'info';
            default:
                return 'danger';
        }
    }

    public getTitle(t: TFunction): string {
        switch (this.type) {
            case RoleType.OPERATOR:
                return t('roles.operator');
            default:
                return this.title || t('roles.unnamedRole');
        }
    }
}

export default Role;

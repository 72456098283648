import AbstractItemResource from '../abstract/abstract-item-resource';
import {LogAction, Resource} from 'sdk/src/defines';
import users from '../users';
import User from './user';
import {TFunction} from 'react-i18next';
import {IconDefinition} from '@fortawesome/fontawesome-svg-core';
import {faEdit, faPlusSquare, faTrashAlt} from '@fortawesome/free-regular-svg-icons';

class Log extends AbstractItemResource {
    public resourceType: Resource = Resource.LOGS;

    public declare userId: string;

    public declare resourceIds: string;

    public declare ip: string;

    public declare resource: Resource;

    public declare action: LogAction;

    public declare data: any;

    public getUser(): User {
        return users.findById(this.userId);
    }

    public getAction(t: TFunction): string {
        switch (this.action) {
            case LogAction.DELETE:
                return t('logs.delete');
            case LogAction.UPDATE:
                return t('logs.edit');
            case LogAction.CREATE:
                return t('logs.create');
            case LogAction.LOGIN:
                return t('logs.login');
            case LogAction.LOGOUT:
                return t('logs.logout');
        }
    }

    public getIcon(): IconDefinition {
        switch (this.action) {
            case LogAction.UPDATE:
                return faEdit;
            case LogAction.CREATE:
                return faPlusSquare;
            case LogAction.DELETE:
                return faTrashAlt;
            default:
                return null;
        }
    }
}

export default Log;

import AbstractListResource from './abstract/abstract-list-resource';
import {ActionType, Resource} from 'sdk/src/defines';
import Operator from './items/operator';

class Operators extends AbstractListResource<Operator> {
    public resourceType: Resource = Resource.OPERATORS;

    public resourceItemClass: typeof Operator = Operator;

    public allowedActions: ActionType[] = [
        ActionType.LIST,
        ActionType.UPDATE,
        ActionType.DELETE,
        ActionType.CREATE,
    ];

    public isSuperAdminResource(): boolean {
        return true;
    }

}

export {Operators, Operator};
export default new Operators();

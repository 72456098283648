import AbstractItemResource from '../abstract/abstract-item-resource';
import {Resource, Uid} from 'sdk/src/defines';
import {CastRule, CastType} from '../../utility/types';

class CategoryCategory extends AbstractItemResource {
    public resourceType: Resource = Resource.CATEGORY_CATEGORIES;

    public declare order: number;

    public declare categoryId: string;

    public declare referenceCategory: {
        title: string,
        id: Uid,
    };

    public castValues(): CastType {
        return {
            order: CastRule.FLOAT,
        };
    }
}

export default CategoryCategory;

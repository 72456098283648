import AbstractListResource from './abstract/abstract-list-resource';
import {ActionType, Resource, TagType, Uid} from 'sdk/src/defines';
import Tag from './items/tag';
import {TFunction} from 'react-i18next';
import {DropDownItem} from '../utility/types';

class Tags extends AbstractListResource<Tag> {
    public resourceType: Resource = Resource.TAGS;

    public resourceItemClass: typeof Tag = Tag;

    public allowedActions: ActionType[] = [
        ActionType.LIST,
        ActionType.UPDATE,
        ActionType.CREATE,
        ActionType.DELETE,
        ActionType.UPLOAD,
    ];

    public hasTag(type: TagType, id: Uid): boolean {
        const item: Tag = this.findById(id);

        return !(!item || item.type !== type);
    }

    public forDropDown(t: TFunction, empty?: string, type?: TagType): DropDownItem[] {
        if (!this.isInitialized()) {
            return [];
        }

        const result: DropDownItem[] = [];

        this.getItems().forEach((item: Tag) => {
            if ((!type || item.type === type)) {
                result.push({
                    label: item.name,
                    value: item.id,
                });
            }
        });

        return result;
    }
}

export {Tags, Tag};
export default new Tags();

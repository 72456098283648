import AbstractItemResource from '../abstract/abstract-item-resource';
import {Resource} from 'sdk/src/defines';
import {TFunction} from 'react-i18next';
import profile from '../profile';

class Operator extends AbstractItemResource {
    public resourceType: Resource = Resource.OPERATORS;

    public declare name: string;

    public getName(t: TFunction): string {
        return this.name || t('operators.unnamedOperator');
    }

    public getTitle(t: TFunction): string {
        return this.getName(t);
    }

    public isLoggedIn(): boolean {
        return profile.organizationId === this.id;
    }

    public login(): Promise<void> {
        return this.api().resourceUpdate(Resource.OPERATOR, this.id, {}).then(() => {
            location.href = '/';
        });
    }
}

export default Operator;

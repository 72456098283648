import AbstractItemResource from '../abstract/abstract-item-resource';
import {Resource} from 'sdk/src/defines';
import User from './user';
import {DropDownItem} from '../../utility/types';

class Term extends AbstractItemResource {
    public resourceType: Resource = Resource.TERMS;

    public declare language: string;

    public declare text: string;

    public getLanguage(): string {
        const element: DropDownItem = User.languagesForDropDown()
            .find((element: DropDownItem) => element.value === this.language);

        if (element) {
            return element.label;
        }

        return null;
    }
}

export default Term;

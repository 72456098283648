import abstractReducer, { ReduxStatus} from './abstract-reducer';
import {Api} from '../../api';
import {Resource} from 'sdk/src/defines';
import AbstractResource from './abstract-resource';

class ResourceCollection {
    private api: Api;

    private readonly resources: {[key: string]: AbstractResource} = {}

    private readonly reducers: {[key: string]: any} = {}

    public getApi(): Api {
        return this.api;
    }

    public setApi(api: Api): void {
        this.api = api;
    }

    public addList(resourceInstances: AbstractResource[] ): void {
        resourceInstances.forEach((item: AbstractResource) => {
            this.add(item);
        })
    }

    public add(resourceInstance: AbstractResource): void {
        this.resources[resourceInstance.resourceType] = resourceInstance;

        this.reducers[resourceInstance.resourceType] = (state: {
            instance: AbstractResource,
            status: string,
        } = {instance: resourceInstance, status: ReduxStatus.UNKNOWN}, action: string = ''): any => {
            return abstractReducer(state, action, resourceInstance.resourceType);
        };
    }

    public getReducers(): {[key: string]: any} {
        return this.reducers;
    }

    public getResources(): {[key: string]: AbstractResource} {
        return this.resources;
    }

    public getResource(type: Resource): AbstractResource {
        return this.resources[type];
    }
}

export default new ResourceCollection();

import defaultAvatar from '@src/assets/images/avatars/default.png';
import {Resource, Uid} from 'sdk/src/defines';
import {AttributesType, CastRule, CastType} from '../../utility/types';
import AbstractFileModelResource from '../abstract/abstract-file-model-resource';
import {TFunction} from 'react-i18next';

class Subscriber extends AbstractFileModelResource {
    public resourceType: Resource = Resource.SUBSCRIBERS;

    public declare dealerId: string;

    public declare externalId: string;

    public declare firstName: string;

    public declare lastName: string;

    public declare password: string;

    public declare provider: string;

    public declare emailVerified: boolean;

    public declare phoneVerified: boolean;

    public declare emailVerificationToken: string;

    public declare phoneVerificationToken: string;

    public declare phone: string;

    public declare username: string;

    public declare email: string;

    public declare disabled: boolean;

    public declare parentalPin: string;

    public declare balance: number;

    public declare npvrLimit: number;

    public declare maxConcurrentConnections: number;

    public declare birthday: Date;

    public declare deletedAt: Date;

    public declare deleteAt: Date;

    public declare country: string;

    public declare address: string;

    public declare city: string;

    public declare zipCode: string;

    public declare groups: {groupId: Uid}[];

    public declare props: AttributesType;

    public castValues(): CastType {
        return {
            balance: CastRule.FLOAT,
            birthday: CastRule.DATE,
            deletedAt: CastRule.DATE,
            deleteAt: CastRule.DATE,
        };
    }

    public getFullName(): string {
        if (this.firstName && this.lastName) {
            return `${this.firstName} ${this.lastName}`;
        }

        return this.firstName;
    }

    public isDeleted(): boolean {
        return this.disabled && !!this.deletedAt?.getTime();
    }

    public isWaitingForDelete(): boolean {
        return this.disabled && !!this.deleteAt?.getTime();
    }

    public getAvatar(): string {
        return this.getImage(defaultAvatar, 'avatar');
    }

    public getStatusColor(): string {
        if (this.isDeleted() || this.isWaitingForDelete()) {
            return 'info';
        } else if (this.disabled) {
            return 'dark';
        } else if (!this.emailVerified || !this.phoneVerified) {
            return 'warning';
        }

        return 'success';
    }

    public getStatus(t: TFunction): string {
        if (this.isDeleted()) {
            return t('users.removed');
        } else if (this.isWaitingForDelete()) {
            return t('users.waitingForTotallyRemoving');
        } else if (this.disabled) {
            return t('users.disabled');
        } else if (!this.emailVerified && !this.phoneVerified) {
            return t('users.emailAndPhoneVerification');
        } else if (!this.emailVerified) {
            return t('users.emailVerification');
        } else if (!this.phoneVerified) {
            return t('users.phoneVerification');
        }

        return t('users.active');
    }
}

export default Subscriber;

// ** Checks if an object is empty (returns boolean)
import * as status from '../api/statuses';

export const replaceAll = (str, find, replace) => {
    return str.replace(new RegExp(find, 'g'), replace);
};

export const isObjEmpty = obj => Object.keys(obj).length === 0;

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num);

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '');

// ** Checks if the passed date is today
const isToday = date => {
    const today = new Date();

    return (
    /* eslint-disable operator-linebreak */
        date.getDate() === today.getDate() &&
		date.getMonth() === today.getMonth() &&
		date.getFullYear() === today.getFullYear()
		/* eslint-enable */
    );
};

export const hmsToSeconds = (hms) => {
    const a = hms.split(':');

    if (a.length !== 3) {
        return 0;
    }

    return Math.round((a[0].trim()) * 60 * 60) + (parseInt(a[1].trim()) * 60) + parseInt(a[2].trim());
};

export const secondsToHms = (d) => {
    d = Number(d);
    const h = Math.floor(d / 3600);
    const m = Math.floor(d % 3600 / 60);
    const s = Math.floor(d % 3600 % 60);
    const arr = [];
    arr.push(h < 10 ? `0${h}` : h);
    arr.push(m < 10 ? `0${m}` : m);
    arr.push(s < 10 ? `0${s}` : s);

    return arr.join(':');
};

export const shortStr = (str, maxLength) => {
    if (str.length < maxLength) {
        return str;
    }

    return `${str.substr(0, maxLength)}...`;
};

export const timeToString = (time, withSeconds = false) => {
    const date = new Date(time);
    let result = '';

    if (date.getHours() < 10) {
        result += '0';
    }

    result += `${date.getHours()}:`;

    if (date.getMinutes() < 10) {
        result += '0';
    }

    result += date.getMinutes();

    if (withSeconds) {
        result += ':';

        if (date.getSeconds() < 10) {
            result += '0';
        }

        result += date.getSeconds();
    }

    return result;
};

export const humanDuration = (seconds) => {
    seconds = Math.round(parseFloat(seconds));

    const levels = [
        [Math.floor(seconds / 31536000), 'y'],
        [Math.floor((seconds % 31536000) / 86400), 'd'],
        [Math.floor(((seconds % 31536000) % 86400) / 3600), 'h'],
        [Math.floor((((seconds % 31536000) % 86400) % 3600) / 60), 'm'],
        [(((seconds % 31536000) % 86400) % 3600) % 60, 's'],
    ];

    let returnText = '';

    for (let i = 0, max = levels.length; i < max; i++) {
        if (levels[i][0] === 0) {
            continue;
        }

        returnText += ` ${levels[i][0]}${levels[i][1]}`;
    }

    return returnText.trim();
};

export const humanFileSize = (bytes, si = false, dp = 1) => {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return `${bytes} B`;
    }

    const units = si ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 * dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return `${bytes.toFixed(dp)} ${units[u]}`;
};

export const formatTime = (value, locale = 'en-US', formatting: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit', second: '2-digit' }) => {
    return new Intl.DateTimeFormat(locale, formatting).format(new Date(value));
};
/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, locale = 'en-US', formatting: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric', year: 'numeric' }) => {
    if (!value) {
        return value;
    }

    return new Intl.DateTimeFormat(locale, formatting).format(new Date(value));
};

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
    const date = new Date(value);
    let formatting: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric' };

    if (toTimeForCurrentDay && isToday(date)) {
        formatting = { hour: 'numeric', minute: 'numeric' };
    }

    return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value));
};

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData');
export const getUserData = () => JSON.parse(localStorage.getItem('userData'));

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
    if (userRole === 'admin') return '/';

    if (userRole === 'client') return '/access-control';

    return '/login';
};

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary25: '#7367f01a', // for option hover bg-color
        primary: '#7367f0', // for selected option bg-color
        neutral10: '#7367f0', // for tags bg-color
        neutral20: '#ededed', // for input border-color
        neutral30: '#ededed', // for input hover border-color
    },
});

export const catchApiResponse = (err, t, setError) => {
    if (err.status === status.SERVER_ERROR) {
        window.location.href = '/500';
    } else if (err.status === status.NOT_AUTHORIZED) {
        window.location.href = '/login';
    } else if (setError && err.validation) {
        Object.keys(err.validation).forEach(fieldName => {
            setError(fieldName, {
                message: t(`errors.${err.validation[fieldName]}`),
            });
        });
    }
};

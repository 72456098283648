import Content from './content';
import {Resource} from 'sdk/src/defines';
import {DispatchFunc, DispatchResponse} from '../../utility/types';

class Season extends Content {
    public resourceType: Resource = Resource.SEASONS;

    public deleteFile(name: string = 'file'): DispatchResponse {
        return (dispatch: DispatchFunc): void => {
            this.api().delete(`${Resource.CONTENTS.toLowerCase()}/${this.id}/file?name=${name}`).then(() => {
                dispatch(this.refresh());
            }).catch(() => null);
        };
    }

    public uploadFiles(files: {[name: string]: File}): DispatchResponse {
        return async (dispatch: DispatchFunc) => {
            const names: string[] = Object.keys(files);

            for (const name of names) {
                await this.api().upload(`${Resource.CONTENTS.toLowerCase()}/${this.id}/file?name=${name}`, {file: files[name]}).catch(() => null)
            }

            dispatch(this.refresh());
        };
    }

    public uploadFile(file: File, name: string = 'file'): DispatchResponse {
        return (dispatch: DispatchFunc): void => {
            const previousValue: string = this.files[name];
            delete this.files[name];
            this.api().upload(`${Resource.CONTENTS.toLowerCase()}/${this.id}/file?name=${name}`, {file}).then(() => {
                dispatch(this.refresh());
            }).catch(() => {
                this.files[name] = previousValue;
            });
        };
    }
}

export default Season;

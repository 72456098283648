import AbstractResource from './abstract/abstract-resource';
import {Resource, ActionType} from 'sdk/src/defines';


class Config extends AbstractResource {
    public resourceType: Resource = Resource.CONFIG;

    public allowedActions: ActionType[] = [
        ActionType.GET,
    ];
}

export default new Config();

import AbstractItemResource from '../abstract/abstract-item-resource';
import {Resource, DiscountType, Uid} from 'sdk/src/defines';
import {CastRule, CastType} from '../../utility/types';

class Discount extends AbstractItemResource {
    public resourceType: Resource = Resource.DISCOUNTS;

    public declare order: number;

    public declare value: number;

    public declare published: boolean;

    public declare title: string;

    public declare type: DiscountType;

    public declare groups: {groupId: Uid}[];

    public castValues(): CastType {
        return {
            order: CastRule.FLOAT,
        };
    }

}

export default Discount;

import AbstractListResource from './abstract/abstract-list-resource';
import {Resource, ActionType} from 'sdk/src/defines';
import CustomTagType from './items/custom-tag-type';
import {SortFunc} from '../utility/types';
import {sortAsc} from '../utility/utils';

class CustomTagTypes extends AbstractListResource<CustomTagType> {
    public resourceType: Resource = Resource.CUSTOM_TAG_TYPES;

    public resourceItemClass: typeof CustomTagType = CustomTagType;

    public sort: SortFunc = sortAsc('order');

    public allowedActions: ActionType[] = [
        ActionType.LIST,
        ActionType.GET,
        ActionType.UPDATE,
        ActionType.DELETE,
        ActionType.CREATE,
    ];


}

export {CustomTagTypes, CustomTagType};

export default new CustomTagTypes();

import defaultAvatar from '@src/assets/images/avatars/default.png';
import Role from './role';
import languages from 'language-list';
const languageList: any = languages();
import CurrencyList from 'currency-list';
import countryList from 'country-list';
import {Resource, OS, RoleType, Uid, PermissionsType} from 'sdk/src/defines';
import {CastRule, CastType, DropDownItem} from '../../utility/types';
import AbstractFileModelResource from '../abstract/abstract-file-model-resource';
import {TFunction} from 'react-i18next';
import roles from '../roles';

class User extends AbstractFileModelResource {
    public resourceType: Resource = Resource.USERS;

    public declare firstName: string;

    public declare lastName: string;

    public declare roleId: string;

    public declare provider: string;

    public declare emailVerified: boolean;

    public declare email: string;

    public declare disabled: boolean;

    public declare balance: number;

    public declare role: {
        id: Uid,
        type: RoleType,
        permissions: PermissionsType,
    };

    public castValues(): CastType {
        return {
            balance: CastRule.FLOAT,
        };
    }

    public static currenciesForDropDown(language: string): DropDownItem[] {
        const result: DropDownItem[] = [];
        const list: string[] = Object.keys(CurrencyList.getAll('en_US'));
        list.forEach((key: string) => {
            const item: {code: string, symbol: string, name_plural: string} = CurrencyList.get(key, language);
            result.push({
                value: key,
                label: `${item.name_plural} (${item.code} / ${item.symbol})`,
            });
        });

        result.push({
            value: 'KGS',
            label: 'Kyrgyz som',
        });

        return result;
    }

    public static languagesForDropDown(empty?: string): DropDownItem[] {
        const list: {language: string, code: string}[] = languageList.getData();
        const data: DropDownItem[] = [];

        if (empty) {
            data.push({
                label: empty,
                value: null,
            });
        }

        list.forEach((item: {language: string, code: string}) => {
            data.push({
                label: item.language,
                value: item.code,
            });
        });

        return data;
    }

    public static countriesForDropDown(): DropDownItem[] {
        const data: DropDownItem[] = [];
        countryList.getData().forEach((item: {name: string, code: string}) => {
            data.push({
                label: item.name,
                value: item.code,
            });
        });

        return data;
    }

    public static osToStr(os: OS): string {
        const item: DropDownItem = User.osForDropDown()
            .find((el: DropDownItem) => el.value === os);

        if (item) {
            return item.label;
        }

        return '';
    }

    public getFullName(): string {
        if (this.firstName && this.lastName) {
            return `${this.firstName} ${this.lastName}`;
        }

        return this.firstName;
    }

    public getAvatar(): string {
        return super.getImage(defaultAvatar, 'avatar');
    }

    public getTitle(): string {
        return this.getFullName();
    }

    public getStatusColor(): string {
        if (this.disabled) {
            return 'dark';
        } else if (!this.emailVerified) {
            return 'warning';
        }

        return 'success';
    }

    public getStatus(t: TFunction): string {
        if (this.disabled) {
            return t('users.disabled');
        } else if (!this.emailVerified) {
            return t('users.emailVerification');
        }

        return t('users.active');
    }

    public getRole(): Role {
        if (this.role) {
            return new Role(this.role, null);
        }

        return roles.findById(this.roleId);
    }

    public getRoleType(): RoleType {
        const role: Role = this.getRole();

        if (!role) {
            return null;
        }

        return role.type;
    }
}

export default User;

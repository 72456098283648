import AbstractItemResource from '../abstract/abstract-item-resource';
import {Resource} from 'sdk/src/defines';
import {TFunction} from 'react-i18next';
import {DetailsType} from '../../utility/types';

class Package extends AbstractItemResource {
    public resourceType: Resource = Resource.PACKAGES;

    public declare title: string;

    public getTitle(t: TFunction): string {
        return this.title || t('contents.unnamedPackage');
    }

    public getDetails(t: TFunction): DetailsType {
        return super.getDetails(t, {
            title: this.getTitle(t),
        });
    }

}

export default Package;

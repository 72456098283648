"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MicroserviceMode = exports.MicroserviceType = exports.DbType = exports.ActionType = exports.LicenseFeatureType = exports.PluginActionType = exports.LogAction = exports.GeoIpMode = exports.GeoIpRuleType = exports.FormFieldType = exports.FormType = exports.CategoryMode = exports.PanelType = exports.OfferIntervalType = exports.MenuType = exports.MenuOrientation = exports.UpdateType = exports.ClientType = exports.ApplicationType = exports.CommercialType = exports.AnnouncementType = exports.TemplateType = exports.CategoryType = exports.ContentAspect = exports.ContentStatusType = exports.ContentType = exports.TrackStatus = exports.TrackType = exports.UserStatus = exports.SubscriptionStatus = exports.PaymentType = exports.RoleType = exports.PluginSubType = exports.PluginMediaType = exports.PluginType = exports.DeliveryMethod = exports.PaymentStatus = exports.PaymentMethod = exports.VoucherType = exports.VoucherStatus = exports.OS = exports.FileType = exports.DiscountType = exports.TableType = exports.OperatorSettings = exports.VariableType = exports.TagType = exports.Resource = exports.PluginInstanceStatusType = exports.LogLevel = void 0;
exports.ResponseCode = exports.ResizeFit = exports.ClusterMessageType = void 0;
const defines_1 = require("framework/defines");
Object.defineProperty(exports, "LogLevel", { enumerable: true, get: function () { return defines_1.LogLevel; } });
var PluginInstanceStatusType;
(function (PluginInstanceStatusType) {
    PluginInstanceStatusType["EMPTY"] = "empty";
    PluginInstanceStatusType["DISABLED"] = "disabled";
    PluginInstanceStatusType["LOADING"] = "loading";
    PluginInstanceStatusType["ACTIVE"] = "active";
})(PluginInstanceStatusType = exports.PluginInstanceStatusType || (exports.PluginInstanceStatusType = {}));
var Resource;
(function (Resource) {
    Resource["PROFILE"] = "profile";
    Resource["THEME"] = "theme";
    Resource["CATEGORIES"] = "categories";
    Resource["ANNOUNCEMENTS"] = "announcements";
    Resource["CONTENTS"] = "contents";
    Resource["SEASONS"] = "seasons";
    Resource["EPISODES"] = "episodes";
    Resource["TRACKS"] = "tracks";
    Resource["OFFERS"] = "offers";
    Resource["PACKAGES"] = "packages";
    Resource["GROUPS"] = "groups";
    Resource["SUBTITLES"] = "subtitles";
    Resource["CUSTOM_TAG_TYPES"] = "customTagTypes";
    Resource["DISCOUNTS"] = "discounts";
    Resource["USERS"] = "users";
    Resource["SUBSCRIBERS"] = "subscribers";
    Resource["ROLES"] = "roles";
    Resource["LOGS"] = "logs";
    Resource["SUBSCRIPTIONS"] = "subscriptions";
    Resource["PLANS"] = "plans";
    Resource["OPERATOR"] = "operator";
    Resource["OPERATORS"] = "operators";
    Resource["API_KEYS"] = "apiKeys";
    Resource["VOUCHERS"] = "vouchers";
    Resource["TEMPLATES"] = "templates";
    Resource["LOCALES"] = "locales";
    Resource["FORMS"] = "forms";
    Resource["CONTENT_CATEGORIES"] = "contentCategories";
    Resource["PLAN_CATEGORIES"] = "planCategories";
    Resource["CATEGORY_CATEGORIES"] = "categoryCategories";
    Resource["TAGS"] = "tags";
    Resource["COMMERCIALS"] = "commercials";
    Resource["CAMPAIGNS"] = "campaigns";
    Resource["MENUS"] = "menus";
    Resource["TERMS"] = "terms";
    Resource["PLUGIN_INSTANCES"] = "pluginInstances";
    Resource["GEOIPS"] = "geoips";
    Resource["PLUGINS"] = "plugins";
    Resource["PANELS"] = "panels";
    Resource["STATISTICS"] = "statistics";
    Resource["PAYMENTS"] = "payments";
    Resource["DEVICES"] = "devices";
    Resource["UPDATES"] = "updates";
    Resource["EPGS"] = "epgs";
    Resource["CONFIG"] = "config";
})(Resource = exports.Resource || (exports.Resource = {}));
var TagType;
(function (TagType) {
    TagType["DIRECTOR"] = "director";
    TagType["ACTOR"] = "actor";
    TagType["WRITER"] = "writer";
    TagType["AWARD"] = "award";
    TagType["GENRE"] = "genre";
    TagType["PRODUCTION_COMPANY"] = "productionCompany";
    TagType["KEYWORD"] = "keyword";
})(TagType = exports.TagType || (exports.TagType = {}));
var VariableType;
(function (VariableType) {
    VariableType["INTEGER"] = "integer";
    VariableType["STRING"] = "string";
    VariableType["TEXT"] = "text";
    VariableType["SELECT"] = "select";
    VariableType["MULTISELECT"] = "multiselect";
    VariableType["DATE"] = "date";
    VariableType["DURATION"] = "duration";
})(VariableType = exports.VariableType || (exports.VariableType = {}));
class OperatorSettings {
    constructor() {
        Object.defineProperty(this, "currency", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "npvrLimit", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "maxConcurrentConnections", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "hooksEnabled", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "hooksUrl", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "hooksModels", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
}
exports.OperatorSettings = OperatorSettings;
var TableType;
(function (TableType) {
    TableType["CATEGORY"] = "category";
    TableType["ANNOUNCEMENT"] = "announcement";
    TableType["CONTENT"] = "content";
    TableType["TRACK"] = "track";
    TableType["SUBTITLE"] = "subtitle";
    TableType["OFFER"] = "offer";
    TableType["PACKAGE"] = "package";
    TableType["USER"] = "user";
    TableType["ROLE"] = "role";
    TableType["LOG"] = "log";
    TableType["SUBSCRIPTION"] = "subscription";
    TableType["PLAN"] = "plan";
    TableType["OPERATOR"] = "operator";
    TableType["API_KEY"] = "apiKey";
    TableType["VOUCHER"] = "voucher";
    TableType["TEMPLATE"] = "template";
    TableType["LOCALE"] = "locale";
    TableType["FORM"] = "form";
    TableType["DEVICE"] = "device";
    TableType["COMMERCIAL_STATISTIC"] = "commercialStatistic";
    TableType["COMMERCIAL"] = "commercial";
    TableType["CAMPAIGN"] = "campaign";
    TableType["CAMPAIGN_PLAN"] = "campaignPlan";
    TableType["MENU"] = "menu";
    TableType["TERM"] = "term";
    TableType["PLUGIN_INSTANCE"] = "pluginInstance";
    TableType["GEOIP"] = "geoip";
    TableType["PLUGIN"] = "plugin";
    TableType["PLUGIN_SOURCE_CODE"] = "pluginSourceCode";
    TableType["PANEL"] = "panel";
    TableType["TAG"] = "tag";
    TableType["CUSTOM_TAG_TYPE"] = "customTagType";
    TableType["TAG_CONTENT"] = "tagContent";
    TableType["TAG_EPG"] = "tagEpg";
    TableType["STATISTIC"] = "statistic";
    TableType["ONLINE_USERS"] = "onlineUsers";
    TableType["PAYMENT"] = "payment";
    TableType["FAVORITE"] = "favorite";
    TableType["RATING"] = "rating";
    TableType["REFRESH_TOKEN"] = "refreshToken";
    TableType["SUBSCRIBER_REFRESH_TOKEN"] = "subscriberRefreshToken";
    TableType["RECORD"] = "record";
    TableType["EPG"] = "epg";
    TableType["PLAN_ROLE"] = "planRole";
    TableType["PLAN_USER"] = "planUser";
    TableType["SUBSCRIBER"] = "subscriber";
    TableType["CONTENT_PACKAGE"] = "contentPackage";
    TableType["SUBSCRIBER_GROUP"] = "subscriberGroup";
    TableType["GROUP"] = "group";
    TableType["ANNOUNCEMENT_GROUP"] = "announcementGroup";
    TableType["CONTENT_CATEGORY"] = "contentCategory";
    TableType["PLAN_CATEGORY"] = "planCategory";
    TableType["CATEGORY_CATEGORY"] = "categoryCategory";
    TableType["DISCOUNT"] = "discount";
    TableType["DISCOUNT_GROUP"] = "discountGroup";
    TableType["PLAN_GROUP"] = "planGroup";
    TableType["OFFER_DISCOUNT"] = "offerDiscount";
    TableType["CONTENT_DISCOUNT"] = "contentDiscount";
    TableType["PURCHASED_CONTENT"] = "purchasedContent";
    TableType["LAST_WATCHED_CONTENT"] = "lastWatchedContent";
    TableType["MICROSERVICE"] = "microservice";
    TableType["FILE"] = "file";
    TableType["FAILED_MESSAGE"] = "failedMessage";
    TableType["MIGRATION"] = "migration";
    TableType["LINK"] = "link";
    TableType["UPDATE"] = "update";
    TableType["AUTH_SESSION"] = "authSession";
})(TableType = exports.TableType || (exports.TableType = {}));
var DiscountType;
(function (DiscountType) {
    DiscountType["PERCENT"] = "percent";
    DiscountType["ABSOLUTE"] = "absolute";
})(DiscountType = exports.DiscountType || (exports.DiscountType = {}));
var FileType;
(function (FileType) {
    FileType["IMAGE"] = "image";
    FileType["ICO"] = "ico";
    FileType["SRT"] = "srt";
    FileType["ZIP"] = "zip";
    FileType["BINARY"] = "binary";
})(FileType = exports.FileType || (exports.FileType = {}));
var OS;
(function (OS) {
    OS["ANDROID_MOBILE"] = "androidMobile";
    OS["ANDROID_TV"] = "androidTV";
    OS["ANDROID_BROWSER"] = "androidBrowser";
    OS["IOS"] = "iosMobile";
    OS["IOS_BROWSER"] = "iosBrowser";
    OS["TIZEN"] = "tizen";
    OS["WEBOS"] = "webos";
    OS["ROKU"] = "roku";
    OS["WINDOWS_BROWSER"] = "windowsBrowser";
    OS["LINUX_BROWSER"] = "linuxBrowser";
    OS["MACOS_BROWSER"] = "macosBrowser";
})(OS = exports.OS || (exports.OS = {}));
var VoucherStatus;
(function (VoucherStatus) {
    VoucherStatus["ACTIVE"] = "active";
    VoucherStatus["ACTIVATED"] = "activated";
})(VoucherStatus = exports.VoucherStatus || (exports.VoucherStatus = {}));
var VoucherType;
(function (VoucherType) {
    VoucherType["BALANCE"] = "balance";
    VoucherType["SUBSCRIPTION"] = "subscription";
})(VoucherType = exports.VoucherType || (exports.VoucherType = {}));
var PaymentMethod;
(function (PaymentMethod) {
    PaymentMethod["BALANCE"] = "balance";
    PaymentMethod["PLUGIN"] = "plugin";
    PaymentMethod["VOUCHER"] = "voucher";
    PaymentMethod["DASHBOARD"] = "dashboard";
})(PaymentMethod = exports.PaymentMethod || (exports.PaymentMethod = {}));
var PaymentStatus;
(function (PaymentStatus) {
    PaymentStatus["PAID"] = "paid";
    PaymentStatus["UNPAID"] = "unpaid";
    PaymentStatus["CANCELLED"] = "cancelled";
})(PaymentStatus = exports.PaymentStatus || (exports.PaymentStatus = {}));
var DeliveryMethod;
(function (DeliveryMethod) {
    DeliveryMethod["SMTP"] = "smtp";
    DeliveryMethod["SMS"] = "sms";
    DeliveryMethod["CUSTOM"] = "custom";
})(DeliveryMethod = exports.DeliveryMethod || (exports.DeliveryMethod = {}));
var PluginType;
(function (PluginType) {
    PluginType["SERVER"] = "server";
    PluginType["PAYMENT"] = "payment";
    PluginType["NOTIFICATION"] = "notification";
    PluginType["GEOIP"] = "geoip";
    PluginType["METADATA"] = "metadata";
    PluginType["AUTHORIZATION"] = "authorization";
    PluginType["CONTENT"] = "content";
    PluginType["EPG"] = "epg";
})(PluginType = exports.PluginType || (exports.PluginType = {}));
var PluginMediaType;
(function (PluginMediaType) {
    PluginMediaType["CHANNEL"] = "channel";
    PluginMediaType["VIDEO"] = "video";
    PluginMediaType["AUDIO"] = "audio";
    PluginMediaType["RADIO"] = "radio";
})(PluginMediaType = exports.PluginMediaType || (exports.PluginMediaType = {}));
var PluginSubType;
(function (PluginSubType) {
    PluginSubType["VOD"] = "vod";
    PluginSubType["MEDIA_SERVER"] = "ms";
    PluginSubType["TRANSCODER"] = "transcoder";
    PluginSubType["SMS"] = "sms";
    PluginSubType["SMTP"] = "smtp";
    PluginSubType["BALANCE"] = "balance";
})(PluginSubType = exports.PluginSubType || (exports.PluginSubType = {}));
var RoleType;
(function (RoleType) {
    RoleType["OPERATOR"] = "operator";
    RoleType["ADMINISTRATOR"] = "administrator";
    RoleType["CONTENT_OWNER"] = "contentOwner";
    RoleType["DEALER"] = "dealer";
})(RoleType = exports.RoleType || (exports.RoleType = {}));
var PaymentType;
(function (PaymentType) {
    PaymentType["BALANCE"] = "balance";
    PaymentType["SUBSCRIPTION"] = "subscription";
    PaymentType["CONTENT"] = "content";
})(PaymentType = exports.PaymentType || (exports.PaymentType = {}));
var SubscriptionStatus;
(function (SubscriptionStatus) {
    SubscriptionStatus["ACTIVE"] = "active";
    SubscriptionStatus["TRIALING"] = "trialing";
    SubscriptionStatus["CANCELLED"] = "cancelled";
    SubscriptionStatus["EXPIRED"] = "expired";
})(SubscriptionStatus = exports.SubscriptionStatus || (exports.SubscriptionStatus = {}));
var UserStatus;
(function (UserStatus) {
    UserStatus[UserStatus["BANNED"] = 0] = "BANNED";
    UserStatus[UserStatus["ACTIVE"] = 1] = "ACTIVE";
    UserStatus[UserStatus["CONFIRMATION"] = 2] = "CONFIRMATION";
})(UserStatus = exports.UserStatus || (exports.UserStatus = {}));
var TrackType;
(function (TrackType) {
    TrackType["TRAILER"] = "trailer";
    TrackType["VIDEO"] = "video";
    TrackType["CHANNEL"] = "channel";
    TrackType["COMMERCIAL"] = "commercial";
    TrackType["CHANNEL_FORCE_LIVE"] = "channelForceLive";
    TrackType["RECORD"] = "record";
})(TrackType = exports.TrackType || (exports.TrackType = {}));
var TrackStatus;
(function (TrackStatus) {
    TrackStatus["ACTIVE"] = "active";
    TrackStatus["FAILED"] = "failed";
    TrackStatus["UPLOADING"] = "uploading";
    TrackStatus["QUEUE"] = "queue";
    TrackStatus["PROCESSING"] = "processing";
})(TrackStatus = exports.TrackStatus || (exports.TrackStatus = {}));
var ContentType;
(function (ContentType) {
    ContentType["CHANNEL"] = "channel";
    ContentType["VIDEO"] = "video";
    ContentType["SERIES"] = "series";
    ContentType["SHOW"] = "show";
    //AUDIO  = 'audio',
    ContentType["ITEM"] = "item";
    ContentType["NEWS"] = "news";
    ContentType["APP"] = "app";
    ContentType["EPISODE"] = "episode";
    ContentType["SEASON"] = "season";
})(ContentType = exports.ContentType || (exports.ContentType = {}));
var ContentStatusType;
(function (ContentStatusType) {
    ContentStatusType["AVAILABLE"] = "available";
    ContentStatusType["NOT_PURCHASED"] = "notPurchased";
    ContentStatusType["NO_CONTENT"] = "noContent";
    ContentStatusType["UNAVAILABLE"] = "unavailable";
})(ContentStatusType = exports.ContentStatusType || (exports.ContentStatusType = {}));
var ContentAspect;
(function (ContentAspect) {
    ContentAspect["SOURCE"] = "source";
    ContentAspect["FIT"] = "fit";
    ContentAspect["STRETCH"] = "stretch";
    ContentAspect["CROP"] = "crop";
})(ContentAspect = exports.ContentAspect || (exports.ContentAspect = {}));
var CategoryType;
(function (CategoryType) {
    CategoryType["CHANNEL"] = "channel";
    CategoryType["VIDEO"] = "video";
    CategoryType["SERIES"] = "series";
    CategoryType["SHOW"] = "show";
    //AUDIO  = 'audio',
    CategoryType["CATEGORY"] = "category";
    CategoryType["ITEM"] = "item";
    CategoryType["NEWS"] = "news";
    CategoryType["APP"] = "app";
    CategoryType["PLAN"] = "plan";
    CategoryType["CUSTOM"] = "custom";
})(CategoryType = exports.CategoryType || (exports.CategoryType = {}));
var TemplateType;
(function (TemplateType) {
    TemplateType["ITEM_PURCHASED"] = "itemPurchased";
    TemplateType["SUBSCRIPTION_CREATED"] = "subscriptionCreated";
    TemplateType["SUBSCRIPTION_EXPIRED"] = "subscriptionExpired";
    TemplateType["SUBSCRIPTION_RENEWED"] = "subscriptionRenewed";
    TemplateType["TOP_UP_BALANCE"] = "topUpBalance";
    TemplateType["CONTENT_PURCHASED"] = "contentPurchased";
    TemplateType["CONFIRMATION"] = "confirmation";
    TemplateType["RESET_PASSWORD"] = "resetPassword";
    TemplateType["PASSWORD_CHANGED"] = "passwordChanged";
    TemplateType["WELCOME"] = "welcome";
})(TemplateType = exports.TemplateType || (exports.TemplateType = {}));
var AnnouncementType;
(function (AnnouncementType) {
    AnnouncementType["IMAGE"] = "image";
    AnnouncementType["PLAN"] = "plan";
    AnnouncementType["CONTENT"] = "content";
    AnnouncementType["CATEGORY"] = "category";
})(AnnouncementType = exports.AnnouncementType || (exports.AnnouncementType = {}));
var CommercialType;
(function (CommercialType) {
    CommercialType["VIDEO"] = "video";
    CommercialType["IMAGE"] = "image";
    CommercialType["TEXT"] = "text";
})(CommercialType = exports.CommercialType || (exports.CommercialType = {}));
var ApplicationType;
(function (ApplicationType) {
    ApplicationType["MOBILE"] = "mobile";
    ApplicationType["WEB"] = "web";
    ApplicationType["TV"] = "tv";
    ApplicationType["DASHBOARD"] = "dashboard";
})(ApplicationType = exports.ApplicationType || (exports.ApplicationType = {}));
var ClientType;
(function (ClientType) {
    ClientType["NATIVE"] = "native";
    ClientType["BROWSER"] = "browser";
})(ClientType = exports.ClientType || (exports.ClientType = {}));
var UpdateType;
(function (UpdateType) {
    UpdateType["MOBILE"] = "mobile";
    UpdateType["WEB"] = "web";
    UpdateType["TV"] = "tv";
    UpdateType["SDK"] = "sdk";
    UpdateType["ANDROID"] = "android";
})(UpdateType = exports.UpdateType || (exports.UpdateType = {}));
var MenuOrientation;
(function (MenuOrientation) {
    MenuOrientation["VERTICAL"] = "vertical";
    MenuOrientation["HORIZONTAL"] = "horizontal";
})(MenuOrientation = exports.MenuOrientation || (exports.MenuOrientation = {}));
var MenuType;
(function (MenuType) {
    MenuType["CHANNELS"] = "channels";
    MenuType["VIDEOS"] = "videos";
    MenuType["SHOWS"] = "shows";
    MenuType["SERIES"] = "series";
    MenuType["SUBSCRIPTIONS"] = "subscriptions";
    MenuType["VOUCHERS"] = "vouchers";
    MenuType["NPVR"] = "npvr";
    MenuType["SHOP"] = "shop";
    MenuType["NEWS"] = "news";
    MenuType["SETTINGS"] = "settings";
    MenuType["SEARCH"] = "search";
    MenuType["BALANCE"] = "balance";
    MenuType["PARENTAL_CONTROL"] = "parentalControl";
    MenuType["SUPPORT"] = "support";
    MenuType["FAVORITES"] = "favorites";
    MenuType["EDIT_PROFILE"] = "editProfile";
    MenuType["CHANGE_PASSWORD"] = "changePassword";
    MenuType["REMOVE_ACCOUNT"] = "removeAccount";
    MenuType["APPS"] = "apps";
})(MenuType = exports.MenuType || (exports.MenuType = {}));
var OfferIntervalType;
(function (OfferIntervalType) {
    OfferIntervalType["DAY"] = "day";
    OfferIntervalType["MONTH"] = "month";
    OfferIntervalType["YEAR"] = "year";
})(OfferIntervalType = exports.OfferIntervalType || (exports.OfferIntervalType = {}));
var PanelType;
(function (PanelType) {
    PanelType["CONTENT"] = "content";
    PanelType["COMMERCIAL"] = "commercial";
    PanelType["PAYMENTS"] = "payments";
    PanelType["USERS"] = "users";
    PanelType["SUBSCRIPTIONS"] = "subscriptions";
    PanelType["ACTIVE_SUBSCRIBERS"] = "activeSubscribers";
    PanelType["ONLINE_USERS"] = "onlineUsers";
})(PanelType = exports.PanelType || (exports.PanelType = {}));
var CategoryMode;
(function (CategoryMode) {
    CategoryMode["REGULAR"] = "regular";
    CategoryMode["LATEST"] = "latest";
    CategoryMode["MOST_RATED"] = "mostRated";
    CategoryMode["CONTINUE_WATCHING"] = "continueWatching";
    CategoryMode["FAVORITES"] = "favorites";
    CategoryMode["PURCHASED"] = "purchased";
    CategoryMode["RECOMMENDED"] = "recommended";
})(CategoryMode = exports.CategoryMode || (exports.CategoryMode = {}));
var FormType;
(function (FormType) {
    FormType["LOGIN"] = "login";
    FormType["REGISTRATION"] = "registration";
    FormType["EDIT_PROFILE"] = "editProfile";
    FormType["RECOVER_PASSWORD"] = "recoverPassword";
    FormType["RESET_PASSWORD"] = "resetPassword";
    FormType["CHANGE_PASSWORD"] = "changePassword";
    FormType["ACTIVATE_VOUCHER"] = "activateVoucher";
    FormType["CONFIRM"] = "confirm";
    FormType["CHANGE_PARENTAL_PIN"] = "changeParentalPin";
    FormType["ENTER_PARENTAL_PIN"] = "enterParentalPin";
})(FormType = exports.FormType || (exports.FormType = {}));
var FormFieldType;
(function (FormFieldType) {
    FormFieldType["LOGIN"] = "login";
    FormFieldType["USERNAME"] = "username";
    FormFieldType["PASSWORD"] = "password";
    FormFieldType["OLD_PASSWORD"] = "oldPassword";
    FormFieldType["NEW_PASSWORD"] = "newPassword";
    FormFieldType["REPEAT_PASSWORD"] = "repeatPassword";
    FormFieldType["EMAIL"] = "email";
    FormFieldType["PHONE"] = "phone";
    FormFieldType["ADDRESS"] = "address";
    FormFieldType["FIRST_NAME"] = "firstName";
    FormFieldType["LAST_NAME"] = "lastName";
    FormFieldType["SOCIAL_LOGIN"] = "socialLogin";
    FormFieldType["ZIP_CODE"] = "zipCode";
    FormFieldType["CODE"] = "code";
    FormFieldType["EMAIL_VERIFICATION_TOKEN"] = "emailVerificationToken";
    FormFieldType["PHONE_VERIFICATION_TOKEN"] = "phoneVerificationToken";
    FormFieldType["OLD_PIN"] = "oldPin";
    FormFieldType["NEW_PIN"] = "newPin";
    FormFieldType["PIN"] = "pin";
    FormFieldType["AUTHORIZATION_PLUGIN"] = "authorizationPlugin";
    FormFieldType["TELEGRAM"] = "telegram";
    FormFieldType["CAPTCHA"] = "captcha";
    FormFieldType["CUSTOM"] = "custom";
})(FormFieldType = exports.FormFieldType || (exports.FormFieldType = {}));
var GeoIpRuleType;
(function (GeoIpRuleType) {
    GeoIpRuleType["COUNTRY"] = "country";
    GeoIpRuleType["CITY"] = "city";
    GeoIpRuleType["IP"] = "ip";
})(GeoIpRuleType = exports.GeoIpRuleType || (exports.GeoIpRuleType = {}));
var GeoIpMode;
(function (GeoIpMode) {
    GeoIpMode["BLOCK_EXCEPT"] = "blockExcept";
    GeoIpMode["ALLOW_EXCEPT"] = "allowExcept";
})(GeoIpMode = exports.GeoIpMode || (exports.GeoIpMode = {}));
var LogAction;
(function (LogAction) {
    LogAction["LOGIN"] = "login";
    LogAction["LOGOUT"] = "logout";
    LogAction["UPDATE"] = "update";
    LogAction["CREATE"] = "create";
    LogAction["DELETE"] = "delete";
})(LogAction = exports.LogAction || (exports.LogAction = {}));
var PluginActionType;
(function (PluginActionType) {
    PluginActionType["NOTIFY_CUSTOMER"] = "notifyCustomer";
    PluginActionType["SEND_MAIL"] = "sendMail";
    PluginActionType["PAY"] = "pay";
    PluginActionType["WEB_HOOK"] = "webHook";
    PluginActionType["PREPARE_URL"] = "prepareUrl";
    PluginActionType["GEO_LOCATION"] = "geoLocation";
    PluginActionType["UPLOAD"] = "upload";
    PluginActionType["PROPS"] = "props";
    PluginActionType["METADATA"] = "metadata";
    PluginActionType["SEARCH_METADATA"] = "searchMetadata";
    PluginActionType["AUTHORIZE_URL"] = "authorizeUrl";
    PluginActionType["SERVER_STATUS"] = "serverStatus";
    PluginActionType["PARSE_EPG"] = "parseEpg";
    PluginActionType["CONTENT_LIST"] = "contentGet";
    PluginActionType["CONTENT_ITEM"] = "contentItem";
    PluginActionType["CONTENT_STREAMS"] = "contentStreams";
    PluginActionType["CONTENT_SEARCH"] = "contentSearch";
    PluginActionType["ACTION"] = "action";
})(PluginActionType = exports.PluginActionType || (exports.PluginActionType = {}));
var LicenseFeatureType;
(function (LicenseFeatureType) {
    LicenseFeatureType["COMMERCIAL"] = "commercial";
    LicenseFeatureType["VOUCHERS"] = "vouchers";
    LicenseFeatureType["STATISTICS"] = "statistics";
    LicenseFeatureType["BILLING"] = "billing";
    LicenseFeatureType["GEOIP"] = "geoip";
    LicenseFeatureType["LOGS"] = "logs";
    LicenseFeatureType["PLUGINS"] = "plugins";
})(LicenseFeatureType = exports.LicenseFeatureType || (exports.LicenseFeatureType = {}));
var ActionType;
(function (ActionType) {
    ActionType["UPDATE"] = "update";
    ActionType["CREATE"] = "create";
    ActionType["DELETE"] = "delete";
    ActionType["LIST"] = "list";
    ActionType["GET"] = "get";
    ActionType["UPLOAD"] = "upload";
    ActionType["DROP_DOWN_LIST"] = "dropDownList";
})(ActionType = exports.ActionType || (exports.ActionType = {}));
var DbType;
(function (DbType) {
    DbType["DASHBOARD"] = "dashboard";
    DbType["PLAYER"] = "player";
    DbType["STATISTICS"] = "statistics";
    DbType["EPG"] = "epg";
    DbType["COMMERCIAL"] = "commercial";
    DbType["MESSENGER"] = "messenger";
    DbType["CONSOLE"] = "console";
    DbType["STORAGE"] = "storage";
    DbType["MIGRATIONS"] = "migrations";
    DbType["AUTHORIZATION"] = "authorization";
})(DbType = exports.DbType || (exports.DbType = {}));
var MicroserviceType;
(function (MicroserviceType) {
    MicroserviceType["DASHBOARD"] = "dashboard";
    MicroserviceType["PLAYER"] = "player";
    MicroserviceType["STATISTICS"] = "statistics";
    MicroserviceType["EPG"] = "epg";
    MicroserviceType["COMMERCIAL"] = "commercial";
    MicroserviceType["SEARCH"] = "search";
    MicroserviceType["CONSOLE"] = "console";
    MicroserviceType["MESSENGER"] = "messenger";
    MicroserviceType["RECOMMENDATIONS"] = "recommendations";
    MicroserviceType["AUTHORIZATION"] = "authorization";
    MicroserviceType["TELEGRAM"] = "telegram";
})(MicroserviceType = exports.MicroserviceType || (exports.MicroserviceType = {}));
var MicroserviceMode;
(function (MicroserviceMode) {
    MicroserviceMode["REGULAR"] = "regular";
    MicroserviceMode["CRON"] = "cron";
})(MicroserviceMode = exports.MicroserviceMode || (exports.MicroserviceMode = {}));
var ClusterMessageType;
(function (ClusterMessageType) {
    ClusterMessageType["HOOK"] = "hook";
    ClusterMessageType["GET_SHARED_USER_THREAD"] = "getSharedUserThread";
    ClusterMessageType["SET_SHARED_USER_THREAD"] = "setSharedUserThread";
    ClusterMessageType["RESPONSE_SHARED_USER_THREAD"] = "responseSharedUserThread";
    ClusterMessageType["REQUEST"] = "request";
    ClusterMessageType["RESPONSE"] = "response";
    ClusterMessageType["RESPONSE_CATCH"] = "responseCatch";
    ClusterMessageType["READY"] = "ready";
    ClusterMessageType["ADD_TRACKING_MODEL"] = "addTrackingModel";
})(ClusterMessageType = exports.ClusterMessageType || (exports.ClusterMessageType = {}));
var ResizeFit;
(function (ResizeFit) {
    ResizeFit["COVER"] = "cover";
    ResizeFit["CONTAIN"] = "contain";
    ResizeFit["FILL"] = "fill";
    ResizeFit["INSIDE"] = "inside";
    ResizeFit["OUTSIDE"] = "outside";
})(ResizeFit = exports.ResizeFit || (exports.ResizeFit = {}));
exports.ResponseCode = Object.assign(Object.assign({}, defines_1.FrameworkResponseCode), { LOGOUT_SUCCESSFULLY: {
        code: 2,
        message: 'success.logout-successfully',
    }, PASSWORD_RECOVER_SUCCESSFULLY: {
        code: 3,
        message: 'success.recover-password-successfully',
    }, PROFILE_EDITED_SUCCESSFULLY: {
        code: 4,
        message: 'success.profile-updated-successfully',
    }, PARENTAL_PIN_CHANGED_SUCCESSFULLY: {
        code: 5,
        message: 'success.pin-changed',
    }, PROFILE_ACTIVATED_SUCCESSFULLY: {
        code: 6,
        message: 'success.profile-activated-successfully',
    }, MESSAGE_HAS_BEEN_SENT: {
        code: 7,
        message: 'success.message-has-been-sent',
    }, VOUCHER_HAS_BEEN_ACTIVATED: {
        code: 8,
        message: 'success.voucher-has-been-activated',
    }, PASSWORD_CHANGED: {
        code: 9,
        message: 'success.password-changed',
    }, OPERATOR_NOT_FOUND: {
        code: 100,
        message: 'errors.operator-not-found',
    }, VALIDATION_ERROR: {
        code: 101,
        message: 'errors.validation-error',
    }, USER_NOT_FOUND: {
        code: 102,
        message: 'errors.user-not-found',
    }, INTERNAL_ERROR: {
        code: 104,
        message: 'errors.internal-error',
    }, USER_IS_BANNED: {
        code: 105,
        message: 'errors.user-banned',
    }, NOT_FOUND: {
        code: 106,
        message: 'errors.not-found',
    }, THROTTLING: {
        code: 107,
        message: 'Throttling',
    }, INVALID_TOKEN: {
        code: 108,
        message: 'errors.invalid-token',
    }, PHONE_VERIFICATION_REQUIRED: {
        code: 109,
        message: 'errors.phone-verification-required',
    }, EMAIL_VERIFICATION_REQUIRED: {
        code: 110,
        message: 'errors.email-verification-required',
    }, INCORRECT_ACTIVATION_KEY: {
        code: 111,
        message: 'Invalid activation key',
    }, PASSWORD_IS_TOO_WEAK: {
        code: 112,
        message: 'errors.password-weak',
    }, ALREADY_TAKEN: {
        code: 113,
        message: 'errors.already-taken',
    }, EMAIL_AND_PHONE_VERIFICATION_REQUIRED: {
        code: 114,
        message: 'errors.email-and-phone-verification-required',
    }, VOUCHER_NOT_FOUND: {
        code: 115,
        message: 'errors.voucher-not-found',
    }, VOUCHER_ALREADY_ACTIVATED: {
        code: 116,
        message: 'errors.voucher-already-activated',
    }, VOUCHER_NOT_AVAILABLE_ON_DEVICE: {
        code: 117,
        message: 'errors.voucher-not-available-on-device',
    }, WRONG_PASSWORD: {
        code: 118,
        message: 'errors.wrong-password',
    }, WRONG_PIN: {
        code: 119,
        message: 'errors.wrong-pin',
    }, NOT_ENOUGH_BALANCE: {
        code: 120,
        message: 'errors.no-sufficient-funds',
    }, TOO_MANY_CONNECTIONS: {
        code: 121,
        message: 'errors.too-many-connections',
    }, LOCATION_NOT_ALLOWED: {
        code: 122,
        message: 'errors.location-not-allowed',
    }, LICENSE_LIMIT_EXCEEDED: {
        code: 123,
        message: 'errors.license-limit-exceeded',
    }, NO_DELIVERY_GATEWAY: {
        code: 124,
        message: 'errors.no-delivery-gateway',
    }, NO_CONFIRMATION_FORM: {
        code: 125,
        message: 'errors.no-confirmation-form',
    }, NO_CONFIRMATION_TEMPLATE: {
        code: 126,
        message: 'errors.no-confirmation-template',
    }, INVALID_CONFIGURATION: {
        code: 127,
        message: 'errors.invalid-configuration',
    }, NOTIFICATION_PLUGIN_NOT_CONFIGURED: {
        code: 128,
        message: 'errors.notification-plugin-not-configured',
    }, LIMIT_EXCEEDED: {
        code: 130,
        message: 'errors.limit-exceeded',
    } });

import AbstractListResource from './abstract/abstract-list-resource';
import Season from './items/season';
import {ActionType, ContentType, Resource, Uid} from 'sdk/src/defines';
import Episode from './items/episode';
import {AttributesType, ResourceApiCalls, SortFunc} from '../utility/types';

class Seasons extends AbstractListResource<Season> {
    public resourceType: Resource = Resource.SEASONS;

    public resourceItemClass: typeof Season = Season;

    public sort: SortFunc = (a: Episode, b: Episode) => {
        if (a.props?.index < b.props?.index) {
            return -1;
        }

        if (a.props?.index > b.props?.index) {
            return 1;
        }

        return 0;
    };

    public allowedActions: ActionType[] = [
        ActionType.LIST,
        ActionType.GET,
        ActionType.UPDATE,
        ActionType.DELETE,
        ActionType.CREATE,
    ];

    public apiCalls: ResourceApiCalls = {
        list: (params: AttributesType) => this.api().resourceGet(Resource.CONTENTS, {type: ContentType.SEASON, ...params}),
        get: (id: Uid, params: AttributesType) =>  this.api().resourceRead(Resource.CONTENTS, id, params),
        create: (params: AttributesType) => this.api().resourceCreate(Resource.CONTENTS, {type: ContentType.SEASON, ...params}),
        update: (ids: Uid | Uid[], params: AttributesType) => this.api().resourceUpdate(Resource.CONTENTS, ids, params),
        delete: (ids: Uid | Uid[]) => this.api().resourceDelete(Resource.CONTENTS, ids),
    }

    public getConnectedResourceTypes(): Resource[] {
        return [Resource.CONTENTS];
    }
}

export {Seasons, Season};
export default new Seasons();

import AbstractResource from '../abstract/abstract-resource';
import {CategoryType, CategoryMode, Resource, OS, ContentType, Uid} from 'sdk/src/defines';
import defaultChannelImage from '@src/assets/images/content/channel/128.jpg';
import AbstractFileModelResource from '../abstract/abstract-file-model-resource';
import {
    CastRule,
    CastType,
    DetailsType,
    DropDownItem,
    LabelsType,
    LocalesType,
} from '../../utility/types';
import {TFunction} from 'react-i18next';

class Category extends AbstractFileModelResource {
    public resourceType: Resource = Resource.CATEGORIES;

    public labels: LabelsType = {
        id: 'ID',
        title: 'contents.title',
        categoryId: 'contents.categoryID',
    };
    
    public declare externalId: string;

    public declare type: CategoryType;

    public declare mode: CategoryMode;

    public declare title: string;

    public declare published: boolean;

    public declare string: string;

    public declare showAtMainScreen: boolean;

    public declare mainScreenOrder: number;

    public declare contentScreenOrder: number;

    public declare os: OS[];

    public declare categories: {referenceCategoryId: Uid}[];

    public declare plans: {planId: Uid}[];

    public declare locales: LocalesType;

    public castValues(): CastType {
        return {
            mainScreenOrder: CastRule.FLOAT,
            contentScreenOrder: CastRule.FLOAT,
        };
    }

    public getImage(): string {
        return super.getImage(defaultChannelImage);
    }

    public static getTypeByContentType(contentType: ContentType): CategoryType {
        switch (contentType) {
            case ContentType.CHANNEL:
                return CategoryType.CHANNEL;
            case ContentType.VIDEO:
                return CategoryType.VIDEO;
            case ContentType.SERIES:
                return CategoryType.SERIES;
            case ContentType.SHOW:
                return CategoryType.SHOW;
            case ContentType.ITEM:
                return CategoryType.ITEM;
            case ContentType.NEWS:
                return CategoryType.NEWS;
            case ContentType.APP:
                return CategoryType.APP;
            default:
                return;
        }
    }

    public getModeStr(t: TFunction): string {
        if (!Category.hasMode(this.type)) {
            return null;
        }

        const item: DropDownItem = Category.modesForDropDown(t)
            .find((item: DropDownItem) => item.value === this.mode);

        if (item) {
            return item.label;
        }

        return null;
    }

    public getTypeStr(t: TFunction): string {
        const item: DropDownItem = Category.typesForDropDown(t)
            .find((item: DropDownItem) => item.value === this.type);

        if (item) {
            return item.label;
        }

        return this.type;
    }

    public static typesForDropDown(t: TFunction): DropDownItem[] {
        return AbstractResource.defineForDropDown(CategoryType, 'contents', t);
    }

    public static modesForDropDown(t: TFunction, type?: CategoryType): DropDownItem[] {
        let modes: CategoryMode[] = [];

        if (type === CategoryType.CHANNEL) {
            modes = [
                CategoryMode.REGULAR,
                CategoryMode.LATEST,
                CategoryMode.FAVORITES,
            ];
        } else {
            modes = Object.values(CategoryMode);
        }

        return modes.map((mode: CategoryMode) => {
            return {
                label: t(`contents.${mode}`),
                value: mode,
            };
        });
    }

    public isContentCategory(): boolean {
        return this.type !== CategoryType.CATEGORY && this.type !== CategoryType.PLAN;
    }

    public static hasMode(type: CategoryType): boolean {
        return [CategoryType.CHANNEL, CategoryType.VIDEO, CategoryType.SERIES, CategoryType.SHOW].includes(type);
    }

    public getTitle(t: TFunction): string {
        return this.title || t('contents.unnamedCategory');
    }

    public getDetails(t: TFunction): DetailsType {
        return super.getDetails(t, {
            title: this.getTitle(t),
        });
    }
}

export default Category;

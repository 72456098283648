import AbstractListResource from './abstract/abstract-list-resource';
import {ActionType, Resource} from 'sdk/src/defines';
import Term from './items/term';

class Terms extends AbstractListResource<Term> {
    public resourceType: Resource = Resource.TERMS;

    public resourceItemClass: typeof Term = Term;

    public allowedActions: ActionType[] = [
        ActionType.LIST,
        ActionType.UPDATE,
        ActionType.CREATE,
        ActionType.DELETE,
    ];
}

export {Terms, Term};
export default new Terms();

export const REQUEST_CANCELLED = 'requestCancelled';
export const SERVER_ERROR = 'serverError';
export const USER_NOT_FOUND = 'userNotFound';
export const USER_DISABLED = 'userDisabled';
export const USER_NOT_VERIFIED = 'userNotVerified';
export const INVALID_TOKEN = 'invalidToken';
export const PASSWORD_IS_TOO_WEAK = 'passwordIsTooWeak';
export const EMAIL_ALREADY_IN_USE = 'emailAlreadyInUse';
export const INVALID_VALUE = 'invalidValue';
export const NOT_AUTHORIZED = 'notAuthorized';
export const LICENSE_LIMIT_EXCEEDED = 'licenseLimitExceeded';

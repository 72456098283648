import AbstractItemResource from '../abstract/abstract-item-resource';
import {OS, Resource, Uid, VoucherStatus, VoucherType} from 'sdk/src/defines';
import AbstractResource from '../abstract/abstract-resource';
import {CastRule, CastType, DropDownItem} from '../../utility/types';
import {TFunction} from 'react-i18next';

class Voucher extends AbstractItemResource {
    public resourceType: Resource = Resource.VOUCHERS;

    public declare createdAt: Date;

    public declare subscriberId: string;

    public declare planId: string;

    public declare offerId: string;

    public declare amount: number;

    public declare code: string;

    public declare type: VoucherType;

    public declare status: VoucherStatus;

    public declare os: OS[];

    public declare activatedAt: Date;

    public declare beginAt: Date;

    public declare endAt: Date;

    public declare subscriber: {
        id: Uid,
        firstName: string,
        email: string,
        lastName: string,
    }

    public castValues(): CastType {
        return {
            beginAt: CastRule.DATE,
            endAt: CastRule.DATE,
            activatedAt: CastRule.DATE,
            createdAt: CastRule.DATE,
            amount: CastRule.FLOAT,
        };
    }

    public hasAvailabilityLimits(): boolean {
        return !((!this.beginAt?.getTime() || this.beginAt.getTime() === this.createdAt.getTime()) && !this.endAt?.getTime());
    }

    public isAvailable(): boolean {
        if (!this.hasAvailabilityLimits()) {
            return true;
        }

        const now: number = Date.now();

        return (!this.beginAt || this.beginAt.getTime() <= 0 || now >= this.beginAt.getTime()) && (!this.endAt || this.endAt.getTime() <= 0 || now <= this.endAt.getTime());
    }

    public getTypeStr(t: TFunction): string {
        const item: DropDownItem = Voucher.typesForDropDown(t)
            .find((item: DropDownItem) => item.value === this.type);

        if (item) {
            return item.label;
        }

        return '';
    }

    public static typesForDropDown(t: TFunction): DropDownItem[] {
        return AbstractResource.defineForDropDown(VoucherType, 'vouchers', t);
    }
}

export default Voucher;

import AbstractItemResource from '../abstract/abstract-item-resource';
import {Resource, ContentType} from 'sdk/src/defines';
import Content from './content';
import {DropDownItem} from '../../utility/types';
import {TFunction} from 'react-i18next';

export type StatisticItem = {
    seconds: number,
    views: number,
    type?: ContentType,
    title?: string,
    date?: string,
}

class Statistic extends AbstractItemResource {
    public resourceType: Resource = Resource.STATISTICS;

    public declare data: StatisticItem[];

    public static contentTypes(): ContentType[] {
        return [ContentType.CHANNEL, ContentType.VIDEO, ContentType.SERIES, ContentType.SHOW];
    }

    public static contentTypesForDropDown(t: TFunction): DropDownItem[] {
        const allowedTypes: ContentType[] = Statistic.contentTypes();
        const result: DropDownItem[] = [{label: t('statistics.allTypes'), value: null}];

        return [
            ...result,
            ...Content.typesForDropDown(t)
                .filter((el: DropDownItem) => allowedTypes.includes(el.value as ContentType)),
        ];
    }

}

export default Statistic;

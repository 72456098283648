import AbstractItemResource from '../abstract/abstract-item-resource';
import {faLock, faLockOpen} from '@fortawesome/free-solid-svg-icons';
import {GeoIpMode, GeoIpRule, GeoIpRuleType, OS, Resource} from 'sdk/src/defines';
import AbstractResource from '../abstract/abstract-resource';
import {IconDefinition} from '@fortawesome/fontawesome-svg-core';
import {TFunction} from 'react-i18next';
import {DropDownItem} from '../../utility/types';

class Geoip extends AbstractItemResource {
    public resourceType: Resource = Resource.GEOIPS;

    public declare title: string;

    public declare mode: GeoIpMode;

    public declare useForAuthorization: boolean;

    public declare os: OS[];

    public declare rules: GeoIpRule[];

    public getModeIcon(): IconDefinition {
        switch (this.mode) {
            case GeoIpMode.ALLOW_EXCEPT:
                return faLockOpen;
            case GeoIpMode.BLOCK_EXCEPT:
                return faLock;
        }
    }

    public getTitle(t: TFunction): string {
        return this.title || t('geoips.unnamedRule');
    }

    public static ruleTypesForDropDown(t: TFunction): DropDownItem[] {
        return AbstractResource.defineForDropDown(GeoIpRuleType, 'geoips', t);
    }

    public static modesForDropDown(t: TFunction): DropDownItem[] {
        return [
            {
                label: t('geoips.allowEverybodyExceptEtc'),
                value: GeoIpMode.ALLOW_EXCEPT,
            },
            {
                label: t('geoips.blockEverybodyExceptEtc'),
                value: GeoIpMode.BLOCK_EXCEPT,
            },
        ];
    }
}

export default Geoip;

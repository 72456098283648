import AbstractListResource from './abstract/abstract-list-resource';
import {Resource, ActionType} from 'sdk/src/defines';
import Commercial from './items/commercial';

class Commercials extends AbstractListResource<Commercial> {
    public resourceType: Resource = Resource.COMMERCIALS;

    public resourceItemClass: typeof Commercial = Commercial;

    public allowedActions: ActionType[] = [
        ActionType.LIST,
        ActionType.CREATE,
        ActionType.UPDATE,
        ActionType.DELETE,
    ];
}

export {Commercials, Commercial};
export default new Commercials();

import AbstractListResource from './abstract/abstract-list-resource';
import {ActionType, Resource} from 'sdk/src/defines';
import Package from './items/package';

class Packages extends AbstractListResource<Package> {
    public resourceType: Resource = Resource.PACKAGES;

    public resourceItemClass: typeof Package = Package;

    public allowedActions: ActionType[] = [
        ActionType.LIST,
        ActionType.UPDATE,
        ActionType.DELETE,
        ActionType.CREATE,
    ];

    public getConnectedResourceTypes(): Resource[] {
        return [Resource.CONTENTS, Resource.PACKAGES];
    }

}

export {Packages, Package};
export default new Packages();

import AbstractItemResource from '../abstract/abstract-item-resource';
import {Resource, PluginType, Uid} from 'sdk/src/defines';
import AbstractResource from '../abstract/abstract-resource';
import {AttributesType, DispatchFunc, DispatchResponse, DropDownItem, LocalesType} from '../../utility/types';
import {TFunction} from 'react-i18next';
import {FormControl} from '../../components/form/FormSet';

class Plugin extends AbstractItemResource {
    public resourceType: Resource = Resource.PLUGINS;

    public declare props: AttributesType;

    public declare pluginSourceCode: {
        id: Uid,
        title: string,
        description: string,
        default: boolean,
        type: PluginType,
        version: string,
        pluginProps: FormControl[],
        instanceProps: AttributesType,
        locales: LocalesType,
        parameters: AttributesType,
    };

    public getType(): PluginType {
        return this.pluginSourceCode.type;
    }

    public getTypeStr(t: TFunction): string {
        const item: DropDownItem = Plugin.typesForDropDown(t)
            .find((item: DropDownItem) => item.value === this.pluginSourceCode.type);

        if (item) {
            return item.label;
        }

        return null;
    }

    public static typesForDropDown(t: TFunction): DropDownItem[] {
        return AbstractResource.defineForDropDown(PluginType, 'plugins', t);
    }

    public getConfig(): string {
        return `${Resource.PLUGINS.toLowerCase()}/${this.id}/config`;
    }

    public translate(value: string, language: string): string {
        const locales: {[key: string]: {[language: string]: string}} = this.pluginSourceCode.locales;

        if (locales) {
            if (locales[language] && locales[language][value]) {
                return locales[language][value];
            }

            if (locales.en && locales.en[value]) {
                return locales.en[value];
            }
        }

        return value;
    }

    public getTitle(t: TFunction, language: string = 'en'): string {
        if (!this.pluginSourceCode.title) {
            return t('plugins.unnamedPlugin');
        }

        return this.translate(this.pluginSourceCode.title, language);
    }

    public updateFile(file: File): DispatchResponse {
        return async (dispatch: DispatchFunc) => {
            return this.api().uploadPut(`${this.resourceType.toLowerCase()}/${this.id}`, {file}).then((data: any) => {
                dispatch(this.updated(data));
            }).catch(() => null)
        };
    }

    public getDescription(language: string, t: TFunction): string {
        if (!this.pluginSourceCode.description) {
            return t('plugins.noDescription');
        }

        return this.translate(this.pluginSourceCode.description, language);
    }
}

export default Plugin;

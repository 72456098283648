import defaultImage from '@src/assets/images/content/items/244x144.jpg';
import Offer from './offer';
import {OfferIntervalType, OS, Resource, Uid} from 'sdk/src/defines';
import {AttributesType, CastRule, CastType, DropDownItem, LocalesType} from '../../utility/types';
import AbstractFileModelResource from '../abstract/abstract-file-model-resource';
import {TFunction} from 'react-i18next';

class Plan extends AbstractFileModelResource {
    public resourceType: Resource = Resource.PLANS;

    public declare externalId: string;

    public declare packageId: string;

    public declare published: boolean;

    public declare autoAddWhenRegisterOfferId: string;

    public declare title: string;

    public declare order: number;

    public declare os: OS[];

    public declare description: string;

    public declare renewSubscriptions: boolean;

    public declare geoIpId: Uid;

    public declare parentPlanId: Uid;

    public declare offers: {
        id: Uid,
        intervalLength: number,
        intervalType: OfferIntervalType,
        price: number,
        order: number,
    }[];

    public declare categories: {categoryId: Uid}[];

    public declare users: {userId: Uid}[];

    public declare roles: {roleId: Uid}[];

    public declare groups: {groupId: Uid}[];

    public declare locales: LocalesType;

    public castValues(): CastType {
        return {
            amount: CastRule.FLOAT,
        };
    }

    public offersForDropDown(t: TFunction): DropDownItem[] {
        const result: DropDownItem[] = [];
        this.offers.forEach((data: AttributesType) => {
            const offer: Offer = new Offer(data, null);
            result.push({
                label: offer.getTitle(t),
                value: data.id,
            });
        });

        return result;
    }

    public getMode(t: TFunction): string {
        if (this.renewSubscriptions) {
            return t('billing.recurrentPayments');
        }

        return t('billing.oneTimePurchase');
    }

    public getTitle(t: TFunction): string {
        return this.title || t('billing.unnamedPlan');
    }

    public getImage(): string {
        return super.getImage(defaultImage)
    }

}

export default Plan;

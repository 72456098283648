import AbstractItemResource from '../abstract/abstract-item-resource';
import {Resource} from 'sdk/src/defines';
import User from './user';
import Role from './role';
import users from '../users';
import roles from '../roles';
import {TFunction} from 'react-i18next';
import {DetailsType, DropDownItem, LabelsType} from '../../utility/types';

class ApiKey extends AbstractItemResource {
    public resourceType: Resource = Resource.API_KEYS;

    public typesForDropDown(t: TFunction): DropDownItem[] {
        return [
            {label: t('apiKeys.userPermissions'), value: 'user'},
            {label: t('apiKeys.readOnly'), value: 'readonly'},
        ];
    }

    public labels: LabelsType = {
        id: 'ID',
        userId: 'apiKeys.user',
        roleId: 'apiKeys.permissionRole',
        blockIP: 'apiKeys.blockIPAddress',
        allowedIPs: 'apiKeys.allowedIPs',
    }

    public declare title: string;

    public declare blockIP: boolean;

    public declare allowedIPs: string[];

    public declare userId: string;

    public declare roleId: string;

    public getDetails(t: TFunction): DetailsType {
        return super.getDetails(t, {
            allowedIPs: this.allowedIPs.join(', '),
        });
    }

    public getRoleTitle(t: TFunction): string {
        const role: Role = roles.findById(this.roleId);

        if (role) {
            return role.getTitle(t);
        }

        return null;
    }

    public getUser(): User {
        return users.findById(this.userId);
    }

}

export default ApiKey;

import resourceCollection from './abstract/resource-collection';
import defaultAvatar from '@src/assets/images/avatars/default.png';
import {ActionType, PermissionsType, Resource, RoleType, Uid} from 'sdk/src/defines';
import AbstractFileModelResource from './abstract/abstract-file-model-resource';
import {DispatchFunc, DispatchResponse} from '../utility/types';
import users from './users';
import AbstractResource from './abstract/abstract-resource';

class Profile extends AbstractFileModelResource {
    public resourceType: Resource = Resource.PROFILE;

    public resourceItemClass: typeof Profile = Profile;

    public allowedActions: ActionType[] = [
        ActionType.GET,
        ActionType.UPDATE,
    ];

    public declare organizationId: string;

    public declare firstName: string;

    public declare lastName: string;

    public declare email: string;

    public declare superAdmin: boolean;

    public declare operatorId: Uid;

    public declare updatedAt: Date;

    public declare createdAt: Date;

    public declare balance: number;

    public declare role: {
        id: Uid,
        type: RoleType,
        permissions: PermissionsType,
    }

    public declare theme: {
        icon: string,
        textLogo: string,
        favicon: string,
    }

    public config: any;

    public getRoleType(): RoleType {
        return this.role ? this.role.type : null;
    }

    public getRoleId(): Uid {
        return this.role ? this.role.id : null;
    }

    public getFullName(): string {
        if (this.firstName && this.lastName) {
            return `${this.firstName} ${this.lastName}`;
        }

        return this.firstName;
    }

    public getAvatar(): string {
        return super.getImage(defaultAvatar, 'avatar');
    }

    public deleteAvatar(): DispatchResponse {
        return this.deleteFile('avatar');
    }

    public uploadAvatar(file: File): DispatchResponse {
        return (dispatch: DispatchFunc) => {
            dispatch(users.clear());
            this.uploadFile(file, 'avatar');
        };
    }

    public logout(): DispatchResponse {
        return (dispatch: DispatchFunc) => {
            this.api().userLogout();
            const resources: {[key: string]: AbstractResource} = resourceCollection.getResources();
            Object.keys(resources).forEach((resourceType: Resource) => {
                dispatch(resources[resourceType].clear());
            });
            dispatch({type: 'LOGOUT'});
        };
    }

    public propagateServices(): Promise<void> {
        return this.api().propagateServices();
    }

    public onUpdated(): DispatchResponse {
        return (dispatch: DispatchFunc) => {
            dispatch(super.onUpdated([this]));
            dispatch(users.clear());
        };
    }
}

export {Profile};

export default new Profile({}, null);

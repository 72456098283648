import {ActionType, Resource, Uid} from 'sdk/src/defines';
import ability from '../../configs/acl/ability';
import {
    AttributesType,
    CastRule,
    CastType,
    DetailsType,
    DispatchFunc,
    DispatchResponse,
    DropDownItem,
    ErrorType, PromiseCallbackFunc,
    ResourceApiCalls,
} from '../../utility/types';
import {TFunction} from 'react-i18next';
import api, {Api} from '../../api';

export enum PermissionType {
    CREATE = 'create',
    DELETE = 'delete',
    EDIT = 'delete',
    VIEW = 'view',
}

class AbstractResource {
    public resourceItemClass: typeof AbstractResource;

    public parent: any;

    public resourceType: Resource;

    public static resourceType: Resource;

    public allowedActions: ActionType[] = [];

    private loadingPromise: Promise<any> = null;

    private dataKeys: string[] = [];

    protected labels: {[key: string]: string} = {};

    public connectedResourceTypes: Resource[] = [];

    public apiCalls: ResourceApiCalls = {
        list: (params: AttributesType) => this.api().resourceGet(this.resourceType, params),
        get: (id: Uid, params: AttributesType) => this.api().resourceRead(this.resourceType, id, params),
        create: (params: AttributesType) => this.api().resourceCreate(this.resourceType, params),
        update: (ids: Uid | Uid[], params: AttributesType) => this.api().resourceUpdate(this.resourceType, ids, params),
        delete: (ids: Uid | Uid[]) => this.api().resourceDelete(this.resourceType, ids),
        upload: (params: AttributesType) => this.api().resourceUpload(this.resourceType, params),
        dropDownList: (params: AttributesType) => this.api().resourceDropDownList(this.resourceType, params),
    }

    constructor(data: AttributesType = null, parent: any = null) {
        this.parent = parent;
        this.setData(data);
    }

    public getResourceItemClass(): typeof AbstractResource {
        return this.resourceItemClass;
    }

    public static defineForDropDown(define: any, labelPrefix: string, t: TFunction): DropDownItem[] {
        const result: DropDownItem[] = [];
        Object.keys(define).forEach((key: string) => {
            result.push({
                value: define[key],
                label: t(`${labelPrefix}.${define[key]}`),
            });
        });

        return result;
    }

    public api(): Api {
        return api;
    }

    public resetError(): DispatchResponse {
        return (dispatch: DispatchFunc) => {
            dispatch({type: `${this.resourceType}_UNKNOWN`, error: null});
        };
    }

    public isSuperAdminResource(): boolean {
        return false;
    }

    public getParent(): AbstractResource {
        return null;
    }

    public getResourceType(): Resource {
        return this.resourceType;
    }

    public getConnectedResourceTypes(): Resource[] {
        return this.connectedResourceTypes;
    }

    public getPossibleActions(): PermissionType[] {
        const result: PermissionType[] = [];

        if (this.allowedActions.includes(ActionType.DROP_DOWN_LIST) ||
            this.allowedActions.includes(ActionType.GET) ||
            this.allowedActions.includes(ActionType.LIST)) {
            result.push(PermissionType.VIEW);
        }

        if (this.allowedActions.includes(ActionType.UPDATE)) {
            result.push(PermissionType.EDIT);
        }

        if (this.allowedActions.includes(ActionType.CREATE) || this.allowedActions.includes(ActionType.UPLOAD)) {
            result.push(PermissionType.CREATE);
        }

        if (this.allowedActions.includes(ActionType.DELETE)) {
            result.push(PermissionType.DELETE);
        }

        return result;
    }

    public getLabel(key: string, t: TFunction): string {
        if (this.labels[key] === undefined) {
            return null;
        }

        return t(this.labels[key]);
    }

    public getDetails(t: TFunction, overwrite: AttributesType = {}): DetailsType {
        const result: DetailsType = {};
        Object.keys(this.labels).forEach((key: string) => {
            if (this[key] !== undefined) {
                let value: any = this[key];

                if (overwrite[key] !== undefined) {
                    value = overwrite[key];
                }

                if (value === true) {
                    value = t('Yes');
                } else if (value === false) {
                    value = t('No');
                }

                result[key] = {
                    label: this.getLabel(key, t),
                    value,
                };
            }
        });

        return result;
    }

    public canView(): boolean {
        return ability.can('view', this.resourceType);
    }

    public canEdit(): boolean {
        return ability.can('edit', this.resourceType);
    }

    public canCreate(): boolean {
        return ability.can('create', this.resourceType);
    }

    public canDelete(): boolean {
        return ability.can('delete', this.resourceType);
    }

    public onCreated(item: AbstractResource): DispatchResponse {
        return (dispatch: DispatchFunc) => {
            if (item) {
                //dispatch(logs.clear());
            }
        };
    }

    public onUpdated(items: AbstractResource[]): DispatchResponse {
        return (dispatch: DispatchFunc) => {
            if (items && items.length) {
                //dispatch(logs.clear());
            }
        };
    }

    public onDeleted(items: AbstractResource[]): DispatchResponse {
        return (dispatch: DispatchFunc) => {
            if (items && items.length) {
                //dispatch(logs.clear());
            }
        };
    }

    public onLoaded(items: AbstractResource[]): DispatchResponse {
        return () => items;
    }

    public getData(): AttributesType {
        if (!this.isInitialized()) {
            return null;
        }

        const result: AttributesType = {};
        this.dataKeys.forEach((key: string) => {
            result[key] = this[key];
        });

        return result;
    }

    public setData(data: AttributesType): AttributesType {
        const previousValues: AttributesType = this.clearData();

        if (data) {
            Object.keys(data).forEach((key: string) => {
                if (!this.dataKeys.includes(key)) {
                    this.dataKeys.push(key);
                }

                this[key] = this.castValue(key, data[key]);
            });
        }

        return previousValues;
    }

    private castValue(key: string, value: any): any {
        const cast: CastType = this.castValues();

        if (null === value || undefined === value) {
            return value;
        } else if (key === 'createdAt' || key === 'updatedAt' || key === 'beginAt' || key === 'endAt') {
            return new Date(value);
        } else {
            switch (cast[key]) {
                case CastRule.FLOAT:
                    return parseFloat(value);
                case CastRule.JSON:
                    return {...value};
                case CastRule.DATE:
                    return new Date(value);
                case CastRule.INTEGER:
                    return parseInt(value);
                case CastRule.STRING:
                    return value.toString();
                default:
                    return value;
            }
        }
    }

    public castValues(): CastType {
        return {};
    }


    public clear(): DispatchResponse {
        return (dispatch: DispatchFunc) => {
            this.clearData();
            dispatch({type: `${this.resourceType}_CLEAR`});
        };
    }

    public clearData(): AttributesType {
        const previousValues: AttributesType = {};
        this.dataKeys.forEach((key: string) => {
            previousValues[key] = this[key];
            this[key] = undefined;
        });
        this.dataKeys = [];

        return previousValues;
    }

    public mergeData(data: AttributesType): AttributesType {
        const previousValues: AttributesType = {};
        Object.keys(data).forEach((key: string) => {

            if (!this.dataKeys.includes(key)) {
                this.dataKeys.push(key);
            }

            previousValues[key] = this[key];

            this[key] = this.castValue(key, data[key]);
        });

        return previousValues;
    }

    public isInitialized(): boolean {
        return this.dataKeys.length > 0;
    }

    public requestForRefresh(): DispatchResponse {
        return (dispatch: DispatchFunc) => {
            dispatch({type: `${this.resourceType}_REQUEST_FOR_REFRESH`});
        };
    }

    public update(params: AttributesType): DispatchResponse {
        return (dispatch: DispatchFunc) => {
            const previousData: AttributesType = this.mergeData(params);
            dispatch({type: `${this.resourceType}_UPDATING`});
            this.apiCalls.update(undefined, params).then((data: AttributesType) => {
                dispatch(this.updated(data));
            }).catch((err: ErrorType) => {
                this.mergeData(previousData);
                dispatch({type: `${this.resourceType}_UPDATE_FAILED`, error: err});
            });
        };
    }

    public updated(data: AttributesType, forceUpdate: boolean = false): DispatchResponse {
        return (dispatch: DispatchFunc) => {
            this.setData(data);
            dispatch(this.onUpdated([this]));

            if (forceUpdate) {
                dispatch({type: `${this.resourceType}_UPDATING`, id: this['id']});
            }

            dispatch({type: `${this.resourceType}_UPDATED`, id: this['id']});
        };
    }

    public refresh(params: AttributesType = {}): DispatchResponse {
        return this.load(params, true);
    }

    public load(params?: AttributesType, ignoreCache: boolean = false, onPromise?: PromiseCallbackFunc): DispatchResponse {
        return (dispatch: DispatchFunc) => {
            if (this.loadingPromise) {
                if (onPromise !== undefined) {
                    onPromise(this.loadingPromise);
                }

                return;
            }

            dispatch({type: `${this.resourceType}_RECEIVING`});

            if (this.isInitialized() && ignoreCache === false) {
                dispatch({type: `${this.resourceType}_RECEIVED`});
            } else {
                this.loadingPromise = this.apiCalls.list(params).then((data: AttributesType) => {
                    this.setData(data);
                    dispatch(this.onLoaded([this]));
                    dispatch({type: `${this.resourceType}_RECEIVED`});
                }).catch((err: ErrorType) => {
                    dispatch({type: `${this.resourceType}_RECEIVE_FAILED`, error: err});
                }).finally(() => {
                    this.loadingPromise = null;
                });

                if (onPromise !== undefined) {
                    onPromise(this.loadingPromise);
                }
            }

            return true;
        };
    }
}

export default AbstractResource;

import AbstractListResource from './abstract/abstract-list-resource';
import {ActionType, Resource} from 'sdk/src/defines';
import Announcement from './items/announcement';
import {sortAsc} from '../utility/utils';
import {SortFunc} from '../utility/types';

class Announcements extends AbstractListResource<Announcement> {
    public resourceType: Resource = Resource.ANNOUNCEMENTS;

    public resourceItemClass: typeof Announcement = Announcement;

    public sort: SortFunc = sortAsc('order');

    public allowedActions: ActionType[] = [
        ActionType.GET,
        ActionType.LIST,
        ActionType.CREATE,
        ActionType.UPDATE,
        ActionType.DELETE,
    ];

    public connectedResourceType: Resource[] = [Resource.CATEGORIES]
}

export {Announcements, Announcement};

export default new Announcements();

import AbstractListResource from './abstract/abstract-list-resource';
import {Resource, DiscountType, ActionType} from 'sdk/src/defines';
import Discount from './items/discount';
import {DropDownItem, SortFunc} from '../utility/types';
import {sortAsc} from '../utility/utils';
import {TFunction} from 'react-i18next';

class Discounts extends AbstractListResource<Discount> {
    public resourceType: Resource = Resource.DISCOUNTS;

    public resourceItemClass: typeof Discount = Discount;

    public sort: SortFunc = sortAsc('order');

    public allowedActions: ActionType[] = [
        ActionType.LIST,
        ActionType.GET,
        ActionType.UPDATE,
        ActionType.DELETE,
        ActionType.CREATE,
    ];

    public typesForDropDown(t: TFunction): DropDownItem[] {
        return [
            {label: t('discounts.percent'), value: DiscountType.PERCENT},
            {label: t('discounts.absolute'), value: DiscountType.ABSOLUTE},
        ];
    }

}

export {Discounts, Discount};
export default new Discounts();

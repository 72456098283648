import AbstractListResource from './abstract/abstract-list-resource';
import {Resource, ActionType} from 'sdk/src/defines';
import Payment from './items/payment';

class Payments extends AbstractListResource<Payment> {
    public resourceType: Resource = Resource.PAYMENTS;

    public resourceItemClass: typeof Payment = Payment;

    public allowedActions: ActionType[] = [
        ActionType.LIST,
        ActionType.CREATE,
    ];
}

export {Payments, Payment};
export default new Payments();

import AbstractListResource from './abstract/abstract-list-resource';
import {ActionType, Resource} from 'sdk/src/defines';
import Template from './items/template';

class Templates extends AbstractListResource<Template> {
    public resourceType: Resource = Resource.TEMPLATES;

    public resourceItemClass: typeof Template = Template;

    public allowedActions: ActionType[] = [
        ActionType.LIST,
        ActionType.UPDATE,
        ActionType.CREATE,
        ActionType.DELETE,
    ];
}

export {Templates, Template};
export default new Templates();

import AbstractListResource from './abstract/abstract-list-resource';
import {ActionType, Resource} from 'sdk/src/defines';
import Update from './items/update';
import {DropDownItem} from '../utility/types';
import {TFunction} from 'react-i18next';

class Updates extends AbstractListResource<Update> {
    public resourceType: Resource = Resource.UPDATES;

    public resourceItemClass: typeof Update = Update;

    public allowedActions: ActionType[] = [
        ActionType.LIST,
        ActionType.UPDATE,
        ActionType.CREATE,
        ActionType.DELETE,
    ];

    public typesForDropDown(t: TFunction): DropDownItem[] {
        return [
            {label: t('vouchers.balance'), value: 'balance'},
            {label: t('vouchers.subscription'), value: 'subscription'},
        ];
    }
}

export {Updates, Update};
export default new Updates();

import AbstractListResource from './abstract/abstract-list-resource';
import {ActionType, Resource} from 'sdk/src/defines';
import Plan from './items/plan';
import {SortFunc} from '../utility/types';
import {sortAsc} from '../utility/utils';

class Plans extends AbstractListResource<Plan> {
    public resourceType: Resource = Resource.PLANS;

    public resourceItemClass: typeof Plan = Plan;

    public sort: SortFunc = sortAsc('order');

    public allowedActions: ActionType[] = [
        ActionType.LIST,
        ActionType.GET,
        ActionType.UPDATE,
        ActionType.DROP_DOWN_LIST,
        ActionType.CREATE,
    ];

    public getConnectedResourceTypes(): Resource[] {
        return [Resource.SUBSCRIPTIONS];
    }

}

export {Plans, Plan};

export default new Plans();

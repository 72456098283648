import AbstractListResource from './abstract/abstract-list-resource';
import {Resource, ActionType} from 'sdk/src/defines';
import Epg from './items/epg';

class Epgs extends AbstractListResource<Epg> {
    public resourceType: Resource = Resource.EPGS;

    public resourceItemClass: typeof Epg = Epg;

    public allowedActions: ActionType[] = [
        ActionType.DROP_DOWN_LIST,
    ];

    public getConnectedResourceTypes(): Resource[] {
        return [Resource.CONTENT];
    }

}

export {Epgs, Epg};

export default new Epgs();

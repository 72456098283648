import AbstractListResource from './abstract/abstract-list-resource';
import {Resource, ActionType} from 'sdk/src/defines';
import GeoIp from './items/geoip';

class GeoIps extends AbstractListResource<GeoIp> {
    public resourceType: Resource = Resource.GEOIPS;

    public resourceItemClass: typeof GeoIp = GeoIp;

    public allowedActions: ActionType[] = [
        ActionType.LIST,
        ActionType.UPDATE,
        ActionType.DELETE,
        ActionType.CREATE,
    ];

}

export {GeoIps, GeoIp}
export default new GeoIps();

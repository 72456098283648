import {Resource} from 'sdk/src/defines';
import languages from 'language-list';
import AbstractFileModelResource from '../abstract/abstract-file-model-resource';
import {CastRule, CastType, DispatchResponse} from '../../utility/types';
const languageList: any = languages();

class Subtitle extends AbstractFileModelResource {
    public resourceType: Resource = Resource.SUBTITLES;

    public declare contentId: string;

    public declare trackId: string;

    public declare language: string;

    public declare title: string;

    public declare url: string;

    public declare locales: {[key: string]: {[language: string]: string}};

    public declare order: number;

    public castValues(): CastType {
        return {
            order: CastRule.FLOAT,
        };
    }

    public upload(file: File): DispatchResponse {
        return this.uploadFile(file);
    }

    public getLanguageStr(): string {
        const languageItem: {language: string} = languageList.getData().find((element: {code: string}) => element.code === this.language);

        if (languageItem) {
            return languageItem.language;
        }

        return null;
    }

    public getUrl(): string {
        return this.url || this.getFileUrl()
    }

}

export default Subtitle;

import AbstractListResource from './abstract/abstract-list-resource';
import {ActionType, Resource} from 'sdk/src/defines';
import Statistic from './items/statistic';

class Statistics extends AbstractListResource<Statistic> {

    public resourceType: Resource = Resource.STATISTICS;

    public resourceItemClass: typeof Statistic = Statistic;

    public allowedActions: ActionType[] = [
        ActionType.GET,
    ];

}

export {Statistics, Statistic};
export default new Statistics();

import defaultVideoImage from '@src/assets/images/content/video/100x75.jpg';
import {Resource, TrackStatus, TrackType, Uid, ContentAspect} from 'sdk/src/defines';
import AbstractFileModelResource from '../abstract/abstract-file-model-resource';
import {AttributesType, CastRule, CastType, LocalesType} from '../../utility/types';
import {TFunction} from 'react-i18next';
import pluginInstances from '../plugin-instances';
import PluginInstance from './plugin-instance'

class Track extends AbstractFileModelResource {
    public resourceType: Resource = Resource.TRACKS;

    public declare published: boolean;

    public declare beginAt: Date;

    public declare endAt: Date;

    public declare recordBeginAt: Date;

    public declare recordEndAt: Date;

    public declare contentId: string;

    public declare commercialId: string;

    public declare pluginInstanceId: string;

    public declare order: number;

    public declare uri: string;

    public declare aspect: ContentAspect;

    public declare geoIpId: Uid;

    public declare type: TrackType;

    public declare status: TrackStatus;

    public declare title: string;

    public declare locales: LocalesType;

    public declare props: AttributesType;

    public castValues(): CastType {
        return {
            order: CastRule.FLOAT,
            beginAt: CastRule.DATE,
            endAt: CastRule.DATE,
            recordBeginAt: CastRule.DATE,
            recordEndAt: CastRule.DATE,
        };
    }

    public getStatusColor(): string {
        if (!this.published || !this.isAvailable()) {
            return 'danger';
        }

        switch (this.status) {
            case TrackStatus.ACTIVE:
                return 'success';
            case TrackStatus.FAILED:
                return 'warning';
            default:
                return 'dark';
        }
    }

    public getStatus(t: TFunction): string {
        if (!this.published || !this.isAvailable()) {
            return t('contents.notPublished');
        }

        return t(`tracks.${this.status}`);
    }

    public getImage(): string {
        return super.getImage(defaultVideoImage);
    }

    public getPluginInstanceStr(t: TFunction): string {
        const pluginInstance: PluginInstance = pluginInstances.findById(this.pluginInstanceId);

        if (pluginInstance) {
            return pluginInstance.title;
        }

        return t('contents.externalUrl');
    }

}

export default Track;

import {TagType, Resource} from 'sdk/src/defines';
import AbstractResource from '../abstract/abstract-resource';
import defaultChannelImage from '@src/assets/images/content/channel/128.jpg';
import AbstractFileModelResource from '../abstract/abstract-file-model-resource';
import {TFunction} from 'react-i18next';
import {DropDownItem, LocalesType} from '../../utility/types';

class Tag extends AbstractFileModelResource {
    public resourceType: Resource = Resource.TAGS;

    public declare type: TagType;

    public declare name: string;

    public declare details: string;

    public declare externalId: string;

    public declare locales: LocalesType;

    public getImage(name: string = 'image'): string {
        return super.getImage(defaultChannelImage, name);
    }

    public getTypeStr(t: TFunction): string {
        const item: DropDownItem = Tag.typesForDropDown(t)
            .find((item: DropDownItem) => item.value === this.type);

        if (item) {
            return item.label;
        }

        return null;
    }

    public static typesForDropDown(t: TFunction, empty?: string): DropDownItem[] {
        const result: DropDownItem[] = [];

        if (empty) {
            result.push({
                label: empty,
                value: null,
            })
        }

        return [...result, ...AbstractResource.defineForDropDown(TagType, 'tags', t)];
    }
}

export default Tag;

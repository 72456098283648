import AbstractResource from '../abstract/abstract-resource';
import defaultVideoImage from '@src/assets/images/content/video/100x75.jpg';
import {Resource, AnnouncementType, ApplicationType, OS, Uid} from 'sdk/src/defines';
import {CastRule, CastType, DropDownItem, LocalesType} from '../../utility/types';
import AbstractFileModelResource from '../abstract/abstract-file-model-resource';
import {TFunction} from 'react-i18next';

class Announcement extends AbstractFileModelResource {
    public resourceType: Resource = Resource.ANNOUNCEMENTS;

    public declare type: AnnouncementType;

    public declare published: boolean;

    public declare application: ApplicationType;

    public declare url: string;

    public declare title: string;

    public declare description: string;

    public declare os: OS[];

    public declare order: number;

    public declare contentId: string;

    public declare categoryId: string;

    public declare planId: string;

    public declare files: {[name: string]: string};

    public declare updatedAt: Date;

    public declare groups: {groupId: Uid}[];

    public declare locales: LocalesType;

    public castValues(): CastType {
        return {
            order: CastRule.FLOAT,
            updatedAt: CastRule.DATE,
        };
    }

    public static typesForDropDown(t: TFunction): DropDownItem[] {
        return AbstractResource.defineForDropDown(AnnouncementType, 'homeScreen', t);
    }

    public getTitle(t: TFunction): string {
        return this.title || t('mainScreen.unnamedAnnouncement');
    }

    public getImage(): string {
        return super.getImage(defaultVideoImage);
    }

}

export default Announcement;

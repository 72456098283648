import {Dispatch} from 'redux';
import {Uid} from 'sdk/src/defines';
import {CancelTokenSource} from 'axios';
import {ReduxStatus} from '../resources/abstract/abstract-reducer';

export type DropDownItem = {
    label: string,
    value: boolean | number | string,
    params?: any,
}

export type AttributesType = {[key: string]: any};

export type DetailsType = {[key: string]: DropDownItem};

export type DispatchFunc = (Function) => Dispatch

export type DispatchResponse = (a: DispatchFunc) => void;

export type SortFunc = (a: any, b: any) => number;

export type LabelsType = {[key: string]: string};

export type PromiseCallbackFunc = (promise: Promise<any>) => void;

export type ListFunc = (params: AttributesType) => Promise<any>;
export type UploadFunc = (params: AttributesType) => Promise<any>;
export type GetFunc = (id: Uid, params: AttributesType) => Promise<any>;
export type CreateFunc = (params: AttributesType) => Promise<any>;
export type UpdateFunc = (id: Uid | Uid[], params: AttributesType) => Promise<any>;
export type DeleteFunc = (id: Uid | Uid[]) => Promise<any>;

export type ResourceApiCalls = {
    list?: ListFunc,
    get?: GetFunc,
    update?: UpdateFunc,
    create?: CreateFunc,
    delete?: DeleteFunc,
    upload?: UploadFunc,
    dropDownList?: ListFunc,
};

export enum CastRule {
    DATE = 'date',
    FLOAT = 'float',
    INTEGER = 'integer',
    JSON = 'json',
    STRING = 'string',
}

export type CastType = {[key: string]: CastRule};

export type ErrorType = {
    status: number,
    validation: {[fieldName: string]: string},
    error?: string,
}

export type LocalesType = {[key: string]: {[language: string]: string}};

export class ExtendedFile extends File {
    public cancelToken: CancelTokenSource;

    public id: number | string;

    public progress: number;

    public reduxStatus: ReduxStatus;

    public reduxId: number | string;
}

import AbstractItemResource from '../abstract/abstract-item-resource';
import {OfferIntervalType, Resource, Uid} from 'sdk/src/defines';
import {CastRule, CastType, DropDownItem} from '../../utility/types';
import {TFunction} from 'react-i18next';

class Offer extends AbstractItemResource {
    public resourceType: Resource = Resource.OFFERS;

    public declare planId: string;

    public declare intervalType: OfferIntervalType;

    public declare intervalLength: number;

    public declare price: number;

    public declare order: number;

    public declare published: boolean;

    public declare url: string;

    public declare discounts: {discountId: Uid}[];

    public declare npvrLimit: number;

    public declare maxConcurrentConnections: number;

    public castValues(): CastType {
        return {
            order: CastRule.FLOAT,
            price: CastRule.FLOAT,
        };
    }

    public getTitle(t: TFunction): string {
        return t('billing.offer-description', {
            interval: this.intervalLength,
            intervalType: this.getIntervalStr(t),
            price: this.price,
        });
    }

    public getIntervalStr(t: TFunction): string {
        const item: DropDownItem = Offer.intervalTypesForDropDown(t)
            .find((item: DropDownItem) => item.value === this.intervalType);

        if (item) {
            return item.label;
        }

        return null;
    }

    public static intervalTypesForDropDown(t: TFunction): DropDownItem[] {
        return [
            {
                label: t('billing.days'),
                value: OfferIntervalType.DAY,
            },
            {
                label: t('billing.months'),
                value: OfferIntervalType.MONTH,
            },
            {
                label: t('billing.years'),
                value: OfferIntervalType.YEAR,
            },
        ];
    }
}

export default Offer;

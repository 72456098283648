import AbstractListResource from './abstract/abstract-list-resource';
import {ActionType, Resource} from 'sdk/src/defines';
import Group from './items/group';

class Groups extends AbstractListResource<Group> {
    public resourceType: Resource = Resource.GROUPS;

    public resourceItemClass: typeof Group = Group;

    public allowedActions: ActionType[] = [
        ActionType.LIST,
        ActionType.UPDATE,
        ActionType.DELETE,
        ActionType.CREATE,
    ];

    public getConnectedResourceTypes(): Resource[] {
        return [Resource.SUBSCRIBERS, Resource.GROUPS];
    }


}

export {Groups, Group};

export default new Groups();

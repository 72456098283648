import AbstractItemResource from '../abstract/abstract-item-resource';
import Subscriber from './subscriber';
import User from './user';
import {PaymentMethod, PaymentStatus, PaymentType, Resource} from 'sdk/src/defines';
import AbstractResource from '../abstract/abstract-resource';
import {CastRule, CastType, DropDownItem} from '../../utility/types';
import {TFunction} from 'react-i18next';

class Payment extends AbstractItemResource {
    public resourceType: Resource = Resource.PAYMENTS;

    public declare subscriberId: string;

    public declare createdByUserId: string;

    public declare type: PaymentType;

    public declare paymentMethod: PaymentMethod;

    public declare status: PaymentStatus;

    public declare pluginInstanceId: string;

    public declare planId: string;

    public declare offerId: string;

    public declare contentId: string;

    public declare subscriptionId: string;

    public declare amount: number;

    public declare createdByUser: any;

    public declare subscriber: any;

    public castValues(): CastType {
        return {
            amount: CastRule.FLOAT,
        };
    }

    public getTypeStr(t: TFunction): string {
        const item: DropDownItem = Payment.typesForDropDown(t)
            .find((item: DropDownItem) => item.value === this.type);

        if (item) {
            return item.label;
        }

        return null;
    }

    public static typesForDropDown(t: TFunction, empty?: string): DropDownItem[] {
        const result: DropDownItem[] = [];

        if (empty) {
            result.push({
                label: empty,
                value: null,
            });
        }

        return [...result, ...AbstractResource.defineForDropDown(PaymentType, 'billing', t)];
    }

    public getCreatedByUser(): User {
        if (!this.createdByUser) {
            return null;
        }

        return new User(this.createdByUser, null);
    }

    public getSubscriber(): Subscriber {
        if (!this.subscriber) {
            return null;
        }

        return new Subscriber(this.subscriber, null);
    }
}

export default Payment;

import AbstractListResource from './abstract/abstract-list-resource';
import {ActionType, Resource} from 'sdk/src/defines';
import Subtitle from './items/subtitle';
import {SortFunc} from '../utility/types';
import {sortAsc} from '../utility/utils';

class Subtitles extends AbstractListResource<Subtitle> {
    public resourceType: Resource = Resource.SUBTITLES;

    public resourceItemClass: typeof Subtitle = Subtitle;

    public sort: SortFunc = sortAsc('order');

    public allowedActions: ActionType[] = [
        ActionType.LIST,
        ActionType.GET,
        ActionType.UPDATE,
        ActionType.CREATE,
        ActionType.DELETE,
        ActionType.UPLOAD,
    ];

    public getConnectedResourceTypes(): Resource[] {
        return [Resource.CONTENTS];
    }

}

export {Subtitles, Subtitle};
export default new Subtitles();

import * as status from '../resources/api/statuses';
import Swal, {SweetAlertOptions} from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {SortFunc} from './types';
import themeConfig from '../configs/themeConfig';
const MySwal = withReactContent(Swal);

export const setPageTitle = (title: string): void => {
    document.title = `${title} - ${themeConfig.app.appName}`;
}

export const selectInArray = (arr: any[], value: any, checked: boolean): any[] => {
    const items: any[] = [...arr];
    const index: number = items.indexOf(value);

    if (checked) {
        if (index < 0) {
            items.push(value);
        }
    } else {
        if (index >= 0) {
            items.splice(index, 1);
        }
    }

    return items;
}

export const sortAsc = (field: string): SortFunc => {
    return (a: any, b: any): number => {
        if (a[field] < b[field]) {
            return -1;
        }

        if (a[field] > b[field]) {
            return 1;
        }

        return 0;
    }
}

export const replaceAll = (str, find, replace) => {
    return str.replace(new RegExp(find, 'g'), replace);
};

export const isObjEmpty = obj => Object.keys(obj).length === 0;

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num);

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '');

// ** Checks if the passed date is today
const isToday = date => {
    const today = new Date();

    return (
        /* eslint-disable operator-linebreak */
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
        /* eslint-enable */
    );
};

export const hmsToSeconds = (hms: string): number => {
    const a: string[] = hms.split(':');

    if (a.length !== 3) {
        return 0;
    }

    return Math.round(parseInt(a[0].trim()) * 60 * 60) + (parseInt(a[1].trim()) * 60) + parseInt(a[2].trim());
};

export const secondsToHms = (d) => {
    d = Number(d);
    const h: number = Math.floor(d / 3600);
    const m: number = Math.floor(d % 3600 / 60);
    const s: number = Math.floor(d % 3600 % 60);
    const arr: string[] = [];
    arr.push(h < 10 ? `0${h}` : h.toString());
    arr.push(m < 10 ? `0${m}` : m.toString());
    arr.push(s < 10 ? `0${s}` : s.toString());

    return arr.join(':');
};

export const shortStr = (str, maxLength) => {
    if (str.length < maxLength) {
        return str;
    }

    return `${str.substr(0, maxLength)}...`;
};

export const timeToString = (time, withSeconds = false) => {
    const date = new Date(time);
    let result = '';

    if (date.getHours() < 10) {
        result += '0';
    }

    result += `${date.getHours()}:`;

    if (date.getMinutes() < 10) {
        result += '0';
    }

    result += date.getMinutes();

    if (withSeconds) {
        result += ':';

        if (date.getSeconds() < 10) {
            result += '0';
        }

        result += date.getSeconds();
    }

    return result;
};

export const translate = (phrase, locales, language) => {
    if (locales) {
        if (locales[language] && locales[language][phrase]) {
            return locales[language][phrase];
        }

        if (locales.en && locales.en[phrase]) {
            return locales.en[phrase];
        }
    }

    return phrase;
};

export const mergeFormControls = (formProps, defaultProps, params) => {
    if (!Array.isArray(formProps)) {
        return [];
    }

    const {locales, language, prefix, noClone} = params;

    const props = [...formProps];

    if (Array.isArray(props)) {
        props.forEach((item, index) => {
            if (Array.isArray(item)) {
                props[index] = mergeFormControls(item, defaultProps, params);
            } else {
                if (Array.isArray(item.form) && defaultProps) {
                    //props[index].form = mergeFormControls(item.form, defaultProps.form, params)
                }

                const prop = noClone ? props[index] : {...props[index]};

                if (Array.isArray(item.data)) {
                    item.data.forEach((itemData, index) => {
                        item.data[index].label = translate(itemData.label, locales, language);
                    });
                }

                prop.title = translate(item.title, locales, language);

                if (defaultProps && defaultProps[item.name] !== undefined) {
                    prop.defaultValue = defaultProps[item.name];// jp.query(pluginInstance.props, `$.${item.name}`)
                }

                prop.name = prefix ? `${prefix}${prop.name}` : prop.name;
                props[index] = prop;
            }
        });
    }

    return props;
};

export const confirmDelete = (t): Promise<void> => {
    const defaultConfirm: SweetAlertOptions = {
        icon: 'warning',
        title: t('areYouSure'),
        text: t('youWontBeAbleToRevertIt'),
        confirmButtonText: t('yesDelete'),
        cancelButtonText: t('Cancel'),
        showCancelButton: true,
        customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
    };

    const confirmObj = Object.assign({}, defaultConfirm, confirm);

    return new Promise((resolve, reject) => {
        MySwal.fire(confirmObj).then(res => {
            if (res.value) {
                resolve();
            } else {
                reject();
            }
        });
    });
};

export const humanDuration = (seconds) => {
    seconds = Math.round(parseFloat(seconds));

    const levels = [
        [Math.floor(seconds / 31536000), 'y'],
        [Math.floor((seconds % 31536000) / 86400), 'd'],
        [Math.floor(((seconds % 31536000) % 86400) / 3600), 'h'],
        [Math.floor((((seconds % 31536000) % 86400) % 3600) / 60), 'm'],
        [(((seconds % 31536000) % 86400) % 3600) % 60, 's'],
    ];

    let returnText = '';

    for (let i = 0, max = levels.length; i < max; i++) {
        if (levels[i][0] === 0) {
            continue;
        }

        returnText += ` ${levels[i][0]}${levels[i][1]}`;
    }

    return returnText.trim();
};

export const humanFileSize = (bytes, si = false, dp = 1) => {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return `${bytes} B`;
    }

    const units = si ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 * dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return `${bytes.toFixed(dp)} ${units[u]}`;
};

export const formatTime = (value, locale: string[] | string = 'en-US', formatting: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit', second: '2-digit' }) => {
    return new Intl.DateTimeFormat(locale, formatting).format(new Date(value));
};

export const hasIntersection = (list1, list2) => {
    if (!Array.isArray(list1) || !Array.isArray(list2) || !list1.length || !list2.length) {
        return true;
    }

    return list1.findIndex(el => list2.includes(el)) >= 0 || list2.findIndex(el => list1.includes(el)) >= 0;
};
/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, locale: string[] | string = 'en-US', formatting: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric', year: 'numeric' }) => {
    if (!value) {
        return value;
    }

    return new Intl.DateTimeFormat(locale, formatting).format(new Date(value));
};

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
    const date: Date = new Date(value);
    let formatting: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric' };

    if (toTimeForCurrentDay && isToday(date)) {
        formatting = { hour: 'numeric', minute: 'numeric' };
    }

    return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value));
};

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = (): boolean => !!localStorage.getItem('userData');
export const getUserData = () => JSON.parse(localStorage.getItem('userData'));

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
    if (userRole === 'admin') return '/';

    if (userRole === 'client') return '/access-control';

    return '/login';
};

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary25: '#7367f01a', // for option hover bg-color
        primary: '#7367f0', // for selected option bg-color
        neutral10: '#7367f0', // for tags bg-color
        neutral20: '#ededed', // for input border-color
        neutral30: '#ededed', // for input hover border-color
    },
});

export const catchApiResponse = (err, t, setError) => {
    if (err.status === status.SERVER_ERROR) {
        //window.location.href = '/500'
    } else if (err.status === status.NOT_AUTHORIZED) {
        window.location.href = '/login';
    } else if (setError && err.validation) {
        Object.keys(err.validation).forEach(fieldName => {
            let message = t(`errors.${err.validation[fieldName].rule || err.validation[fieldName]}`, err.validation[fieldName]);
            console.log(message, err.validation[fieldName]);

            if (message === `errors.${err.validation[fieldName].rule || err.validation[fieldName]}`) {
                message = t('errors.invalidValue');
            }

            setError(fieldName, {
                message,
            });
        });
    } else if (setError && err.errors) {
        Object.keys(err.errors).forEach(fieldName => {
            setError(fieldName, {
                message: err.errors[fieldName],
            });
        });
    }
};

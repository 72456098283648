import AbstractListResource from './abstract/abstract-list-resource';
import {Resource, ActionType} from 'sdk/src/defines';
import Device from './items/device';

class Devices extends AbstractListResource<Device> {
    public resourceType: Resource = Resource.DEVICES;

    public resourceItemClass: typeof Device = Device;

    public allowedActions: ActionType[] = [
        ActionType.LIST,
        ActionType.GET,
        ActionType.UPDATE,
        ActionType.DELETE,
        ActionType.CREATE,
    ];

    public getConnectedResourceTypes(): Resource[] {
        return [Resource.SUBSCRIBERS];
    }

}

export {Devices, Device};

export default new Devices();

import AbstractListResource from './abstract/abstract-list-resource';
import Subscription from './items/subscription';
import {Resource, ActionType}  from 'sdk/src/defines';

class Subscriptions extends AbstractListResource<Subscription> {
    public resourceType: Resource = Resource.SUBSCRIPTIONS;

    public resourceItemClass: typeof Subscription = Subscription;

    public allowedActions: ActionType[] = [
        ActionType.LIST,
        ActionType.UPDATE,
        ActionType.CREATE,
    ];

}

export {Subscriptions, Subscription};

export default new Subscriptions();

import axios, {AxiosError, AxiosResponse, CancelTokenSource} from 'axios';
import * as errorCode from '../resources/api/errors';
import * as status from '../resources/api/statuses';
import {decodeToken} from 'react-jwt';
import io, {Socket} from 'socket.io-client';
import {store} from '../redux/storeConfig/store';
import {v4 as uuidv4} from 'uuid';
import fileDownload from 'js-file-download';
import resourceCollection from '../resources/abstract/resource-collection';
import {ActionType, Resource, RoleType, Uid} from 'sdk/src/defines';
import ability, {prepareRules} from '../configs/acl/ability';
import ApiConfig from '../configs/apiConfig';
import AbstractListResource from '../resources/abstract/abstract-list-resource';
import {AttributesType, ErrorType} from '../utility/types';
import {Profile} from '../resources/profile';

enum RequestType {
    POST = 'post',
    GET = 'get',
    BLOB = 'blob',
    PUT = 'put',
    DELETE = 'delete',
    UPLOAD_POST = 'uploadPost',
    UPLOAD_PUT = 'uploadPut',
}

type TokensPair = {
    accessToken: string,
    refreshToken: string,
}

export const ROUTE: {[key: string]: string} = {
    SIGN_IN: '/auth/signin',
    SIGN_IN_LOCAL: '/auth/signin/local',
    SEND_PASSWORD_RESET_LINK: '/auth/send-password-reset-email',
    RESET_PASSWORD: '/auth/password-reset',
    REFRESH_TOKEN: '/auth/refresh-token',
    SEND_VERIFY_EMAIL: '/auth/send-verify-email',
    CHECK_RESET_PASSWORD_TOKEN: '/auth/check-password-reset-token',
    SIGNUP: '/auth/signup',
    VERIFY_EMAIL: '/auth/verify-email',
    PROFILE: '/profile',
    CHANGE_PASSWORD: '/profile/change-password',
    AVATAR: '/profile/avatar',
    LOGOUT: '/profile/logout',
    SCREENS: '/screens',
    GROUPS: '/groups',
    ROLES: '/roles',
    MEMBERS: '/members',
    USERS: '/users',
    CHECK_PAIRING_CODE: '/screens/check-pairing-code',
    SEND_INVITATION_LINK: '/users/send-invitation-link',
    SCREENS_REFRESH: '/screens/refresh',
    CONFIG_FRONT: '/config/front',
    PROPAGATE_SERVICES: '/operator/propagate-services',
    REMOVE_EPG: '/contents/remove-epg',
    PARSE_EPG: '/contents/parse-epg',
    LICENSE: '/operator/license',
    SUB_OPERATOR_LOGIN: '/operator/login',
    SUB_OPERATOR_LOGOUT: '/operator/logout',
    TRANSFER_BALANCE: '/users/transfer-balance',
    CANCEL_SUBSCRIPTIONS: '/subscriptions/cancel',
    CANCEL_SUBSCRIPTIONS_AT_PERIOD_ENDS: '/subscriptions/cancel-at-period-end',
};

class Api {
    private readonly sessionId: string;

    private socket: Socket;

    protected refreshTokenPromise: Promise<any>;

    protected config: typeof ApiConfig;

    constructor() {
        this.sessionId = uuidv4();
        this.config = ApiConfig;
        axios.defaults.baseURL = this.config.apiURL;
        axios.defaults.headers.common['Session-ID'] = this.sessionId;
        axios.defaults.headers.common['Content-Type'] = 'application/json';
        const accessToken: string = localStorage.getItem('accessToken');

        if (accessToken) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
            const decodedToken: any = decodeToken(accessToken);
            this.initSocket(decodedToken.user.operatorId);
        }
    }

    public getCancelToken(): CancelTokenSource {
        return axios.CancelToken.source();
    }

    public getHost(): string {
        return this.config.hostURL;
    }

    public getApiUrl(): string {
        return `${this.getHost()}/api/dashboard`;
    }

    private handleSocketEvent(event: ActionType, resourceType: Resource, data: AttributesType | AttributesType[]): void {

        const resource: AbstractListResource<any> = resourceCollection.getResource(resourceType) as AbstractListResource<any>;

        if (resource) {

            const dataList: AttributesType[] = Array.isArray(data) ? data : [data];

            dataList.forEach((dataItem: AttributesType) => {
                let equal: boolean = false;
                const updateResource: AbstractListResource<any> = resource;

                if (updateResource.isInitialized()) {
                    equal = true;
                }

                if (equal && updateResource.canView()) {
                    switch (event) {
                        case ActionType.DELETE: {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-expect-error
                            store.dispatch(updateResource.deleted(dataItem.id, true));
                            break;
                        }
                        case ActionType.CREATE: {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-expect-error
                            store.dispatch(updateResource.created(dataItem, true));
                            break;
                        }
                        case ActionType.UPDATE: {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-expect-error
                            store.dispatch(updateResource.updated(dataItem, true));
                            break;
                        }
                        default:
                            break;
                    }
                }
            });
        }
    }

    private initSocket(operatorId: Uid): void {
        if (this.getRoleType() !== RoleType.OPERATOR &&
            this.getRoleType() !== RoleType.ADMINISTRATOR) {
            return;
        }

        this.socket = io(this.config.socketURL, {
            query: { roomId: operatorId },
        });

        this.socket.on('delete', (msg: any) => {
            if (msg.sessionId !== this.sessionId) {
                this.handleSocketEvent(ActionType.DELETE, msg.resource, msg.data);
            }
        });

        this.socket.on('create', (msg: any) => {
            if (msg.sessionId !== this.sessionId) {
                this.handleSocketEvent(ActionType.CREATE, msg.resource, msg.data);
            }
        });

        this.socket.on('update', (msg: any) => {
            if (msg.sessionId !== this.sessionId) {
                this.handleSocketEvent(ActionType.UPDATE, msg.resource, msg.data);
            }
        });
    }

    public baseUrl(): string {
        return this.config.apiURL;
    }

    public userLogout(): Promise<void> {
        const refreshToken: string = localStorage.getItem('refreshToken');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('userData');
        localStorage.removeItem('accessTokenExpiresAt');

        if (this.socket) {
            this.socket.close();
        }

        if (axios.defaults.headers.common['Authorization']) {
            return this.post(ROUTE.LOGOUT, {
                refreshToken,
            }).finally(() => {
                delete axios.defaults.headers.common['Authorization'];
            });
        }

        Object.keys(resourceCollection.getResources()).forEach((resource: string) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            store.dispatch(resourceCollection.getResource(resource).clear());
        })

        return Promise.resolve();
    }

    protected receiveToken(accessToken: string, refreshToken: string): Profile {
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

        const decodedToken: any = decodeToken(accessToken);

        if (decodedToken) {
            const expiresAt: number = Math.round(Date.now() / 1000) + (decodedToken.exp - decodedToken.iat);
            localStorage.setItem('userData', JSON.stringify(decodedToken.user));
            localStorage.setItem('accessTokenExpiresAt', expiresAt.toString());

            return new Profile(decodedToken.user, null);
        }

        return null;
    }

    protected processErrors(error: AxiosError): Promise<any> {
        if (error.response && error.response.data) {
            return Promise.reject(error.response.data);
        } else if (axios.isCancel(error)) {
            return Promise.reject({
                status: status.REQUEST_CANCELLED,
                error: errorCode.REQUEST_CANCELLED,
                validation: {},
            });
        } else {
            return Promise.reject({
                status: status.SERVER_ERROR,
                error: errorCode.SERVER_ERROR,
                validation: {},
            });
        }
    }

    protected catchErrors(request: {type: RequestType, route: string, params: AttributesType}, error: AxiosError): Promise<any> {
        if (error.response && error.response.status === status.NOT_AUTHORIZED) {
            return this.refreshToken().then(({accessToken, refreshToken}: TokensPair) => {
                this.receiveToken(accessToken, refreshToken);

                return this.request(request.type, request.route, request.params, false).catch((err: AxiosError) => {
                    return this.processErrors(err);
                });
            }).catch((err: ErrorType) => {
                if (err.status !== status.SERVER_ERROR && this.isLoggedIn()) {
                    this.userLogout().then(() => {
                        location.href = '/';
                    });
                }

                return Promise.reject({
                    status: status.NOT_AUTHORIZED,
                    error: errorCode.NOT_AUTHORIZED,
                    validation: {},
                });
            });
        } else {
            return this.processErrors(error);
        }
    }

    protected request(type: RequestType, route: string, params: AttributesType, catchErrors: boolean = true): Promise<any> {

        switch (type) {
            case RequestType.GET: {
                return axios.get(route, {params}).then((res: AxiosResponse) => {
                    return Promise.resolve(res.data);
                }).catch((error: AxiosError) => {
                    if (catchErrors) {
                        return this.catchErrors({type, route, params}, error);
                    } else {
                        return Promise.reject(error);
                    }
                });
            }
            case RequestType.BLOB: {
                return axios(route, {
                    method: 'GET',
                    responseType: 'blob',
                }).then((res: AxiosResponse) => {
                    return Promise.resolve(res.data);
                }).catch((error: AxiosError) => {
                    if (catchErrors) {
                        return this.catchErrors({type, route, params}, error);
                    } else {
                        return Promise.reject(error);
                    }
                });
            }
            case RequestType.POST: {
                return axios.post(route, params).then((res: AxiosResponse) => {
                    return Promise.resolve(res.data);
                }).catch((error: AxiosError) => {
                    if (catchErrors) {
                        return this.catchErrors({type, route, params}, error);
                    } else {
                        return Promise.reject(error);
                    }
                });
            }
            case RequestType.PUT: {
                return axios.put(route, params).then((res: AxiosResponse) => {
                    return Promise.resolve(res.data);
                }).catch((error: AxiosError) => {
                    if (catchErrors) {
                        return this.catchErrors({type, route, params}, error);
                    } else {
                        return Promise.reject(error);
                    }
                });
            }
            case RequestType.DELETE: {
                return axios.delete(route, {params}).then((res: AxiosResponse) => {
                    return Promise.resolve(res.data);
                }).catch((error: AxiosError) => {
                    if (catchErrors) {
                        return this.catchErrors({type, route, params}, error);
                    } else {
                        return Promise.reject(error);
                    }
                });
            }
            case RequestType.UPLOAD_PUT:
            case RequestType.UPLOAD_POST: {

                const bodyFormData: FormData = new FormData();

                if (params.file) {
                    bodyFormData.append('file', params.file);
                    bodyFormData.append('filename', params.file.name);
                }

                if (params.files) {
                    Object.keys(params.files).forEach((key: string) => {
                        if (params.files[key]) {
                            bodyFormData.append(key, params.files[key]);
                        }
                    });
                }

                if (params.postParams) {
                    Object.keys(params.postParams).forEach((key: string) => {
                        if (typeof params.postParams[key] === 'object') {
                            bodyFormData.append(key, JSON.stringify(params.postParams[key]));
                        } else {
                            bodyFormData.append(key, params.postParams[key]);
                        }
                    });
                }

                const func: any = type === RequestType.UPLOAD_PUT ? axios.put : axios.post;

                return func(route, bodyFormData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                    onUploadProgress: params.onProgress,
                    cancelToken: params.cancelToken,
                }).then((res: AxiosResponse) => {
                    return Promise.resolve(res.data);
                }).catch((error: AxiosError) => {
                    if (catchErrors) {
                        return this.catchErrors({type, route, params}, error);
                    } else {
                        return Promise.reject(error);
                    }
                });
            }
        }
    }

    public resourceRead(resourceType: Resource, id: Uid, params?: AttributesType): Promise<any> {
        return this.get(`${resourceType.toLowerCase()}/${id}`, params);
    }

    public resourceGet(resourceType: Resource, params: AttributesType): Promise<any> {
        return this.get(resourceType.toLowerCase(), params);
    }

    public resourceDropDownList(resourceType: Resource, params: AttributesType): Promise<any> {
        return this.get(`${resourceType.toLowerCase()}/drop-down-list`, params);
    }

    public resourceCreate(resourceType: Resource, params?: AttributesType, upload: boolean = false): Promise<any> {
        if (upload) {
            return this.uploadPost(resourceType.toLowerCase(), params);
        } else {
            return this.post(resourceType.toLowerCase(), params);
        }
    }

    public resourceUpload(resourceType: Resource, params: AttributesType): Promise<any> {
        return this.upload(`${resourceType.toLowerCase()}/upload`, params);
    }

    public resourceExport(resourceType: Resource): Promise<any> {
        return this.blob(`${resourceType.toLowerCase()}/export/xlsx`, {responseType: 'blob'}).then((result: any) => {
            fileDownload(result, 'export.xlsx');

            return Promise.resolve(result);
        });
    }

    public resourceDelete(resourceType: Resource, id: Uid | Uid[]): Promise<any> {

        let route: string = `${resourceType.toLowerCase()}/${id}`;
        let params: AttributesType = {};

        if (Array.isArray(id)) {
            route = resourceType.toLowerCase();
            params = {id};
        }

        return this.delete(route, params);
    }

    public resourceUpdate(resourceType: Resource, id: Uid | Uid[], params: AttributesType, upload: boolean = false): Promise<any> {

        let route: string = undefined === id ? `${resourceType.toLowerCase()}` : `${resourceType.toLowerCase()}/${id}`;

        if (Array.isArray(id)) {
            route = `${resourceType.toLowerCase()}`;
            params = {id, params};
        }

        if (upload) {
            return this.uploadPut(route, params);
        } else {
            return this.put(route, params);
        }
    }

    public get(route: string, params?: AttributesType): Promise<any> {
        return this.request(RequestType.GET, route, params);
    }

    public blob(route: string, params?: AttributesType): Promise<any> {
        return this.request(RequestType.BLOB, route, params);
    }

    public post(route: string, params?: AttributesType): Promise<any> {
        return this.request(RequestType.POST, route, params);
    }

    public put(route: string, params?: AttributesType): Promise<any> {
        return this.request(RequestType.PUT, route, params);
    }

    public delete(route: string, params?: AttributesType): Promise<any> {
        return this.request(RequestType.DELETE, route, params);
    }

    public propagateServices(): Promise<any> {
        return this.post(ROUTE.PROPAGATE_SERVICES);
    }

    public removeEpg(channelId: Uid): Promise<any> {
        if (channelId) {
            return this.post(`${ROUTE.REMOVE_EPG}/${channelId}`);
        } else {
            return this.post(ROUTE.REMOVE_EPG);
        }
    }

    public parseEpg(channelId: Uid): Promise<any> {
        if (channelId) {
            return this.post(`${ROUTE.PARSE_EPG}/${channelId}`);
        } else {
            return this.post(ROUTE.PARSE_EPG);
        }
    }

    public getLicense(): Promise<any> {
        return this.get(ROUTE.LICENSE);
    }

    public upload(route: string, params: AttributesType): Promise<any> {
        return this.request(RequestType.UPLOAD_POST, route, params);
    }

    public uploadPut(route: string, params: AttributesType): Promise<any> {
        return this.request(RequestType.UPLOAD_PUT, route, params);
    }

    public uploadPost(route: string, params: AttributesType): Promise<any> {
        return this.upload(route, params);
    }

    public refreshToken(): Promise<any> {
        if (this.refreshTokenPromise !== undefined) {
            return this.refreshTokenPromise;
        }

        this.refreshTokenPromise = new Promise((resolve: any, reject: any) => {
            const refreshToken: string = localStorage.getItem('refreshToken');

            if (!refreshToken) {
                reject({
                    status: status.NOT_AUTHORIZED,
                    error: errorCode.NOT_AUTHORIZED,
                    validation: {},
                });
                this.refreshTokenPromise = undefined;
            } else {
                this.post(ROUTE.REFRESH_TOKEN, { refreshToken }).then((res: any) => {
                    resolve(res);
                }).catch((err: ErrorType) => {
                    reject(err);
                }).finally(() => {
                    this.refreshTokenPromise = undefined;
                });
            }
        });

        return this.refreshTokenPromise;
    }

    public currentUser(): Promise<any> {
        return this.get(ROUTE.PROFILE);
    }

    public updateProfile(params: AttributesType): Promise<any> {
        return this.put(ROUTE.PROFILE, params);
    }

    public isLoggedIn(): boolean {
        return !!localStorage.getItem('userData');
    }

    public getRoleType(): RoleType {
        const userData: string = localStorage.getItem('userData');

        try {
            const json: any = JSON.parse(userData);

            if (!json || !json.role) {
                return null;
            }

            return json.role.type;
        } catch (e) {
            return null;
        }
    }

    public isSubOperatorLoggedIn(): boolean {
        const userData: string = localStorage.getItem('userData');

        try {
            const json: any = JSON.parse(userData);

            if (!json || !json.nativeOperatorId || json.nativeOperatorId === json.operatorId) {
                return false;
            }

            return !!json.operatorId;
        } catch (e) {
            return false;
        }
    }

    public userLogin(credentials: AttributesType): Promise<Profile> {
        if (credentials.social) {
            window.location.href = `${this.config.apiURL + ROUTE.SIGN_IN}/${credentials.social}?app=${this.config.redirectUrl}`;
        } else {
            return this.post(ROUTE.SIGN_IN_LOCAL, credentials).then(({accessToken, refreshToken}: TokensPair) => {
                const userData: Profile = this.receiveToken(accessToken, refreshToken);
                this.initSocket(userData.operatorId);
                ability.update(prepareRules(userData));

                return Promise.resolve(userData);
            })
        }
    }

    public subOperatorLogin(operatorId: Uid): Promise<any> {
        return this.post(`${ROUTE.SUB_OPERATOR_LOGIN}/${operatorId}`)
            .then(({accessToken, refreshToken}: TokensPair) => {
                const userData: Profile = this.receiveToken(accessToken, refreshToken);

                this.initSocket(userData.operatorId);

                return Promise.resolve(userData);
            });
    }

    public subOperatorLogout(): Promise<void> {
        return this.post(ROUTE.SUB_OPERATOR_LOGOUT).then(({accessToken, refreshToken}: TokensPair) => {
            const userData: Profile = this.receiveToken(accessToken, refreshToken);
            this.initSocket(userData.operatorId);
        });
    }

    public transferBalance(userId: Uid, amount: number): Promise<any> {
        return this.post(ROUTE.TRANSFER_BALANCE, {userId, amount});
    }

    public singInWithToken(token: string): Promise<any> {
        localStorage.setItem('refreshToken', token);

        return this.refreshToken().then(({accessToken, refreshToken}: TokensPair) => {
            this.receiveToken(accessToken, refreshToken);
            window.location.href = '/';

            return Promise.resolve();
        });
    }

    public sendPasswordResetEmail(email: string): Promise<any> {
        return this.post(ROUTE.SEND_PASSWORD_RESET_LINK, {email});
    }

    public resetPassword(token: string, password: string): Promise<any> {
        return this.put(ROUTE.RESET_PASSWORD, {token, password});
    }

    public changePassword(currentPassword: string, newPassword: string): Promise<any> {
        return this.put(ROUTE.CHANGE_PASSWORD, {currentPassword, newPassword});
    }

    public checkResetPasswordToken(token: string): Promise<any> {
        return this.post(ROUTE.CHECK_RESET_PASSWORD_TOKEN, {token});
    }

    public registerUser(credentials: AttributesType): Promise<any> {
        return this.post(ROUTE.SIGNUP, credentials);
    }

    public verifyEmail(token: string): Promise<any> {
        return this.put(ROUTE.VERIFY_EMAIL, {token});
    }

    public sendVerifyEmail(email: string): Promise<any> {
        return this.post(ROUTE.SEND_VERIFY_EMAIL, {email});
    }

    public cancelSubscriptions(planId: Uid, offerId?: Uid, subscriberId?: Uid, subscriptionId?: Uid): Promise<any> {
        return this.post(ROUTE.CANCEL_SUBSCRIPTIONS, {planId, offerId, subscriberId, subscriptionId});
    }

    public cancelSubscriptionsAtPeriodEnds(planId: Uid, offerId: Uid, subscriberId?: Uid, subscriptionId?: Uid): Promise<any> {
        return this.post(ROUTE.CANCEL_SUBSCRIPTIONS_AT_PERIOD_ENDS, {planId, offerId, subscriberId, subscriptionId});
    }
}

export {Api};
export default new Api();

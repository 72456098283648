import {
    faBars,
    faCogs, faDollarSign, faEye, faFilm, faGrip, faHandsHelping, faHeart,
    faHouseUser, faIdCard, faKey, faList, faNewspaper, faRecordVinyl,
    faSearch, faTv, faUserEdit, faUserMinus, faVideo, faWallet,
} from '@fortawesome/free-solid-svg-icons';
import AbstractItemResource from '../abstract/abstract-item-resource';
import {MenuType, Resource, FormType, PluginType, MenuOrientation, ApplicationType, OS} from 'sdk/src/defines';
import AbstractResource from '../abstract/abstract-resource';
import {hasIntersection} from '../../utility/utils';
import {CastRule, CastType, DropDownItem} from '../../utility/types';
import {TFunction} from 'react-i18next';
import forms from '../forms';
import operator from '../operator';
import pluginInstances from '../plugin-instances';
import Form from './form';
import PluginInstance from './plugin-instance';
import {IconDefinition} from '@fortawesome/fontawesome-svg-core';

class Menu extends AbstractItemResource {
    public resourceType: Resource = Resource.MENUS;

    public declare order: number;

    public declare type: MenuType;

    public declare orientation: MenuOrientation;

    public declare application: ApplicationType;

    public declare os: OS[];

    public castValues(): CastType {
        return {
            order: CastRule.FLOAT,
        };
    }

    public getTitle(t: TFunction): string {
        return this.getTypeStr(t);
    }

    public getTypeStr(t: TFunction): string {
        const item: DropDownItem = Menu.typesForDropDown(t)
            .find((item: DropDownItem) => item.value === this.type);

        if (item) {
            return item.label;
        }

        return t('menus.unspecifiedType');
    }

    public hasNoForm(): boolean {
        const types: {[key: string]: FormType[]} = {
            [MenuType.VOUCHERS]: [FormType.ACTIVATE_VOUCHER],
            [MenuType.PARENTAL_CONTROL]: [FormType.CHANGE_PARENTAL_PIN, FormType.ENTER_PARENTAL_PIN],
            [MenuType.EDIT_PROFILE]: [FormType.EDIT_PROFILE],
            [MenuType.CHANGE_PASSWORD]: [FormType.CHANGE_PASSWORD],
        };

        if (types[this.type] === undefined) {
            return false;
        }

        const hasFormType: FormType[] = [];
        forms.getItems().forEach((form: Form) => {
            if (hasIntersection(form.os, this.os) && types[this.type].includes(form.type)) {
                if (!hasFormType.includes(form.type)) {
                    hasFormType.push(form.type);
                }
            }
        });

        return hasFormType.length < types[this.type].length;
    }

    public hasSupportEmail(): boolean {
        return !!operator.playerSettings?.supportEmail;
    }

    public hasSMTP(): boolean {
        const items: PluginInstance[] = pluginInstances.getPluginInstancesByType(PluginType.NOTIFICATION);

        return items.length > 0;
    }

    public getIcon(): IconDefinition {
        switch (this.type) {
            case MenuType.CHANNELS:
                return faTv;
            case MenuType.VIDEOS:
                return faFilm;
            case MenuType.SHOWS:
                return faEye;
            case MenuType.SERIES:
                return faVideo;
            case MenuType.SUBSCRIPTIONS:
                return faWallet;
            case MenuType.VOUCHERS:
                return faIdCard;
            case MenuType.NPVR:
                return faRecordVinyl;
            case MenuType.SHOP:
                return faDollarSign;
            case MenuType.NEWS:
                return faNewspaper;
            case MenuType.SETTINGS:
                return faCogs;
            case MenuType.SEARCH:
                return faSearch;
            case MenuType.FAVORITES:
                return faHeart;
            case MenuType.SUPPORT:
                return faHandsHelping;
            case MenuType.PARENTAL_CONTROL:
                return faHouseUser;
            case MenuType.BALANCE:
                return faDollarSign;
            case MenuType.EDIT_PROFILE:
                return faUserEdit;
            case MenuType.CHANGE_PASSWORD:
                return faKey;
            case MenuType.REMOVE_ACCOUNT:
                return faUserMinus;
            case MenuType.APPS:
                return faGrip;
            default:
                return faList;
        }
    }

    public static typesForDropDown(t: TFunction): DropDownItem[] {
        return AbstractResource.defineForDropDown(MenuType, 'menus', t);
    }
}

export default Menu;

import {ActionType, Resource} from 'sdk/src/defines';
import AbstractFileModelResource from './abstract/abstract-file-model-resource';

class Theme extends AbstractFileModelResource {
    public resourceType: Resource = Resource.THEME;

    public resourceItemClass: typeof Theme = Theme;

    public allowedActions: ActionType[] = [
        ActionType.GET,
    ];

    public declare icon: string;

    public declare textLogo: string;

    public declare favicon: string;

}

export {Theme};

export default new Theme({}, null);

import AbstractItemResource from '../abstract/abstract-item-resource';
import {Resource} from 'sdk/src/defines';
import {TFunction} from 'react-i18next';
import {DetailsType} from '../../utility/types';

class Group extends AbstractItemResource {
    public resourceType: Resource = Resource.GROUPS;

    public declare title: string;

    public declare addSubscriberWhenRegister: boolean;

    public getTitle(t: TFunction): string {
        return this.title || t('subscribers.unnamedGroup');
    }

    public getDetails(t: TFunction): DetailsType {
        return super.getDetails(t, {
            title: this.getTitle(t),
        });
    }

}

export default Group;

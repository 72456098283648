import AbstractListResource from './abstract/abstract-list-resource';
import {ActionType, Resource, RoleType} from 'sdk/src/defines';
import User from './items/user';
import {DropDownItem} from '../utility/types';

class Users extends AbstractListResource<User> {
    public resourceType: Resource = Resource.USERS;

    public resourceItemClass: typeof User = User;

    public allowedActions: ActionType[] = [
        ActionType.LIST,
        ActionType.GET,
        ActionType.UPDATE,
        ActionType.CREATE,
        ActionType.DELETE,
    ];

    public getConnectedResourceTypes(): Resource[] {
        return [Resource.ROLES];
    }

    public dealersForDropDown(empty?: string): DropDownItem[] {

        const result: DropDownItem[] = [];

        if (!this.isInitialized()) {
            return [];
        }

        if (empty) {
            result.push({
                value: null,
                label: empty,
            });
        }

        this.getItems().forEach((item: User) => {
            if (item.role && item.role.type === RoleType.DEALER) {
                result.push({
                    value: item.id,
                    label: item.getFullName(),
                });
            }
        });

        return result;
    }
}

export {Users, User};
export default new Users();

import AbstractListResource from './abstract/abstract-list-resource';
import {Resource, ActionType} from 'sdk/src/defines';
import Offer from './items/offer';
import {DispatchFunc, DispatchResponse, SortFunc} from '../utility/types';
import {sortAsc} from '../utility/utils';
import plans from './plans';

class Offers extends AbstractListResource<Offer> {
    public resourceType: Resource = Resource.OFFERS;

    public resourceItemClass: typeof Offer = Offer;

    public allowedActions: ActionType[] = [
        ActionType.LIST,
        ActionType.UPDATE,
        ActionType.GET,
        ActionType.CREATE,
    ];

    public sort: SortFunc = sortAsc('order');

    public onCreated(item: Offer): DispatchResponse {
        return (dispatch: DispatchFunc) => {
            if (plans) {
                dispatch(plans.refresh());
            }

            return super.onCreated(item);
        };
    }

    public getConnectedResourceTypes(): Resource[] {
        return [Resource.PLANS];
    }

}

export {Offers, Offer};
export default new Offers();

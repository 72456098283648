import {Resource, PlayerSettings, OperatorSettings, ActionType, ApplicationType, License} from 'sdk/src/defines';
import defaultImage from '@src/assets/images/content/channel/128.jpg';
import AbstractFileModelResource from './abstract/abstract-file-model-resource';
import {DispatchFunc, DispatchResponse, LocalesType} from '../utility/types';


class Operator extends AbstractFileModelResource {
    public declare name: string;

    public declare operatorSettings?: OperatorSettings;

    public declare playerSettings?: PlayerSettings;

    public declare license?: License;

    public resourceItemClass: typeof Operator = Operator;

    public resourceType: Resource = Resource.OPERATOR;

    public locales: LocalesType;

    public allowedActions: ActionType[] = [
        ActionType.UPDATE,
        ActionType.GET,
    ];

    public uploadFiles(files: {[name: string]: File}): DispatchResponse {
        return async (dispatch: DispatchFunc) => {
            const names: string[] = Object.keys(files);

            for (const name of names) {
                await this.api().upload(`${this.resourceType.toLowerCase()}/file?name=${name}`, {
                    file: files[name],
                }).catch(() => null)
            }

            dispatch(this.clear());
            dispatch(this.load());
        };
    }

    public getThemeParameter(application: ApplicationType, parameter: string, defaultValue?: any): any {
        if (undefined === this.playerSettings?.theme ||
			undefined === this.playerSettings?.theme[application] ||
			undefined === this.playerSettings?.theme[application][parameter]) {
            return defaultValue;
        }

        return this.playerSettings.theme[application][parameter];
    }

    public getImage(name?: string): string {
        return super.getImage(defaultImage, name);
    }

    public deleteFile(name: string = 'file'): DispatchResponse {
        return (dispatch: DispatchFunc): void => {
            this.api().delete(`${this.resourceType.toLowerCase()}/file?name=${name}`).then(() => {
                dispatch(this.clear());
                dispatch(this.load());
            }).catch(() => null);
        };
    }

}

export {Operator};

export default new Operator({}, null);

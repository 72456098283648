import AbstractListResource from './abstract/abstract-list-resource';
import {Resource, ActionType}  from 'sdk/src/defines';
import Menu from './items/menu';
import {SortFunc} from '../utility/types';
import {sortAsc} from '../utility/utils';

class Menus extends AbstractListResource<Menu> {
    public resourceType: Resource = Resource.MENUS;

    public resourceItemClass: typeof Menu = Menu;

    public sort: SortFunc = sortAsc('order');

    public allowedActions: ActionType[] = [
        ActionType.LIST,
        ActionType.UPDATE,
        ActionType.DELETE,
        ActionType.CREATE,
    ];
}

export {Menus, Menu};
export default new Menus();

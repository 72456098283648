const productionDomain: string = `${window.location.protocol}//${window.location.host}`;
const developmentDomain: string = `${window.location.protocol}//${process.env.API_HOST ? process.env.API_HOST : 'localhost'}`;
const apiHOST: string = process.env.NODE_ENV === 'development' ? developmentDomain : productionDomain;

const apiPORT: string = process.env.NODE_ENV === 'development' ? process.env.API_PORT ? process.env.API_PORT : '8079' : '';
const apiURL: string = `${apiHOST}${apiPORT ? `:${apiPORT}` : ''}/api/dashboard`;
const hostURL: string = `${apiHOST}${apiPORT ? `:${apiPORT}` : ''}`;
const socketURL: string = `${apiHOST}:${process.env.SOCKET_PORT ? process.env.SOCKET_PORT : ''}`;
const socialLoginRedirectUrl: string = process.env.NODE_ENV === 'development' ? developmentDomain : productionDomain;
const config: any = {
    apiURL,
    socketURL,
    hostURL,
    redirectUrl: socialLoginRedirectUrl,
};

export default config;

window['config'] = config;

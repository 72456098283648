import AbstractListResource from './abstract/abstract-list-resource';
import {ActionType, Resource} from 'sdk/src/defines';
import Plugin from './items/plugin';
import {DispatchFunc, DispatchResponse} from "../utility/types";

class Plugins extends AbstractListResource<Plugin> {
    public resourceType: Resource = Resource.PLUGINS;

    public resourceItemClass: typeof Plugin = Plugin;

    public allowedActions: ActionType[] = [
        ActionType.LIST,
        ActionType.UPDATE,
        ActionType.DELETE,
        ActionType.CREATE,
    ];

    public createWithFile(file: File): DispatchResponse {
        return async (dispatch: DispatchFunc) => {
            return this.api().upload(`${this.resourceType.toLowerCase()}`, {file}).then((data: any) => {
                dispatch(this.created(data));
            }).catch(() => null)
        };
    }

}

export {Plugins, Plugin};

export default new Plugins();

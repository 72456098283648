import AbstractListResource from './abstract/abstract-list-resource';
import {Resource, ActionType} from 'sdk/src/defines';
import Campaign from './items/campaign';

class Campaigns extends AbstractListResource<Campaign> {
    public resourceType: Resource = Resource.CAMPAIGNS;

    public resourceItemClass: typeof Campaign = Campaign;

    public allowedActions: ActionType[] = [
        ActionType.LIST,
        ActionType.CREATE,
        ActionType.UPDATE,
        ActionType.DELETE,
    ];
}

export {Campaigns, Campaign};

export default new Campaigns();

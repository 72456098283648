import AbstractListResource from './abstract/abstract-list-resource';
import {ActionType, Resource, Uid} from 'sdk/src/defines';
import PlanCategory from './items/plan-cetegory';
import {DispatchFunc, DispatchResponse, SortFunc} from '../utility/types';
import {sortAsc} from '../utility/utils';
import plans from './plans';
import Plan from './items/plan';

class PlanCategories extends AbstractListResource<PlanCategory> {
    public resourceType: Resource = Resource.PLAN_CATEGORIES;

    public resourceItemClass: typeof PlanCategory = PlanCategory;

    public sort: SortFunc = sortAsc('order');

    public allowedActions: ActionType[] = [
        ActionType.LIST,
        ActionType.UPDATE,
        ActionType.DELETE,
        ActionType.CREATE,
    ];

    public getConnectedResourceTypes(): Resource[] {
        return [Resource.PLANS];
    }

    public onDeleted(items: PlanCategory[]): DispatchResponse {
        return (dispatch: DispatchFunc) => {
            dispatch(super.onDeleted(items));

            if (plans.isInitialized()) {
                items.forEach((item: PlanCategory) => {
                    const plan: Plan = plans.findById(item.plan.id);

                    if (plan) {
                        const index: number = plan.categories
                            .findIndex((el: {categoryId: Uid}) => el.categoryId === item.categoryId);

                        if (index >= 0) {
                            plan.categories.splice(index, 1);
                        }
                    }
                });
            }
        };
    }
}

export {PlanCategories, PlanCategory};

export default new PlanCategories();

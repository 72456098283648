import {Resource} from 'sdk/src/defines';

export enum ReduxStatus {
    UNKNOWN = '',
    RECEIVING = 'RECEIVING',
    RECEIVED = 'RECEIVED',
    RECEIVE_FAILED = 'RECEIVE_FAILED',
    SINGLE_ITEM_RECEIVING = 'SINGLE_ITEM_RECEIVING',
    SINGLE_ITEM_RECEIVED = 'SINGLE_ITEM_RECEIVED',
    SINGLE_ITEM_RECEIVE_FAILED = 'SINGLE_ITEM_RECEIVE_FAILED',
    REMOTE_UPDATING = 'REMOTE_UPDATING',
    REMOTE_UPDATED = 'REMOTE_UPDATED',
    UPDATING = 'UPDATING',
    UPDATED = 'UPDATED',
    UPDATE_FAILED = 'UPDATE_FAILED',
    REMOTE_CREATING = 'REMOTE_CREATING',
    REMOTE_CREATED = 'REMOTE_CREATED',
    CREATING = 'CREATING',
    CREATED = 'CREATED',
    CREATE_FAILED = 'CREATE_FAILED',
    REMOTE_DELETING = 'REMOTE_DELETING',
    REMOTE_DELETED = 'REMOTE_DELETED',
    DELETING = 'DELETING',
    DELETED = 'DELETED',
    DELETE_FAILED = 'DELETE_FAILED',
    CLEAR = 'CLEAR',
    SINGLE_ITEM_CLEAR = 'SINGLE_ITEM_CLEAR',
    WORKSPACE_CHANGING = 'WORKSPACE_CHANGING',
    WORKSPACE_CHANGED = 'WORKSPACE_CHANGED',
    REFRESHING = 'REFRESHING',
    REFRESHED = 'REFRESHED',
}

const abstractReducer = (state, action, resourceName) => {
    let result = state;

    if (resourceName === Resource.PROFILE) {
        if (action.type === ReduxStatus.WORKSPACE_CHANGING || action.type === ReduxStatus.WORKSPACE_CHANGED) {
            return {
                ...state,
                status: action.type,
                workspaceId: action.id,
            };
        }
    }

    Object.keys(ReduxStatus).forEach(status => {
        if (`${resourceName}_${status}` === action.type) {
            result = {
                ...state,
                status,
                id: action.id,
                error: action.error,
            };
        }
    });

    return result;
};

export default abstractReducer;

import AbstractListResource from './abstract/abstract-list-resource';
import {Resource, ActionType} from 'sdk/src/defines';
import Form from './items/form';

class Forms extends AbstractListResource<Form> {
    public resourceType: Resource = Resource.FORMS;

    public resourceItemClass: typeof Form = Form;

    public allowedActions: ActionType[] = [
        ActionType.LIST,
        ActionType.UPDATE,
        ActionType.DELETE,
        ActionType.CREATE,
    ];
}

export {Forms, Form};
export default new Forms();

import AbstractItemResource from '../abstract/abstract-item-resource';
import {Resource, Uid} from 'sdk/src/defines';
import {CastRule, CastType} from '../../utility/types';

class PlanCategory extends AbstractItemResource {
    public resourceType: Resource = Resource.PLAN_CATEGORIES;

    public declare categoryId: string;

    public declare order: number;

    public declare plan: {
        title: string,
        id: Uid,
    };

    public castValues(): CastType {
        return {
            amount: CastRule.FLOAT,
        };
    }
}

export default PlanCategory;

import AbstractListResource from './abstract/abstract-list-resource';
import {ActionType, Resource, RoleType} from 'sdk/src/defines';
import Role from './items/role';
import {TFunction} from 'react-i18next';
import {DispatchFunc, DispatchResponse, DropDownItem} from '../utility/types';
import users from './users';
import profile from './profile';

class Roles extends AbstractListResource<Role> {

    public resourceType: Resource = Resource.ROLES;

    public resourceItemClass: typeof Role = Role;

    public allowedActions: ActionType[] = [
        ActionType.LIST,
        ActionType.UPDATE,
        ActionType.DELETE,
        ActionType.CREATE,
    ];

    public forDropDown(t: TFunction, empty?: string, types?: RoleType[]): DropDownItem[] {
        const result: DropDownItem[] = [];

        if (!this.isInitialized()) {
            return [];
        }

        if (empty) {
            result.push({
                label: empty,
                value: null,
            });
        }

        this.getItems().forEach((item: Role) => {
            if (!Array.isArray(types) || types.includes(item.type)) {
                result.push({
                    value: item.id,
                    label: item.getTitle(t),
                });
            }
        });

        return result;
    }

    public onDeleted(items: Role[]): DispatchResponse {
        return (dispatch: DispatchFunc) => {
            dispatch(users.refresh());
            dispatch(profile.refresh());
            dispatch(super.onDeleted(items));
        };
    }

}

export {Roles, Role};

export default new Roles();

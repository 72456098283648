import AbstractListResource from './abstract/abstract-list-resource';
import Log from './items/log';
import {ActionType, LogAction, Resource} from 'sdk/src/defines';
import {DropDownItem} from '../utility/types';
import {TFunction} from 'react-i18next';

class Logs extends AbstractListResource<Log> {
    public resourceType: Resource = Resource.LOGS;

    public resourceItemClass: typeof Log = Log;

    public allowedActions: ActionType[] = [
        ActionType.LIST,
    ];

    public getConnectedResourceTypes(): Resource[] {
        return [Resource.USERS];
    }

    public actionsForDropDown(t: TFunction, empty?: string): DropDownItem[] {
        const result: DropDownItem[] = [];

        if (empty) {
            result.push({
                label: empty,
                value: null,
            });
        }

        result.push({
            value: LogAction.CREATE,
            label: t('logs.create'),
        });
        result.push({
            value: LogAction.UPDATE,
            label: t('logs.edit'),
        });
        result.push({
            value: LogAction.DELETE,
            label: t('logs.delete'),
        });
        result.push({
            value: LogAction.LOGIN,
            label: t('logs.login'),
        });
        result.push({
            value: LogAction.LOGOUT,
            label: t('logs.logout'),
        });

        return result;
    }
}

export {Logs, Log};

export default new Logs();

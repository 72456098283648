import AbstractResource from './abstract-resource';
import {Uid, OS, ApplicationType, Resource, ActionType} from 'sdk/src/defines';
import {
    AttributesType,
    DispatchFunc,
    DispatchResponse,
    DropDownItem,
    ErrorType,
    LabelsType,
    SortFunc,
} from '../../utility/types';
import {TFunction} from 'react-i18next';
import AbstractListResource from './abstract-list-resource';

class AbstractItemResource extends AbstractResource {
    public declare id: Uid;

    public labels: LabelsType = {id: 'ID'};

    public declare os: OS | OS[];

    public declare createdAt: Date;

    public declare updatedAt: Date;

    public declare beginAt: Date;

    public declare endAt: Date;

    public static resourceType: Resource;

    public static allowedActions: ActionType[];

    public static connectedResourceTypes: Resource[];

    public static sort: SortFunc;

    public static list(): AbstractListResource<any> {
        const list: AbstractListResource<any> = new AbstractListResource<any>();
        list.resourceType = this.resourceType;
        list.allowedActions = this.allowedActions;
        list.resourceItemClass = this;
        list.connectedResourceTypes = this.connectedResourceTypes;
        list.sort = this.sort;

        return list;
    }


    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public getTitle(t: TFunction): string {
        return this['title'];
    }

    public hasAvailabilityLimits(): boolean {
        return !((!this.beginAt || this.beginAt.getTime() === this.createdAt?.getTime()) && !this.endAt);
    }

    public isAvailable(): boolean {
        if (!this.hasAvailabilityLimits()) {
            return true;
        }

        const now: number = Date.now();

        return (!this.beginAt || now >= this.beginAt.getTime()) && (!this.endAt || now <= this.endAt.getTime());
    }

    public refresh(params?: AttributesType, storeId?: Uid): DispatchResponse {
        return (dispatch: DispatchFunc) => {
            if (!storeId) {
                storeId = this.id;
            }

            try {
                dispatch({type: `${this.resourceType}_SINGLE_ITEM_RECEIVING`});
                this.parent.apiCalls.get(this.id, params).then((data: AttributesType) => {
                    this.setData(data);
                    dispatch({type: `${this.resourceType}_SINGLE_ITEM_RECEIVED`, id: storeId});
                }).catch((err: ErrorType) => {
                    dispatch({type: `${this.resourceType}_SINGLE_ITEM_RECEIVE_FAILED`, error: err});
                });
            } catch (e) {
                console.log(e);
            }

        };
    }

    public static osForDropDown(empty?: string, application?: ApplicationType): DropDownItem[] {
        const result: DropDownItem[] = [];

        if (empty) {
            result.push({
                label: empty,
                value: null,
            });
        }

        if (application) {
            switch (application) {
                case ApplicationType.MOBILE:
                    result.push(
                        {
                            label: 'Android Mobile',
                            value: OS.ANDROID_MOBILE,
                        },
                        {
                            label: 'iOS',
                            value: OS.IOS,
                        },
                        {
                            label: 'Android Browser',
                            value: OS.ANDROID_BROWSER,
                        },
                        {
                            label: 'iOS Browser',
                            value: OS.IOS_BROWSER,
                        },
                    );
                    break;
                case ApplicationType.WEB:
                    result.push(
                        {
                            label: 'Windows Browser',
                            value: OS.WINDOWS_BROWSER,
                        },
                        {
                            label: 'Linux Browser',
                            value: OS.LINUX_BROWSER,
                        },
                        {
                            label: 'Macos Browser',
                            value: OS.MACOS_BROWSER,
                        },
                    );
                    break;
                case ApplicationType.TV:
                    result.push(
                        {
                            label: 'TV Android',
                            value: OS.ANDROID_TV,
                        },
                        {
                            label: 'Tizen',
                            value: OS.TIZEN,
                        },
                        {
                            label: 'WebOS',
                            value: OS.WEBOS,
                        },
                        {
                            label: 'Roku',
                            value: OS.ROKU,
                        },
                    );
                    break;
                default:
                    break;
            }

            return result;
        }

        result.push(
            {
                label: 'TV Android',
                value: OS.ANDROID_TV,
            },
            {
                label: 'Android Mobile',
                value: OS.ANDROID_MOBILE,
            },
            {
                label: 'iOS',
                value: OS.IOS,
            },
            {
                label: 'Tizen',
                value: OS.TIZEN,
            },
            {
                label: 'WebOS',
                value: OS.WEBOS,
            },
            {
                label: 'iOS Browser',
                value: OS.IOS_BROWSER,
            },
            {
                label: 'Android Browser',
                value: OS.ANDROID_BROWSER,
            },
            {
                label: 'Windows Browser',
                value: OS.WINDOWS_BROWSER,
            },
            {
                label: 'Linux Browser',
                value: OS.LINUX_BROWSER,
            },
            {
                label: 'Macos Browser',
                value: OS.MACOS_BROWSER,
            },
            {
                label: 'Roku',
                value: OS.ROKU,
            },
        );

        return result;
    }

    public osStr(t: TFunction): string {
        if (!this.os || !this.os.length) {
            return t('allPlatforms');
        }

        const result: string[] = [];
        AbstractItemResource.osForDropDown().forEach((el: DropDownItem) => {
            if (this.os.includes(el.value as OS)) {
                result.push(el.label);
            }
        });

        return result.join(', ');
    }

    public delete(): DispatchResponse {
        if (this.parent) {
            return this.parent.delete(this.id);
        }
    }

    public update(params: AttributesType): DispatchResponse {
        if (this.parent) {
            return this.parent.update(params, this.id);
        }

        return super.update(params);
    }

}

export default AbstractItemResource;

import AbstractItemResource from '../abstract/abstract-item-resource';
import {Resource, FormType, FormFieldType, OS, FormField} from 'sdk/src/defines';
import {
    faIdCard, faKey,
    faRegistered,
    faSignInAlt,
    faUser,
} from '@fortawesome/free-solid-svg-icons';
import AbstractResource from '../abstract/abstract-resource';
import {TFunction} from 'react-i18next';
import {DropDownItem} from '../../utility/types';
import {IconDefinition} from '@fortawesome/fontawesome-svg-core';

class Form extends AbstractItemResource {
    public resourceType: Resource = Resource.FORMS;

    public declare type: FormType;

    public declare os: OS[];

    public declare fields: FormField[];

    public getIcon(): IconDefinition {
        switch (this.type) {
            case FormType.REGISTRATION:
            case FormType.CONFIRM:
                return faRegistered;
            case FormType.LOGIN:
                return faSignInAlt;
            case FormType.EDIT_PROFILE:
                return faUser;
            case FormType.RECOVER_PASSWORD:
            case FormType.RESET_PASSWORD:
            case FormType.CHANGE_PASSWORD:
            case FormType.CHANGE_PARENTAL_PIN:
            case FormType.ENTER_PARENTAL_PIN:
                return faKey;
            case FormType.ACTIVATE_VOUCHER:
                return faIdCard;
        }
    }

    public static fieldTypesForDropDown(t: TFunction, formType: FormType): DropDownItem[] {
        switch (formType) {
            case FormType.LOGIN:
                return [
                    {
                        label: t('forms.account'),
                        value: FormFieldType.LOGIN,
                    },
                    {
                        label: t('forms.password'),
                        value: FormFieldType.PASSWORD,
                    },
                    {
                        label: t('forms.authorizationPlugin'),
                        value: FormFieldType.AUTHORIZATION_PLUGIN,
                    },
                ];
            case FormType.REGISTRATION:
                return [
                    {
                        label: t('forms.password'),
                        value: FormFieldType.PASSWORD,
                    },
                    {
                        label: t('forms.repeatPassword'),
                        value: FormFieldType.REPEAT_PASSWORD,
                    },
                    {
                        label: t('forms.email'),
                        value: FormFieldType.EMAIL,
                    },
                    {
                        label: t('forms.phone'),
                        value: FormFieldType.PHONE,
                    },
                    {
                        label: t('forms.username'),
                        value: FormFieldType.USERNAME,
                    },
                    {
                        label: t('forms.address'),
                        value: FormFieldType.ADDRESS,
                    },
                    {
                        label: t('forms.zipCode'),
                        value: FormFieldType.ZIP_CODE,
                    },
                    {
                        label: t('forms.firstName'),
                        value: FormFieldType.FIRST_NAME,
                    },
                    {
                        label: t('forms.lastName'),
                        value: FormFieldType.LAST_NAME,
                    },
                    {
                        label: t('forms.captcha'),
                        value: FormFieldType.CAPTCHA,
                    },
                    {
                        label: t('forms.custom'),
                        value: FormFieldType.CUSTOM,
                    },
                ];
            case FormType.CHANGE_PASSWORD:
                return [
                    {
                        label: t('forms.oldPassword'),
                        value: FormFieldType.OLD_PASSWORD,
                    },
                    {
                        label: t('forms.newPassword'),
                        value: FormFieldType.NEW_PASSWORD,
                    },
                    {
                        label: t('forms.repeatPassword'),
                        value: FormFieldType.REPEAT_PASSWORD,
                    },
                ];
            case FormType.EDIT_PROFILE:
                return [
                    {
                        label: t('forms.email'),
                        value: FormFieldType.EMAIL,
                    },
                    {
                        label: t('forms.phone'),
                        value: FormFieldType.PHONE,
                    },
                    {
                        label: t('forms.username'),
                        value: FormFieldType.USERNAME,
                    },
                    {
                        label: t('forms.address'),
                        value: FormFieldType.ADDRESS,
                    },
                    {
                        label: t('forms.zipCode'),
                        value: FormFieldType.ZIP_CODE,
                    },
                    {
                        label: t('forms.firstName'),
                        value: FormFieldType.FIRST_NAME,
                    },
                    {
                        label: t('forms.lastName'),
                        value: FormFieldType.LAST_NAME,
                    },
                    {
                        label: t('forms.custom'),
                        value: FormFieldType.CUSTOM,
                    },
                ];
            case FormType.RECOVER_PASSWORD:
                return [
                    {
                        label: t('forms.login'),
                        value: FormFieldType.LOGIN,
                    },
                    {
                        label: t('forms.captcha'),
                        value: FormFieldType.CAPTCHA,
                    },
                ];
            case FormType.RESET_PASSWORD:
                return [
                    {
                        label: t('forms.code'),
                        value: FormFieldType.CODE,
                    },
                    {
                        label: t('forms.newPassword'),
                        value: FormFieldType.NEW_PASSWORD,
                    },
                    {
                        label: t('forms.repeatPassword'),
                        value: FormFieldType.REPEAT_PASSWORD,
                    },
                ];
            case FormType.ACTIVATE_VOUCHER:
                return [
                    {
                        label: t('forms.code'),
                        value: FormFieldType.CODE,
                    },
                ];
            case FormType.CONFIRM:
                return [
                    {
                        label: t('forms.emailVerificationCode'),
                        value: FormFieldType.EMAIL_VERIFICATION_TOKEN,
                    },
                    {
                        label: t('forms.phoneVerificationCode'),
                        value: FormFieldType.PHONE_VERIFICATION_TOKEN,
                    },
                ];
            case FormType.CHANGE_PARENTAL_PIN:
                return [
                    {
                        label: t('forms.oldPin'),
                        value: FormFieldType.OLD_PIN,
                    },
                    {
                        label: t('forms.newPin'),
                        value: FormFieldType.NEW_PIN,
                    },
                ];
            case FormType.ENTER_PARENTAL_PIN:
                return [
                    {
                        label: t('forms.pin'),
                        value: FormFieldType.PIN,
                    },
                ];
            default:
                return [];
        }
    }

    public getTypeStr(t: TFunction): string {
        const item: DropDownItem = Form.typesForDropDown(t)
            .find((item: DropDownItem) => item.value === this.type);

        if (item) {
            return item.label;
        }

        return null;
    }

    public static typesForDropDown(t: TFunction): DropDownItem[] {
        return AbstractResource.defineForDropDown(FormType, 'forms', t);
    }
}

export default Form;

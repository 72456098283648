import AbstractItemResource from '../abstract/abstract-item-resource';
import {Resource, Uid} from 'sdk/src/defines';

class Epg extends AbstractItemResource {
    public resourceType: Resource = Resource.EPGS;

    public declare title: Uid;

    public declare beginAt: Date;

    public declare stopAt: Date;
}

export default Epg;

import AbstractListResource from './abstract/abstract-list-resource';
import {ActionType, PluginType, Resource} from 'sdk/src/defines';
import PluginInstance from './items/plugin-instance';
import {TFunction} from 'react-i18next';
import {DropDownItem} from '../utility/types';

class PluginInstances extends AbstractListResource<PluginInstance> {
    public resourceType: Resource = Resource.PLUGIN_INSTANCES;

    public resourceItemClass: typeof PluginInstance = PluginInstance;

    public allowedActions: ActionType[] = [
        ActionType.LIST,
        ActionType.UPDATE,
        ActionType.CREATE,
        ActionType.DELETE,
    ];

    public forDropDown(t: TFunction, empty?: string, type?: PluginType): DropDownItem[] {
        if (!this.isInitialized()) {
            return [];
        }

        const result: DropDownItem[] = [];

        if (empty) {
            result.push({
                label: empty,
                value: null,
            });
        }

        this.getItems().forEach((item: PluginInstance) => {
            if (!type || item.getPluginType() === type) {
                result.push({
                    label: item.title,
                    value: item.id,
                });
            }
        });

        return result;
    }

    public getPluginInstancesByType(type: PluginType): PluginInstance[] {
        return this.getItems()
            .filter((pluginInstance: PluginInstance) => !type || type === pluginInstance.getPluginType());
    }
}

export {PluginInstances, PluginInstance};

export default new PluginInstances();

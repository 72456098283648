import AbstractItemResource from '../abstract/abstract-item-resource';
import User from './user';
import {Resource, OS, Uid} from 'sdk/src/defines';
import {DropDownItem} from '../../utility/types';

class Device extends AbstractItemResource {
    public resourceType: Resource = Resource.DEVICES;

    public declare subscriberId: Uid;

    public declare deviceId: string;

    public getOs(): string {
        const el: DropDownItem = User.osForDropDown()
            .find((el: DropDownItem) => (el.value as OS) === this.os);

        if (!el) {
            return null;
        }

        return el.label;
    }
}

export default Device;

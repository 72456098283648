import AbstractListResource from './abstract/abstract-list-resource';
import {ActionType, Resource} from 'sdk/src/defines';
import ApiKey from './items/apikey';
import {TFunction} from 'react-i18next';
import {DropDownItem} from '../utility/types';

class ApiKeys extends AbstractListResource<ApiKey> {
    public resourceType: Resource = Resource.API_KEYS;

    public resourceItemClass: typeof ApiKey = ApiKey;

    public allowedActions: ActionType[] = [
        ActionType.LIST,
        ActionType.CREATE,
        ActionType.UPDATE,
        ActionType.DELETE,
    ];

    public typesForDropDown(t: TFunction): DropDownItem[] {
        return [
            {label: t('apiKeys.userPermissions'), value: 'user'},
            {label: t('apiKeys.readOnly'), value: 'readonly'},
        ];
    }

    public getConnectedResourceTypes(): [Resource] {
        return [Resource.ROLES];
    }
}

export {ApiKeys, ApiKey};

export default new ApiKeys();

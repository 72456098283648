import AbstractResource from './abstract-resource';
import AbstractItemResource from './abstract-item-resource';
import {Uid} from 'sdk/src/defines';
import {AttributesType, DispatchFunc, DispatchResponse, DropDownItem, ErrorType, SortFunc} from '../../utility/types';
import {TFunction} from 'react-i18next';

export default class AbstractListResource<T> extends AbstractResource {

    public sort: SortFunc;

    public declare id: Uid;

    public declare count: number;

    private items: T[] = null;

    private singleItems: {[id: string]: T} = {};

    private loadingSingleItemIds: Uid[] = [];

    private idsIndex: {[id: string]: T} = {};

    private isLoading: {[key: string]: boolean} = {};

    public forDropDown(t: TFunction, empty?: string): DropDownItem[] {
        if (!this.isInitialized()) {
            return [];
        }

        const result: DropDownItem[] = [];

        if (empty) {
            result.push({
                label: empty,
                value: null,
            });
        }

        this.getItems().forEach((item: T) => {
            const it: AbstractItemResource = item as AbstractItemResource;
            result.push({
                label: it.getTitle(t),
                value: it.id,
            });
        });

        return result;
    }

    public setItems(items: AttributesType[]): void {
        this.items = [];
        items.forEach((item: AttributesType) => {
            if (undefined !== item.id) {
                const model: AbstractItemResource = new this.resourceItemClass(item, this) as AbstractItemResource;
                this.idsIndex[model.id] = model as T;
                this.items.push(model as T);
            }
        });
    }

    public getDefaultId(): Uid {
        if (!this.isInitialized()) {
            return null;
        }

        const items: T[] = this.getItems();

        for (let i: number = 0; i < items.length; i++) {
            if (items[i]['default'] === true) {
                return items[i]['id'];
            }
        }

        return null;
    }

    public getLength(): number {
        if (!this.items) {
            return 0;
        }

        return this.items.length;
    }

    public indexById(id: Uid): number {
        if (!this.items) {
            return -1;
        }

        return this.items.findIndex((el: T) => el['id'] === id);
    }

    public findById(id: Uid): T {
        if (!this.isInitialized()) {
            return null;
        }

        return this.idsIndex[id];
    }

    public upload(params: AttributesType): DispatchResponse {
        return (dispatch: DispatchFunc) => {
            dispatch({type: `${this.resourceType}_CREATING`});
            this.apiCalls.upload(params).then((data: AttributesType) => {
                dispatch(this.created(data));
            }).catch((err: ErrorType) => {
                dispatch({type: `${this.resourceType}_CREATE_FAILED`, error: err});
            });
        };
    }

    public create(params?: AttributesType): DispatchResponse {
        return (dispatch: DispatchFunc) => {
            dispatch({type: `${this.resourceType}_CREATING`});
            this.apiCalls.create(params).then((data: AttributesType) => {
                dispatch(this.created(data));
            }).catch((err: ErrorType) => {
                dispatch({type: `${this.resourceType}_CREATE_FAILED`, error: err});
            });
        };
    }

    public created(data: AttributesType, remoteUpdate: boolean = false): DispatchResponse {
        return (dispatch: DispatchFunc) => {

            if (!data.id) {
                dispatch({type: `${this.resourceType}_CREATED`});

                return;
            }

            if (this.findById(data.id)) {
                dispatch(this.updated(data, remoteUpdate));

                return;
            }

            if (!this.items) {
                this.items = [];
            }

            const model: AbstractItemResource = new this.resourceItemClass(data, this) as AbstractItemResource;
            this.idsIndex[model.id] = model as T;
            this.items.unshift(model as T);

            dispatch(this.onCreated(model));

            if (remoteUpdate) {
                dispatch({type: `${this.resourceType}_REMOTE_CREATING`});
                setTimeout(() => {
                    dispatch({type: `${this.resourceType}_REMOTE_CREATED`, id: data.id, item: model});
                }, 10)
            } else {
                dispatch({type: `${this.resourceType}_CREATED`, id: data.id, item: model});
            }
        };
    }

    public clear(): DispatchResponse {
        return (dispatch: DispatchFunc) => {
            this.clearData();
            dispatch({type: `${this.resourceType}_CLEAR`});
        };
    }

    public clearSingleItem(id: Uid): DispatchResponse {
        return (dispatch: DispatchFunc) => {
            this.clearSingleData(id);
            dispatch({type: `${this.resourceType}_SINGLE_ITEM_CLEAR`, id});
        };
    }

    public clearSingleData(id: Uid): void {
        delete this.singleItems[id];
    }

    public clearSingleItems(): DispatchResponse {
        return (dispatch: DispatchFunc) => {
            this.singleItems = {};
            dispatch({type: `${this.resourceType}_SINGLE_ITEM_CLEAR`});
        };
    }

    public clearData(): AttributesType {
        this.idsIndex = {};
        this.items = null;

        return {};
    }

    public delete(ids: Uid | Uid[]): DispatchResponse {
        return (dispatch: DispatchFunc) => {
            dispatch({type: `${this.resourceType}_DELETING`, ids});
            this.apiCalls.delete(ids).then(() => {
                dispatch(this.deleted(ids));
            }).catch((err: ErrorType) => {
                dispatch({type: `${this.resourceType}_DELETE_FAILED`, ids, error: err});
            });
        };
    }

    public deleted(id: Uid | Uid[], remoteUpdate: boolean = false): DispatchResponse {
        return (dispatch: DispatchFunc) => {
            if (!this.isInitialized()) {
                return;
            }

            const ids: Uid[] = Array.isArray(id) ? id : [id];
            const removedItems: T[] = [];
            ids.forEach((id: Uid) => {
                const index: number = this.indexById(id);

                if (index >= 0) {
                    delete this.idsIndex[this.items[index]['id']];
                    removedItems.push(this.items[index]);
                    this.items.splice(index, 1);
                }
            });

            if (removedItems.length) {
                dispatch(this.onDeleted(removedItems.map((el: T) => el as AbstractResource)));

                if (remoteUpdate) {
                    dispatch({type: `${this.resourceType}_REMOTE_DELETING`, id});
                    dispatch({type: `${this.resourceType}_REMOTE_DELETED`, id});
                } else {
                    dispatch({type: `${this.resourceType}_DELETED`, id});
                }
            }
        };
    }

    public update(params: AttributesType, id?: Uid | Uid[]): DispatchResponse {
        return (dispatch: DispatchFunc) => {
            if (undefined === id) {
                return;
            }

            const ids: Uid[] = Array.isArray(id) ? id : [id];
            type RestoreItem = {data: AttributesType, item: any};
            const restoreItems: RestoreItem[] = [];
            ids.forEach((idItem: Uid) => {
                if (idItem === null) {
                    restoreItems.push({data: this.mergeData(params), item: this});
                } else {
                    const item: AbstractItemResource = this.findById(idItem) as AbstractItemResource;

                    if (item) {
                        restoreItems.push({data: item.mergeData(params), item});
                    }
                }

                if (this.singleItems[idItem]) {
                    (this.singleItems[idItem] as AbstractItemResource).mergeData(params);
                }
            });

            dispatch({type: `${this.resourceType}_UPDATING`, id});
            this.apiCalls.update(id, params).then((data: AttributesType) => {
                dispatch(this.updated(data));
            }).catch((err: ErrorType) => {
                restoreItems.forEach((restoreItem: RestoreItem) => {
                    restoreItem.item.mergeData(restoreItem.data);

                    if (this.singleItems[restoreItem.item.id]) {
                        (this.singleItems[restoreItem.item.id] as AbstractItemResource).mergeData(restoreItem.data);
                    }
                });

                dispatch({type: `${this.resourceType}_UPDATE_FAILED`, id, error: err});
            });
        };
    }

    public updated(data: AttributesType, remoteUpdate: boolean = false): DispatchResponse{
        return (dispatch: DispatchFunc) => {
            const items: T[] = [];
            let ids: Uid | Uid[] = [];

            if (Array.isArray(data)) {
                data.forEach((dataItem: AttributesType) => {
                    const index: number = this.indexById(dataItem.id);

                    if (index >= 0 && Array.isArray(ids)) {
                        ids.push(dataItem.id);
                        (this.items[index] as AbstractItemResource).mergeData(dataItem);
                        items.push(this.items[index]);
                    }

                    if (this.singleItems[dataItem.id]) {
                        (this.singleItems[dataItem.id] as AbstractItemResource).mergeData(dataItem);
                    }
                });
            } else {
                const index: number = this.indexById(data.id);

                if (index >= 0) {
                    ids = data.id;
                    (this.items[index] as AbstractItemResource).mergeData(data);
                    items.push(this.items[index]);
                }

                if (this.singleItems[data.id]) {
                    (this.singleItems[data.id] as AbstractItemResource).mergeData(data);
                }

                if (data.id === this.id) {
                    this.mergeData(data);
                }
            }

            if (items.length > 0 || this.singleItems[data.id] || data.id === this.id) {
                dispatch(this.onUpdated(items.map(((el: T) => el as AbstractResource))));

                if (remoteUpdate) {
                    dispatch({type: `${this.resourceType}_REMOTE_UPDATING`, id: ids});
                    dispatch({type: `${this.resourceType}_REMOTE_UPDATED`, id: ids});
                } else {
                    dispatch({type: `${this.resourceType}_UPDATED`, id: ids});
                }
            }
        };
    }

    public refresh(params: AttributesType = {}): DispatchResponse {
        return this.load(params, true);
    }

    public refreshSingleItem(id: Uid, params: AttributesType = {}, storeId?: Uid): DispatchResponse {
        return this.loadSingleItem(id, params, true, storeId);
    }

    public getSingleItem(id: Uid): T {
        return this.singleItems[id];
    }

    public getSingleItems(): {[id: string]: T} {
        return this.singleItems;
    }

    public isSingleItemInitialized(id: Uid): boolean {
        return undefined !== this.singleItems[id];
    }

    public loadSingleItem(id: Uid, params: AttributesType = {}, ignoreCache: boolean = false, storeId: Uid = null): DispatchResponse {
        return (dispatch: DispatchFunc) => {
            if (!storeId) {
                storeId = id;
            }

            if (this.loadingSingleItemIds.includes(storeId)) {
                return;
            }

            this.loadingSingleItemIds.push(storeId);
            dispatch({type: `${this.resourceType}_SINGLE_ITEM_RECEIVING`});

            if (this.singleItems[storeId] && ignoreCache === false) {
                dispatch({type: `${this.resourceType}_SINGLE_ITEM_RECEIVED`});

                const index: number = this.loadingSingleItemIds.indexOf(storeId);

                if (index >= 0) {
                    this.loadingSingleItemIds.splice(index, 1);
                }
            } else {

                delete this.singleItems[storeId];
                this.apiCalls.get(id, params).then((data: AttributesType) => {
                    this.singleItems[storeId] = new this.resourceItemClass(data, this) as T;
                    dispatch({type: `${this.resourceType}_SINGLE_ITEM_RECEIVED`, id: storeId});
                }).catch((err: ErrorType) => {
                    dispatch({type: `${this.resourceType}_SINGLE_ITEM_RECEIVE_FAILED`, error: err});
                }).finally(() => {
                    const index: number = this.loadingSingleItemIds.indexOf(storeId);

                    if (index >= 0) {
                        this.loadingSingleItemIds.splice(index, 1);
                    }
                });
            }
        };
    }

    public load(params: AttributesType = {}, ignoreCache: boolean = false): DispatchResponse {
        return (dispatch: DispatchFunc) => {
            const key: string = JSON.stringify(params);

            if (this.isLoading[key]) {
                return;
            }

            dispatch({type: `${this.resourceType}_RECEIVING`});

            if (!this.canView() && this.apiCalls.dropDownList === undefined) {
                this.items = [];
                this.idsIndex = {};
                dispatch({type: `${this.resourceType}_RECEIVED`});
                dispatch(this.onLoaded(this.items.map((el: T) => el as AbstractResource)));

                return;
            }

            this.isLoading[key] = true;

            if (this.isInitialized() && ignoreCache === false) {
                dispatch({type: `${this.resourceType}_RECEIVED`});
                delete this.isLoading[key];
            } else {
                const functionCall: Promise<any> = this.canView() ? this.apiCalls.list(params) : this.apiCalls.dropDownList(params);
                functionCall.then((data: AttributesType) => {
                    this.items = [];
                    this.idsIndex = {};
                    Object.keys(data).forEach((key: string) => {
                        if (key === 'rows') {
                            data.rows.forEach((item: AttributesType) => {
                                try {
                                    const model: AbstractItemResource = new this.resourceItemClass(item, this) as AbstractItemResource;
                                    this.idsIndex[model.id] = model as T;
                                    this.items.push(model as T);
                                } catch (e) {
                                    console.log(e);
                                }
                            });
                        } else {
                            this[key] = data[key];
                        }
                    });

                    dispatch({type: `${this.resourceType}_RECEIVED`});
                    dispatch(this.onLoaded(this.items.map((el: T) => el as AbstractResource)));
                }).catch((err: ErrorType) => {
                    dispatch({type: `${this.resourceType}_RECEIVE_FAILED`, error: err});
                }).finally(() => {
                    delete this.isLoading[key];
                });
            }
        };
    }

    public isInitialized(): boolean {
        return (this.items !== null);
    }

    public getItems(): T[] {
        if (!this.isInitialized()) {
            return [];
        }

        if (this.sort) {
            return this.items.sort(this.sort);
        }

        return this.items;
    }

}

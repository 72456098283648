import {combineReducers} from 'redux';
import api from '../../api';
import navbar from './navbar';
import layout from './layout';
import profileResource from '../../resources/profile';
import themeResource from '../../resources/theme';
import usersResource from '../../resources/users';
import subscribersResource from '../../resources/subscribers';
import rolesResource from '../../resources/roles';
import templatesResource from '../../resources/templates';
import pluginsResource from '../../resources/plugins';
import plansResource from '../../resources/plans';
import contentsResource from '../../resources/contents';
import categoriesResource from '../../resources/categories';
import episodesResource from '../../resources/episodes';
import packagesResource from '../../resources/packages';
import groupsResource from '../../resources/groups';
import vouchersResource from '../../resources/vouchers';
import formsResource from '../../resources/forms';
import tracksResource from '../../resources/tracks';
import subtitlesResource from '../../resources/subtitles';
import geoipsResource from '../../resources/geoips';
import seasonsResource from '../../resources/seasons';
import announcementsResource from '../../resources/announcements';
import localesResource from '../../resources/locales';
import subscriptionsResource from '../../resources/subscriptions';
import panelsResource from '../../resources/panels';
import customTagTypesResource from '../../resources/custom-tag-types';
import operatorResource from '../../resources/operator';
import operatorsResource from '../../resources/operators';
import termsResource from '../../resources/terms';
import logsResource from '../../resources/logs';
import apiKeysResource from '../../resources/apikeys';
import statisticsResource from '../../resources/statistics';
import paymentsResource from '../../resources/payments';
import configResource from '../../resources/config';
import discountsResource from '../../resources/discounts';
import pluginInstancesResource from '../../resources/plugin-instances';
import menusResource from '../../resources/menus';
import contentCategoriesResource from '../../resources/content-categories';
import planCategoriesResource from '../../resources/plan-categories';
import categoryCategoriesResource from '../../resources/category-categories';
import campaignsResource from '../../resources/campaigns';
import devicesResource from '../../resources/devices';
import tagsResource from '../../resources/tags';
import updatesResource from '../../resources/updates';
import offersResource from '../../resources/offers';
import commercialsResource from '../../resources/commercials';
import epgsResource from '../../resources/epgs';
import resourceCollection from '../../resources/abstract/resource-collection';

resourceCollection.setApi(api);
resourceCollection.addList([
    campaignsResource,
    commercialsResource,
    pluginsResource,
    pluginInstancesResource,
    menusResource,
    termsResource,
    formsResource,
    localesResource,
    templatesResource,
    geoipsResource,
    vouchersResource,
    announcementsResource,
    contentsResource,
    epgsResource,
    categoriesResource,
    contentCategoriesResource,
    planCategoriesResource,
    categoryCategoriesResource,
    seasonsResource,
    episodesResource,
    tracksResource,
    packagesResource,
    groupsResource,
    subscribersResource,
    devicesResource,
    usersResource,
    discountsResource,
    rolesResource,
    plansResource,
    offersResource,
    tagsResource,
    subscriptionsResource,
    paymentsResource,
    operatorResource,
    logsResource,
    apiKeysResource,
    statisticsResource,
    operatorsResource,
    updatesResource,
    configResource,
    panelsResource,
    profileResource,
    themeResource,
    subtitlesResource,
    customTagTypesResource,
]);


const reducers: {[key: string]: any} = resourceCollection.getReducers();
reducers.navbar = navbar;
reducers.layout = layout;

export default combineReducers(reducers);

import AbstractListResource from './abstract/abstract-list-resource';
import {ActionType, Resource} from 'sdk/src/defines';
import CategoryCategory from './items/category-category';
import {AttributesType, DispatchFunc, DispatchResponse, SortFunc} from '../utility/types';
import {sortAsc} from '../utility/utils';
import categories from './categories';
import Category from './items/category';

class CategoryCategories extends AbstractListResource<CategoryCategory> {
    public resourceType: Resource = Resource.CATEGORY_CATEGORIES;

    public resourceItemClass: typeof CategoryCategory = CategoryCategory;

    public sort: SortFunc = sortAsc('order');

    public allowedActions: ActionType[] = [
        ActionType.LIST,
        ActionType.GET,
        ActionType.UPDATE,
        ActionType.DELETE,
    ];

    public getConnectedResourceTypes(): Resource[] {
        return [Resource.CATEGORIES];
    }

    public onDeleted(items: CategoryCategory[]): DispatchResponse {
        return (dispatch: DispatchFunc) => {
            dispatch(super.onDeleted(items));

            if (categories.isInitialized()) {
                items.forEach((item: CategoryCategory) => {
                    const category: Category = categories.findById(item.referenceCategory.id);

                    if (category) {
                        const index: number = category.categories.findIndex((el: AttributesType) => el.categoryId === item.referenceCategory.id);

                        if (index >= 0) {
                            category.categories.splice(index, 1);
                        }
                    }
                });
            }
        };
    }
}

export {CategoryCategories, CategoryCategory};

export default new CategoryCategories();

import AbstractListResource from './abstract/abstract-list-resource';
import {Resource, ActionType} from 'sdk/src/defines';
import Track from './items/track';
import {SortFunc} from '../utility/types';
import {sortAsc} from '../utility/utils';

class Tracks extends AbstractListResource<Track> {
    public resourceType: Resource = Resource.TRACKS;

    public resourceItemClass: typeof Track = Track;

    public sort: SortFunc = sortAsc('order');

    public allowedActions: ActionType[] = [
        ActionType.LIST,
        ActionType.GET,
        ActionType.UPDATE,
        ActionType.CREATE,
        ActionType.DELETE,
        ActionType.UPLOAD,
    ];

    public getConnectedResourceTypes(): Resource[] {
        return [Resource.CONTENTS];
    }

}

export {Tracks, Track};

export default new Tracks();

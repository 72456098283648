import AbstractItemResource from '../abstract/abstract-item-resource';
import {Resource, UpdateType} from 'sdk/src/defines';
import AbstractResource from '../abstract/abstract-resource';
import {CastRule, CastType, DropDownItem} from '../../utility/types';
import {TFunction} from 'react-i18next';

class Update extends AbstractItemResource {
    public resourceType: Resource = Resource.UPDATES;

    public declare version: string;

    public declare deviceName: string;

    public declare minBuildNumber: number;

    public declare maxBuildNumber: number;

    public declare type: UpdateType;

    public castValues(): CastType {
        return {
            minBuildNumber: CastRule.FLOAT,
            maxBuildNumber: CastRule.FLOAT,
        };
    }
    public getTypeStr(t: TFunction): string {
        const item: DropDownItem = Update.typesForDropDown(t)
            .find((item: DropDownItem) => item.value === this.type);

        if (item) {
            return item.label;
        }

        return null;
    }

    public static typesForDropDown(t: TFunction): DropDownItem[] {
        return AbstractResource.defineForDropDown(UpdateType, 'updates', t);
    }
}

export default Update;

import AbstractResource from '../abstract/abstract-resource';
import defaultVideoImage from '@src/assets/images/content/video/100x75.jpg';
import {Resource, CommercialType} from 'sdk/src/defines';
import AbstractFileModelResource from '../abstract/abstract-file-model-resource';
import {DropDownItem, LabelsType} from '../../utility/types';
import {TFunction} from 'react-i18next';
import campaigns from '../campaigns';
import Campaign from './campaign';

class Commercial extends AbstractFileModelResource {
    public resourceType: Resource = Resource.COMMERCIALS;

    public labels: LabelsType = {
        id: 'ID',
        title: 'contents.title',
        categoryId: 'contents.categoryID',
    };

    public declare type: CommercialType;

    public declare campaignId: string;

    public declare header: string;

    public declare title: string;

    public declare line1: string;

    public declare line2: string;

    public declare url: string;

    public declare skipTimeout: number;

    public declare published: boolean;

    public getImage(): string {
        return super.getImage(defaultVideoImage);
    }

    public getTitle(t: TFunction): string {
        return this.title || t('commercials.unnamedCommercial');
    }

    public getCampaign(): Campaign {
        return campaigns.findById(this.campaignId);
    }

    public getCampaignTitle(t: TFunction): string {
        const campaign: Campaign = this.getCampaign();

        if (campaign) {
            return campaign.getTitle(t);
        }

        return '';
    }


    public getTypeStr(t: TFunction): string {
        const item: DropDownItem = Commercial
            .typesForDropDown(t)
            .find((item: DropDownItem) => item.value === this.type);

        if (item) {
            return item.label;
        }

        return '';
    }

    public static typesForDropDown(t: TFunction): DropDownItem[] {
        return AbstractResource.defineForDropDown(CommercialType, 'commercials', t);
    }
}

export default Commercial;

import AbstractItemResource from '../abstract/abstract-item-resource';
import {Resource, PanelType, RoleType, Uid} from 'sdk/src/defines';
import {TFunction} from 'react-i18next';
import {CastRule, CastType, DropDownItem} from '../../utility/types';

class Panel extends AbstractItemResource {
    public resourceType: Resource = Resource.PANELS;

    public declare userId: string;

    public declare type: PanelType;

    public declare order: number;

    public declare content: {
        id: Uid,
    };

    public castValues(): CastType {
        return {
            order: CastRule.FLOAT,
        };
    }

    public static typesForDropDown(t: TFunction, roleType?: RoleType): DropDownItem[] {
        let types: PanelType[] = [];

        switch (roleType) {
            case RoleType.DEALER:
                types = [
                    PanelType.CONTENT,
                    PanelType.SUBSCRIPTIONS,
                    PanelType.USERS,
                    PanelType.PAYMENTS,
                ];
                break;
            case RoleType.CONTENT_OWNER:
                types = [
                    PanelType.CONTENT,
                    PanelType.SUBSCRIPTIONS,
                    PanelType.PAYMENTS,
                ];
                break;
            default:
                types = [
                    PanelType.CONTENT,
                    PanelType.SUBSCRIPTIONS,
                    PanelType.USERS,
                    PanelType.PAYMENTS,
                    PanelType.COMMERCIAL,
                    PanelType.ACTIVE_SUBSCRIBERS,
                    PanelType.ONLINE_USERS,
                ]
        }

        return types.map((type: PanelType) => {
            return {
                label: t(`dashboard.${type}`),
                value: type,
            };
        });
    }

    public getType(t: TFunction): string {
        const types: DropDownItem[] = Panel.typesForDropDown(t);

        for (let i: number = 0; i < types.length; i++) {
            const item: DropDownItem = types[i];

            if (item.value === this.type) {
                return item.label;
            }
        }

        return null;
    }

    public getStatisticType(): string {
        switch (this.type) {
            case PanelType.CONTENT:
                return 'content';
            case PanelType.COMMERCIAL:
                return 'commercials';
            default:
                return null;
        }
    }

    public isStatistic(): boolean {
        return !!this.getStatisticType();
    }

}

export default Panel;

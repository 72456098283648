import AbstractItemResource from '../abstract/abstract-item-resource';
import {Resource, OS, Uid} from 'sdk/src/defines';
import {TFunction} from 'react-i18next';

class Campaign extends AbstractItemResource {
    public resourceType: Resource = Resource.CAMPAIGNS;

    public declare title: string;

    public declare countries: string[];

    public declare languages: string[];

    public declare os: OS[];

    public declare registeredUsers: boolean;

    public declare demoUsers: boolean;

    public declare videoInterval: number;

    public declare bannerInterval: number;

    public declare published: boolean;

    public declare plans: {planId: Uid}[];

    public getTitle(t: TFunction): string {
        return this.title || t('commercials.unnamedCampaign');
    }
}

export default Campaign;
